<div class="container-fluid customers-root">
  <app-card-analytics [stats]="stats" (shareFilterValue)="onFilterBy($event)">
  </app-card-analytics>

  <div class="table-root">
    <div class="table-action-wrapper d-flex align-items-center justify-content-between">
      <div id="transformer_search" class="search-input">
        <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()" />
        <input type="text" [placeholder]="'COMMON.SEARCH' | translate" [(ngModel)]="searchText" (keydown.enter)="search()"
          (ngModelChange)="this.searchTextUpdate.next($event)" />
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div id="transformer_date-type" class="datetype-picker">
              <div class="dropdown" (click)="showSelectDateType = !showSelectDateType" click-stop-propagation
                [title]="selectedDateTypeDisplay | translate">
                <span>{{'COMMON.DATE_TYPE.TITLE' | translate}}:</span>
                <span>{{ selectedDateTypeDisplay | translate }}</span>
                <span class="arrow"><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                [id]="'transformer_date-type-' + (i + 1)"
                (click)="
                    selectDateType(dateType); (datesValid() == true && (dateTo || dateFrom)) && search()
                  " *ngFor="let dateType of dateTypes; let i = index">
                  <div>{{ dateType.translation | translate }}</div>
                </div>
              </div>
            </div>
            <div id="transformer_date-from" class="datepicker-container" style="width: 23rem;">
              <label for="dateFrom"> {{'COMMON.FROM' | translate}}: </label>
              <input type="date" id="dateFrom" name="dateFrom" [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                " [(ngModel)]="dateFrom" (change)="datesValid() == true && search()" />
            </div>

            <div id="transformer_date-to" class="datepicker-container">
              <label for="dateTo" class="right-label"> {{'COMMON.TO' | translate}}: </label>
              <input type="date" id="dateTo" name="dateTo" [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                " [(ngModel)]="dateTo" (change)="datesValid() == true && search()" />
            </div>
          </div>
        </div>
        <div id="transformer_sort-by" class="sort-by-root" (click)="showSortBy = !showSortBy" click-stop-propagation>
          <div class="dropdown" [title]="sortByName | translate">
            <span>{{'COMMON.SORT_BY.TITLE' | translate}}:</span>
            <span>{{ sortByName | translate }}</span>
            <span><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSortBy">
            <div id="transformer_sort-by-newest" (click)="sortBy(sortByOptions.NEWEST)">{{'COMMON.SORT_BY.NEWEST' | translate}}</div>
            <div id="transformer_sort-by-slrn-asc" (click)="sortBy(sortByOptions.SLRN_ASC)">{{'COMMON.SORT_BY.SLRN_ASC' | translate}}.</div>
            <div id="transformer_sort-by-slrn-desc" (click)="sortBy(sortByOptions.SLRN_DESC)">{{'COMMON.SORT_BY.SLRN_DESC' | translate}}.</div>
            <div id="transformer_sort-by-id" (click)="sortBy(sortByOptions.ID)">ID</div>
          </div>
        </div>

        <button class="btn btn-icon" (click)="download()">
          <span>{{'COMMON.DOWNLOAD' | translate}}</span>
        </button>
        <div *ngIf="
            permissionsService.isTransformersCreateEditAllowed
          ">
          <button class="btn btn-primary btn-upload" (click)="showUpload = !showUpload" click-stop-propagation>
            <img src="../../assets/icons/chevron-down.svg" alt="Chevron down" />
          </button>
          <div *ngIf="showUpload" class="xlsx-upload" [routerLink]="'/upload/4/' + utilityId">
            <div><img src="../../assets/icons/upload-arrow.svg" alt="Upload icon" /></div>
            <div>{{'COMMON.UPLOAD_XLSX' | translate}}</div>
          </div>
        </div>
        <button class="btn btn-primary btn-add-new" routerLink="add" *ngIf="
            permissionsService.isTransformersCreateEditAllowed
          ">
          <img src="../../assets/icons/plus.svg" alt="Plus icon" />
          <span style="white-space: nowrap;">{{'TRANSFORMERS.NEW_TRANSFORMER' | translate}}</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        {{'COMMON.FILTER' | translate}}
        <app-multi-select-dropdown [id]="'transformer_region_filter'" class="multiselect" [clicked]="clicked" [items]="regions" [label]="'COMMON.BU.PLURAL'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectRegion($event)">
        </app-multi-select-dropdown>
        <app-multi-select-dropdown [id]="'transformer_area_filter'" class="multiselect" [clicked]="clicked" [items]="areas" [label]="'COMMON.UT.PLURAL'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectArea($event)">
        </app-multi-select-dropdown>

        <select id="transformer_user-type_filter" name="st-select" (change)="selectUserType($event)" class="form-select">
          <option value="" [selected]="!userType" disabled>
            {{'COMMON.USER.TYPE.TITLE' | translate}}
          </option>
          <option [id]="'transformer_user-type_filter-' + (i + 1)" [selected]="userType == o.value" *ngFor="let o of userTypeOptions; let i = index" value="{{ o.value }}">
            {{ o.translation | translate }}
          </option>
        </select>

        <app-multi-select-dropdown [id]="'transformer_user_filter'" class="multiselect" [searchFilterActive]="true"
          (filterValue)="applySearchFilter($event)" [clicked]="clicked" [items]="filteredUsers" [label]="'COMMON.USER.PLURAL'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectUsers($event)">
        </app-multi-select-dropdown>

        <select name="ts" (change)="selectTS($event)" class="form-select">
          <option value="0" [selected]="selectedTs == 0" disabled>
            {{'COMMON.TS.SINGLE' | translate}}
          </option>
          <option [id]="'transformer_user_filter-' + (i + 1)" [selected]="selectedTs == o.id" *ngFor="let o of ts; let i = index" value="{{ o.id }}">
            {{ o.name }}
          </option>
        </select>

        <select id="transformer_feeder-voltage_filter" name="voltage" (change)="selectFeederVoltage($event)" class="form-select">
          <option value="0" [selected]="feederVoltage == 0" disabled>
            {{'COMMON.FEEDER_VOLTAGE' | translate}}
          </option>
          <option [id]="'transformer_filter_feeder_voltage-' + (i + 1)" [selected]="feederVoltage == o.value" *ngFor="let o of feederVoltageOptions; let i = index" value="{{ o.value }}">
            {{ o.name }}
          </option>
        </select>

        <select id="transformer_mount-position_filter" name="mount" (change)="selectMountPosition($event)" class="form-select">
          <option value="0" [selected]="mountPosition == 0" disabled>
            {{'TRANSFORMERS.MOUNT_POS' | translate}}
          </option>
          <option [id]="'transformer_mount-position_filter-' + (i + 1)" [selected]="mountPosition == o.value" *ngFor="let o of mountPositionOptions; let i = index" value="{{ o.value }}">
            {{ o.translation | translate }}
          </option>
        </select>
        <select id="transformer_dt-type_filter" name="type" (change)="selectDtType($event)" class="form-select">
          <option value="0" [selected]="dtType == 0" disabled>{{'TRANSFORMERS.DT_TYPE' | translate}}</option>
          <option [id]="'transformer_dt-type_filter-' + (i +  1)" [selected]="dtType == o.value" *ngFor="let o of dtTypeOptions; let i = index" value="{{ o.value }}">
            {{ o.translation | translate }}
          </option>
        </select>
        <select id="transformer_dt-status_filter" name="status" (change)="selectDtStatus($event)" class="form-select">
          <option value="0" [selected]="dtStatus == 0" disabled>
            {{'TRANSFORMERS.DT_STATUS' | translate}}
          </option>
          <option [id]="'transformer_dt-status_filter-' + (i + 1)" [selected]="dtStatus == o.value" *ngFor="let o of dtStatusOptions; let i = index" value="{{ o.value }}">
            {{ o.translation | translate }}
          </option>
        </select>
        <select id="transformer_meter-status_filter" name="meter-status" (change)="selectMeterStatus($event)" class="form-select">
          <option value="0" [selected]="meterStatus == 0" disabled>
            {{'COMMON.MBC_DETAILS.METER_STATUS' | translate}}
          </option>
          <option [id]="'transformer_meter-status_filter-' + (i + 1)" [selected]="meterStatus == o.value" *ngFor="let o of meterStatusOptions; let i = index" value="{{ o.value }}">
            {{ o.translation | translate }}
          </option>
        </select>
        <select id="transformer_validation-status_filter" *ngIf="permissionsService.isValidationStatusAllowed" name="status" (change)="selectStatus($event)" class="form-select">
          <option value="0" [selected]="status == 0" disabled>{{'COMMON.VALIDATION_STATUS' | translate}}</option>
          <option [id]="'transformer_validation-status_filter-' + (i + 1)" [selected]="status == o.value" *ngFor="let o of statusOptions; let i = index" value="{{ o.value }}">
            {{ o.translation | translate }}
          </option>
        </select>
        <select id="transformer_kva-rating_filter" name="kva-rating" (change)="selectKVARating($event)" class="form-select">
          <option value="0" [selected]="!kvaRating" disabled>{{'TRANSFORMERS.KVA_RATING' | translate}}</option>
          <option [id]="'transformer_kva-rating_filter-' + (i + 1)" [selected]="kvaRating == kva.id" *ngFor="let kva of kvaRatingOptions; let i = index" value="{{ kva.id }}">
            {{ kva.kvaRating }}
          </option>
        </select>
      </div>

      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist [filters]="selectedFilters" [useApply]="true" (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)" (applyFilterEmitter)="applyFilter()">
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="dts">
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th id="transformer_table_header_checkbox">
                <input type="checkbox" class="form-check-input" [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()" />
              </th>
              <th id="transformer_table_header_slrn">{{'COMMON.SLRN' | translate}}</th>
              <th id="transformer_table_header_dt-name" class="th-dt-name">{{'COMMON.DT_NAME' | translate}}</th>
              <th id="transformer_table_header_dt-rating">{{'TRANSFORMERS.DT_RATING' | translate}}(KVA)</th>
              <th id="transformer_table_header_dt-address" class="th-address">{{'TRANSFORMERS.DT_ADDRESS' | translate}}</th>
              <th id="transformer_table_header_district">{{'COMMON.UT.SINGLE' | translate}}</th>
              <th id="transformer_table_header_region">{{'COMMON.BU.SINGLE' | translate}}</th>
              <th id="transformer_table_header_feeder-name">{{'COMMON.MBC_DETAILS.FEEDER_NAME' | translate}}</th>
              <th id="transformer_table_header_feeder-voltage">{{'COMMON.FEEDER_VOLTAGE' | translate}}</th>
              <th id="transformer_table_header_connected-customers">{{'COMMON.MBC_DETAILS.CONNECTED_CUSTOMERS' | translate}}</th>
              <th id="transformer_table_header_type">{{'COMMON.TYPE' | translate}}</th>
              <th id="transformer_table_header_validation-status" *ngIf="permissionsService.isValidationStatusAllowed">{{'COMMON.VALIDATION_STATUS' | translate}}</th>
              <th id="transformer_table_header_tag">{{'COMMON.TAG.TITLE' | translate}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr [id]="'transformer_table_row-' + (i + 1)" *ngFor="let dt of dts; let i = index">
              <td [id]="'transformer_table_checkbox-' + (i + 1)">
                <input type="checkbox" class="form-check-input" value="{{ dt.id }}" [ngModel]="dt.isChecked"
                  (click)="checkDt(dt.id)" />
              </td>
              <td [id]="'transformer_table_slrn-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt?.slrn | hasValue }}
              </td>
              <td [id]="'transformer_table_dt-name-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.name | hasValue }}
              </td>
              <td [id]="'transformer_table_dt-rating-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.kvaRating | hasValue }}
              </td>

              <td [id]="'transformer_table_dt-address-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.locationAddress | hasValue }}
              </td>
              <td [id]="'transformer_table_district-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.area.name | hasValue }}
              </td>
              <td [id]="'transformer_table_region-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.area.regionName | hasValue }}
              </td>
              <td [id]="'transformer_table_feeder-name-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.feeder.name | hasValue }}
              </td>
              <td [id]="'transformer_table_feeder-voltage-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{
                dt.feeder.feederType == "KV11" ? "11KV" : ("33KV" | hasValue)
                }}
              </td>
              <td [id]="'transformer_table_connected-customers-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.connectedCustomers | number | hasValue }}
              </td>

              <td [id]="'transformer_table_type-' + (i + 1)" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                {{ dt.type | hasValue }}
              </td>

              <td [id]="'transformer_table_validation-status-' + (i + 1)" *ngIf="permissionsService.isValidationStatusAllowed" [routerLink]="['edit', dt.id]" (click)="setFilterStorage()">
                <div *ngIf="dt.dataQualityStatus" class="building-status" [ngClass]="{
                        'complete-building' : dt.dataQualityStatus == 'Approved',
                        'rejected-building' : dt.dataQualityStatus == 'Rejected'
                      }">
                  {{ dt.dataQualityStatus == 'Approved' ? ('COMMON.ASSET_STATUS.COMPLETE' | translate) : ('COMMON.ASSET_STATUS.REJECTED' | translate) }}
                </div>
                <div *ngIf="dt.status && !dt.dataQualityStatus" class="building-status" [ngClass]="{
                    'pending-building': dt.status == 'Pending',
                    'assigned-building': dt.status == 'Assigned' || dt.status == 'Retagged'
                  }">
                  {{ 'COMMON.ASSET_STATUS.' + dt.status | uppercase | translate }}
                </div>
                <div *ngIf="!dt.status && !dt.dataQualityStatus" class="building-status">--------</div>
              </td>
              <td [id]="'transformer_table_tag-' + (i + 1)">
                <div [routerLink]="['edit', dt.id]" (click)="setFilterStorage()" class="tag-customer">
                  {{'COMMON.TAG.EXISTING' | translate}}
                </div>
              </td>
              <td class="more-icon" (click)="setFilterStorage()" [routerLink]="['edit', dt.id]">
                <img src="../../assets/icons/more-icon.svg" alt="More icon" class="more-icon" />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging *ngIf="count > 0" [pageSize]="pageSize" [useDirect]="true" [count]="count"
            [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>
