<div class="container-fluid customers-root">
    <app-card-analytics [stats]="stats" 
    (shareFilterValue)="onFilterBy($event)"></app-card-analytics>

    <div class="table-root">
        <div class="table-action-wrapper d-flex align-items-center justify-content-between">
          <div class="d-flex searches">
            <div id="meter_search" class="search-input">
              <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()">
              <input type="text" [placeholder]="'COMMON.SEARCH' | translate" [(ngModel)]="searchText" (keydown.enter)="search()" (ngModelChange)="this.searchTextUpdate.next($event)">              
            </div>
            <div id="meter_search-by" class="search-type-picker">
              <div class="dropdown" (click)="showSelectSearchType = !showSelectSearchType" click-stop-propagation
                [title]="selectedSearchTypeDisplay | translate">
                <span>{{'COMMON.BY' | translate}}:</span>
                <span>{{ selectedSearchTypeDisplay | translate}}</span>
                <span class="arrow"><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down" /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectSearchType">
                <div [id]="'meter_search-by-' + (i + 1)" (click)="
                    selectSearchType(searchType)
                  " *ngFor="let searchType of searchByOptions; let i = index">
                  <div>{{ searchType.translation | translate }}</div>
                </div>
              </div>
            </div>
          </div>
            <div class="ml-auto d-flex">
                <div>
                    <div class="d-flex">
                      <div id="meter_date-type" class="datetype-picker">
                        <div class="dropdown date-type-dropdown"
                          (click)="showSelectDateType = !showSelectDateType"
                          click-stop-propagation [title]="selectedDateTypeDisplay | translate"
                        >
                          <span>{{'COMMON.DATE_TYPE.TITLE' | translate}}:</span>
                          <span>{{ selectedDateTypeDisplay | translate }}</span>
                          <span class="arrow"
                            ><img
                              src="../../assets/icons/gray-arrow-down.svg"
                              alt="Gray arrow down"
                          /></span>
                        </div>
                        <div class="dropdown-opened" *ngIf="showSelectDateType">
                          <div
                            [id]="'meter_date-type' + (i + 1)"
                            (click)="
                              selectDateType(dateType); (datesValid() == true && (dateTo || dateFrom)) && search()
                            "
                            *ngFor="let dateType of dateTypes; let i = index"
                          >
                            <div>{{ dateType.translation | translate }}</div>
                          </div>
                        </div>
                      </div>
                      <div id="meter_date-from" class="datepicker-container" style="width: 23rem;">
                        <label for="dateFrom">
                          {{'COMMON.FROM' | translate}}:
                        </label>
                        <input type="date" id="dateFrom" name="dateFrom" [ngClass]="!datesValid() ? 'btn datepicker-icon-error' : 'btn datepicker-icon'" [(ngModel)]="dateFrom" (change)="datesValid()==true && search()" >
    
                      </div>
                      
                      <div id="meter_date-to" class="datepicker-container" style="width: 20rem;">
                        <label for="dateTo" class="right-label">
                          {{'COMMON.TO' | translate}}:
                        </label>
                        <input type="date" id="dateTo" name="dateTo" [ngClass]="!datesValid() ? 'btn datepicker-icon-error' : 'btn datepicker-icon'" [(ngModel)]="dateTo"  (change)="datesValid()==true && search()" >
    
                        </div>
                    </div>
                  </div>
                <div id="meter_sort-by" class="sort-by-root" (click)="showSortBy=!showSortBy;" click-stop-propagation>
                    <div class="dropdown" [title]="sortByName | translate">
                      <span>{{'COMMON.SORT_BY.TITLE' | translate}}:</span>
                      <span style="white-space: wrap;">{{sortByName | translate}}</span>
                      <span><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down"></span>
                    </div>
                    <div class="dropdown-opened" *ngIf="showSortBy">
                      <div id="meter_sort-by-newest" (click)="sortBy(sortByOptions.NEWEST)">{{'COMMON.SORT_BY.NEWEST' | translate}}</div>
                      <div id="meter_sort-by-slrn-asc" (click)="sortBy(sortByOptions.SLRN_ASC)">{{'COMMON.SORT_BY.SLRN_ASC' | translate}}.</div>
                      <div id="meter_sort-by-slrn-desc" (click)="sortBy(sortByOptions.SLRN_DESC)">{{'COMMON.SORT_BY.SLRN_DESC' | translate}}.</div>
                      <div id="meter_sort-by-num-asc" (click)="sortBy(sortByOptions.NUMBER_ASC)">{{'COMMON.SORT_BY.NUMBER_ASC' | translate}}.</div>
                      <div id="meter_sort-by-num-desc" (click)="sortBy(sortByOptions.NUMBER_DESC)">{{'COMMON.SORT_BY.NUMBER_DESC' | translate}}.</div>

                    </div>
                </div>
                <button class="btn btn-icon"  (click)="download()">
                    <span>{{'COMMON.DOWNLOAD' | translate}}</span>
                </button>
                <div *ngIf="permissionsService.isMetersCreateEditAllowed">
                  <button class="btn btn-primary btn-upload" (click)="showUpload=!showUpload;" click-stop-propagation>
                    <img src="../../assets/icons/chevron-down.svg" alt="Chevron down">
                  </button>
                  <div *ngIf="showUpload" class="xlsx-upload" >
                    <div><img src="../../assets/icons/upload-arrow.svg" alt="Upload icon"/></div>
                    <div>{{'COMMON.UPLOAD_XLSX' | translate}}</div>
                  </div>
                </div>
                <button class="btn btn-primary btn-add-new" *ngIf="permissionsService.isMetersCreateEditAllowed" [title]="'METERS.NEW' | translate">
                    <img src="../../assets/icons/plus.svg" alt="Plus icon">
                    <span style="max-width: 9rem; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{'METERS.NEW' | translate}}</span>
                </button>
            </div>
          </div>
          <div class="filter">
            <div class="filter-list">
              {{'COMMON.FILTER' | translate}}
              <app-multi-select-dropdown id="meter_region_filter"
                                         class="multiselect"
                                         [clicked] = "clicked"
                                         [items]="regions" 
                                         [label]="'COMMON.BU.PLURAL'"
                                         [showMore]="false"
                                         [requireApply]="true"
                                         (shareIndividualCheckedList)="selectRegion($event)"
                                         >
              </app-multi-select-dropdown>
              <app-multi-select-dropdown id="meter_area-filter"
                                         class="multiselect"
                                         [clicked] = "clicked"
                                         [items]="areas" 
                                         [label]="'COMMON.UT.PLURAL'"
                                         [showMore]="false"
                                         [requireApply]="true"
                                         (shareIndividualCheckedList)="selectArea($event)"
                                         >
              </app-multi-select-dropdown>
 
              <select id="meter_meter-type_filter" name="mt-select" (change)="selectMeterType($event)" class="form-select" >
                <option value="" [selected]="!meterType" disabled>{{'COMMON.MBC_DETAILS.METER_TYPE.SINGLE' | translate}}</option>
                <option [id]="'meter_meter-type_filter-' + (i + 1)" [selected]="meterType==m.id"  *ngFor="let m of meterTypes; let i = index" value="{{m.id}}">
                    {{m.name}}
                </option>
              </select> 

              <select id="meter_customer-type_filter" name="ct-select" (change)="selectCustomerType($event)" class="form-select" >
                <option value="" [selected]="!customerType" disabled>{{'COMMON.MBC_DETAILS.CUSTOMER_TYPE' | translate}}</option>
                <option [id]="'meter_customer-type_filter-' + (i + 1)" [selected]="customerType == o.value" *ngFor="let o of customerTypeOptions; let i = index" value="{{o.value}}">
                    {{o.translation | translate}}
                </option>
              </select> 

              <select id="meter_status_filter" name="status-select" (change)="selectStatus($event)" class="form-select" >
                <option value="" [selected]="!status" disabled>{{'COMMON.STATUS' | translate}}</option>
                <option [id]="'meter_status_filter-' + (i + 1)" [selected]="status==s.value" *ngFor="let s of statusOptions; let i = index" value="{{s.value}}">
                    {{s.translation | translate}}
                </option>
              </select> 
              
              <select id="meter_tag_filter" name="tag-select" (change)="selectTag($event)" class="form-select" >
                <option value="" [selected]="!tag" disabled>{{'COMMON.TAG.TITLE' | translate}}</option>
                <option [id]="'meter_tag_filter-' + (i + 1)" [selected]="tag==t.value" *ngFor="let t of tagOptions; let i = index" value="{{t.value}}">
                    {{t.translation | translate}}
                </option>
              </select> 
            </div>
            <div *ngIf="selectedFilters.length > 0">
              <app-filter-checklist [filters]="selectedFilters" [useApply]="true" (resetFilterEmitter)="resetFilter($event)"
                (removeFilterEmitter)="removeFilter($event)" (applyFilterEmitter)="applyFilter()">
              </app-filter-checklist>
            </div>
          </div>
          <div class="table">
            <div *ngIf="meters"> 
              <table class="table border-bottom">
                <thead class="table-light">
                  <tr>
                    <th id="meter_table_header_checkbox">
                      <input type="checkbox" class="form-check-input" [ngModel]="allCheckboxesChecked" (click)="checkAllCheckboxes()">
                    </th>               
                    <th id="meter_table_header_meter-slrn">{{'COMMON.TABLE_HEADERS.METER_SLRN' | translate}}</th>
                    <th id="meter_table_header_customer-name">{{'COMMON.MBC_DETAILS.CUSTOMER_NAME' | translate}}</th>
                    <th id="meter_table_header_serial-num">{{'COMMON.TABLE_HEADERS.METER_SERIAL_NUM' | translate}}</th>
                    <th id="meter_table_header_address">{{'COMMON.ADDRESS' | translate}}</th>
                    <th id="meter_table_header_acc-no">{{'COMMON.MBC_DETAILS.ACCOUNT_NO' | translate}}</th>
                    <th id="meter_table_header_meter-type">{{'COMMON.TABLE_HEADERS.METER_TYPE' | translate}}</th>
                    <th id="meter_table_header_region">{{'COMMON.BU.SINGLE' | translate}}</th>
                    <th id="meter_table_header_area">{{'COMMON.TABLE_HEADERS.AREA' | translate}}</th>
                    <th id="meter_table_header_customer-type">{{'COMMON.MBC_DETAILS.CUSTOMER_TYPE' | translate}}</th>
                    <th id="meter_table_header_status">{{'COMMON.STATUS' | translate}}</th>
                    <th id="meter_table_header_tag">{{'COMMON.TAG.TITLE' | translate}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr [id]="'meter_table_row-' + (i + 1)" *ngFor="let m of meters; let i=index;">
                    <td [id]="'meter_table_checkbox' + (i + 1)"><input type="checkbox" class="form-check-input" [ngModel]="m.isChecked" (click)="checkMeter(m.id)"></td>
                    <td [id]="'meter_table_meter-slrn' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m?.slrn | hasValue}}</td>
                    <td [id]="'meter_table_customer-name' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.customerName | hasValue}}</td>
                    <td [id]="'meter_table_serial-num' + (i + 1)" (click)="setFilterStorage()"  [routerLink]="['edit', m.customerId]">{{m.number | hasValue}}</td>
                    <td [id]="'meter_table_address' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.address | hasValue}}</td>
                    <td [id]="'meter_table_acc-no' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.accountNumber | hasValue}}</td>
                    <td [id]="'meter_table_meter-type' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.meterType | hasValue}}</td>
                    <td [id]="'meter_table_region' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.region | hasValue}}</td>
                    <td [id]="'meter_table_area' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.area | hasValue}}</td>
                    <td [id]="'meter_table_customer-type' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">{{m.customerType | hasValue}}</td>
                    <td [id]="'meter_table_status' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">
                      <div *ngIf="
                        !m.dqStatus &&
                        m.status !== AssetStatus.Assigned
                      " class="meter-status pending-meter">
                      {{'COMMON.ASSET_STATUS.PENDING' | translate}}
                    </div>
                    <div *ngIf="m.status === AssetStatus.Assigned" class="meter-status assigned-meter">
                      {{'COMMON.ASSET_STATUS.ASSIGNED' | translate}}
                    </div>
                    <div *ngIf="
                        m.dqStatus &&
                        m.dqStatus?.toString() == 'Approved' &&
                        m.status !== AssetStatus.Assigned
                      " class="meter-status complete-meter">
                      {{'COMMON.ASSET_STATUS.COMPLETE' | translate}}
                    </div>
                    <div *ngIf="
                        m.dqStatus &&
                        m.dqStatus?.toString() == 'Rejected' &&
                        m.status !== AssetStatus.Assigned
                      " class="meter-status rejected-meter">
                      {{'COMMON.ASSET_STATUS.REJECTED' | translate}}
                    </div>
                    </td>
                    <td [id]="'meter_table_tag' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]" class="tag-status">
                        <div  [ngClass]="m.tagged ? 'tag-new-customer' : 'tag-customer'">{{m.tagged ? ("COMMON.TAG.NEW" | translate) : ("COMMON.TAG.EXISTING" | translate)}}</div>
                    </td>
        
                    <td class="more-icon" (click)="setFilterStorage()" [routerLink]="['edit', m.customerId]">
                        <img src="../../assets/icons/more-icon.svg" alt="More icon" class="more-icon">
                    </td>
                  </tr>
                </tbody>
              </table>
              <div> 
                 <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-paging> 
              </div> 
            </div>
          </div>
    </div>
</div>
