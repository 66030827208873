<div class="container-fluid maintenance-root">
  <div class="maintenance-table">
    <div class="d-flex justify-content-start switch-toggle transparent">
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == tabs.Unassigned ? 'active' : ''"
        (click)="changeTab(tabs.Unassigned)"
      >
        <div>
          <span class="bubble">{{ stats.unassigned | number }}</span>
          {{ "COMMON.ASSET_STATUS.UNASSIGNED" | translate }}
        </div>
      </a>
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == tabs.Assigned ? 'active' : ''"
        (click)="changeTab(tabs.Assigned)"
      >
        <div>
          <span class="bubble">{{ stats.assigned | number }}</span>
          {{ "COMMON.ASSET_STATUS.ASSIGNED" | translate }}
        </div>
      </a>
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == tabs.Completed ? 'active' : ''"
        (click)="changeTab(tabs.Completed)"
      >
        <div>
          <span class="bubble">{{ stats.completed | number }}</span>
          {{ "COMMON.ASSET_STATUS.COMPLETED" | translate }}
        </div>
      </a>
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == tabs.Canceled ? 'active' : ''"
        (click)="changeTab(tabs.Canceled)"
      >
        <div>
          <span class="bubble">{{ stats.canceled | number }}</span>
          {{ "COMMON.ASSET_STATUS.CANCELED" | translate }}
        </div>
      </a>
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == tabs.Incomplete ? 'active' : ''"
        (click)="changeTab(tabs.Incomplete)"
      >
        <div>
          <span class="bubble">{{ stats.incomplete | number }}</span>
          {{ "COMMON.ASSET_STATUS.INCOMPLETE" | translate }}
        </div>
      </a>
    </div>

    <div class="table-root-child">
      <div
        class="table-action-wrapper d-flex align-items-center justify-content-between"
      >
        <div id="wo_m_search" class="search-input">
          <img
            src="../../../../../assets/icons/search.svg"
            alt="Search icon"
            (click)="search()"
          />
          <input
            type="text"
            [placeholder]="'COMMON.SEARCH' | translate"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
        </div>
        <div class="ml-auto d-flex">
          <div>
            <div class="d-flex">
              <div id="wo_m_date-from" class="datepicker-container" style="width: 23rem;">
                <label for="dateFrom"> {{ "COMMON.FROM" | translate }}: </label>
                <input
                  type="date"
                  id="dateFrom"
                  name="dateFrom"
                  [ngClass]="
                    dateFrom > dateTo
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  "
                  [(ngModel)]="dateFrom"
                  (change)="datesValid() == true && search()"
                />
              </div>

              <div id="wo_m_date-to" class="datepicker-container">
                <label for="dateTo" class="right-label">  {{ "COMMON.TO" | translate }}: </label>
                <input
                  type="date"
                  id="dateTo"
                  name="dateTo"
                  [ngClass]="
                    dateFrom > dateTo
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  "
                  [(ngModel)]="dateTo"
                  (change)="datesValid() == true && search()"
                />
              </div>
              <div
                id="wo_m_sort-by"
                class="sort-by-root"
                (click)="showSortBy = !showSortBy"
                click-stop-propagation
              >
                <div class="dropdown">
                  <span>{{ "COMMON.SORT_BY.TITLE" | translate }}:</span>
                  <span
                    >{{ sortByLabelValue.label | translate }}
                    <img
                      *ngIf="sortByLabelValue.arrow !== 'no'"
                      [ngClass]="{
                        'arrow-up': sortByLabelValue.arrow === 'up'
                      }"
                      src="../../../../../assets/icons/arrow.svg"
                      alt="Arrow icon"
                    />
                  </span>
                  <span>
                    <img
                      src="../../../../../assets/icons/gray-arrow-down.svg"
                      alt="Gray arrow down"
                    />
                  </span>
                </div>
                <div class="dropdown-opened" *ngIf="showSortBy">
                  <div id="wo_m_sort-by-newest" (click)="sortBy(sortByOptions.NEWEST)">
                    <div>{{ "COMMON.SORT_BY.NEWEST" | translate }}</div>
                  </div>
                  <div id="wo_m_sort-by-oldest" (click)="sortBy(sortByOptions.OLDEST)">
                    <div>{{ "COMMON.SORT_BY.OLDEST" | translate }}</div>
                  </div>
                </div>
              </div>
              <button class="btn btn-icon" (click)="download()">
                <span>{{ "COMMON.DOWNLOAD" | translate }}</span>
              </button>

              <button
                [routerLink]="['/work-orders/new-work-order']"
                class="btn btn-primary btn-add-new"
              >
                <img
                  src="../../../../../assets/icons/plus.svg"
                  alt="Plus icon"
                />
                <span style="white-space: nowrap;">{{ "COMMON.NEW" | translate }} {{ "ROLE_MANAGEMENT.WORK_ORDER" | translate |lowercase }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table">
      <table class="table border-bottom">
        <thead class="table-light">
          <tr>
            <th id="wo_m_table_header_checkbox">
              <input
                type="checkbox"
                class="form-check-input"
                [checked]="false"
                (change)="checkAllCheckBox($event)"
              />
            </th>
            <th id="wo_m_table_header_id">{{ "ROLE_MANAGEMENT.WORK_ORDER" | translate }} {{ "COMMON.SORT_BY.ID" | translate }}</th>
            <th id="wo_m_table_header_category">{{ "COMMON.CATEGORY" | translate }}</th>
            <th id="wo_m_table_header_asset-type">{{ "COMMON.ASSET_TYPE" | translate }}</th>
            <th id="wo_m_table_header_description">{{ "COMMON.DESCRIPTION" | translate }}</th>
            <th id="wo_m_table_header_status" *ngIf="currentTab === 1">{{ "COMMON.STATUS" | translate }}</th>
            <th *ngIf="currentTab === 1 || currentTab === 0"></th>
          </tr>
        </thead>
        <tbody>
          <tr [id]="'wo_m_table_row-' + (i + 1)" *ngFor="let maintenance of maintenanceList; let i = index">
            <td [id]="'wo_m_table_checkbox-' + (i + 1)">
              <input
                type="checkbox"
                class="form-check-input"
                value="{{ maintenance.workOrderId }}"
              />
            </td>

            <td [id]="'wo_m_table_id-' + (i + 1)" (click)="selectMaintenance(maintenance.id)">
              {{ maintenance.workOrderId | hasValue }}
            </td>
            <td [id]="'wo_m_table_category-' + (i + 1)" (click)="selectMaintenance(maintenance.id)">
              {{ maintenance.category | hasValue }}
            </td>
            <td [id]="'wo_m_table_asset-type-' + (i + 1)" (click)="selectMaintenance(maintenance.id)">
              {{ maintenance?.assetTypeForUI | hasValue }}
            </td>
            <td [id]="'wo_m_table_description-' + (i + 1)" (click)="selectMaintenance(maintenance.id)">
              {{ maintenance.description | hasValue }}
            </td>
            <td
              *ngIf="currentTab === 1"
              (click)="selectMaintenance(maintenance.id)"
            >
              <span
                [ngClass]="{
                  'orange-status': maintenance.status === 'In progress',
                  'green-status': maintenance.status === 'Assigned'
                }"
                >{{ maintenance.status | hasValue }}</span
              >
            </td>

            <td
              [class.align-options-end]="currentTab === 0"
              *ngIf="currentTab === 1 || currentTab === 0"
            >
              <span
                [id]="'wo_m_assign-btn-' + (i + 1)"
                *ngIf="currentTab === 0"
                (click)="
                  selectMaintenanceForAssign(maintenance.areaId, maintenance.id)
                "
              >
                <button
                  class="transparent-button"
                  data-bs-toggle="modal"
                  data-bs-target="#assignToFieldAgent"
                >
                  Assign
                </button>
              </span>
              <img
                (click)="selectMaintenance(maintenance.id)"
                src="../../../../../assets/icons/more-icon.svg"
                alt="More icon"
                class="more-icon"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <app-paging
          *ngIf="count > 0"
          [pageSize]="pageSize"
          [count]="count"
          [currentPage]="currentPage"
          [useDirect]="true"
          (pageChange)="pageChange($event)"
        ></app-paging>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="assignToFieldAgent"
  tabindex="-1"
  aria-hidden="true"
  (hidden.bs.modal)="onModalAssignFade()"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "COMMON.ASSIGN_TO_FIELD_AGENT" | translate }}</h5>
        <button
          type="button"
          #closeModal
          class="btn-cancel-modal"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        {{ "COMMON.CANCEL" | translate }}
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input
            #userFilterInput
            autocomplete="off"
            type="text"
            name="filterText"
            [(ngModel)]="filterText"
            class="form-control filter-field"
            [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }"
            id="floatingInput"
            (ngModelChange)="filterMobileUsers()"
            (click)="isDropdownOpened = true"
            placeholder=" "
          />
          <label for="floatingInput">{{ "COMMON.FIELD_AGENT" | translate }}</label>

          <div
            class="filtered-agent-list"
            *ngIf="isDropdownOpened"
            #dropdownContainer
          >
            <div
              click-stop-propagation
              class="agent-name"
              (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers"
            >
              {{ u.fullName }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          {{ "COMMON.ASSIGN_TASK" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
