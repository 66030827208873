import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
import { AppConfig } from '../config/config';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from '../models/role.model';
import { DashboardConfiguration, UtilityLanguageSuffix } from '../models/utility.model';
import { PermissionsService } from './permissions.service';
import { ReportStateService } from '../components/reports/services/report-state.service';
import { Language } from '../models/user.model';
import { ResponsePackage } from '../models/util.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private pathApi = this.config.setting['pathApi'] + "auth/";
  private jwtService = new JwtHelperService();

  constructor(private http: HttpClient, 
    private config: AppConfig, 
    private router: Router,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private reportStateService: ReportStateService,
    ) {  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(this.pathApi + "login", { Email: email, Password: password });
  } 

  tokenLogin(token: string): Observable<any> {
    return this.http.post(this.pathApi + "tokenLogin", { Token: token });
  }

  bpsLogin(email: string,pass: string, utilityId: string): Observable<any> {
    return this.http.post(this.pathApi + "bpsLogin", { Email: email, Password: pass, UtilityId: parseInt(utilityId) });
  } 

  bpsLoginFirstStep(email: string, utilityId: number): Observable<any> {
    return this.http.post(this.pathApi + "bpsLoginFirstStep", { Email: email, UtilityId: utilityId });
  } 

  logout(routeAfterClear : boolean) {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('colorCode');
    localStorage.removeItem('configuration');
    localStorage.removeItem('imageUrl');
    localStorage.removeItem('filter');
    localStorage.removeItem('filterMeter');
    localStorage.removeItem('filterPole');
    localStorage.removeItem('filterTransformer');
    localStorage.removeItem('filterCustomer');
    localStorage.removeItem('filterBuilding'); 
    localStorage.removeItem('filterCustomer');
    localStorage.removeItem('filterBuilding');
    localStorage.removeItem('cameFrom');
    localStorage.removeItem('notVisibleFields');
    localStorage.removeItem('currency');
    localStorage.removeItem('preferredLanguage');
    localStorage.removeItem('languageSuffixes');
    this.reportStateService.clearAllReportFilters({ clearMainTabSelection: true, clearDataQualityTabsSelection: true });

    window.sessionStorage.clear();
    this.permissionsService.resetPermissions();
    if(routeAfterClear){
      this.router.navigate(['']);
    }
  }

  updateUserInLocalStorageNoApi(user: any) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  isLoggedIn() {
    let token = localStorage.getItem('token');
    if (!token)
      return false;
    if(this.jwtService.isTokenExpired(token)){
      this.logout(true);
      this.toastr.error('Session timed out.');
    }
    return !this.jwtService.isTokenExpired(token);
  }

  public getCurrentUser() {
    let token = localStorage.getItem('token');
    if (!token)
      return null;

    return this.jwtService.decodeToken(token);
  }

  public isAuthorized(permission: PermissionsEnum) {
    let rolePermissions = localStorage.getItem('permissions');

    if (!rolePermissions) // dont have permissions on local storage
      return false;

      let permissions = rolePermissions ? JSON.parse(rolePermissions) : [];
      let filteredPerms = permissions.filter((x: any) => x == permission);

    if(filteredPerms)
      return filteredPerms[0];
    return null;
  }
  
  public hasConfiguration(config:any){
    let configuration = localStorage.getItem('configuration');
    if (!configuration)
    return false;

    let configurations = configuration ? JSON.parse(configuration) : [];
    return configurations.includes(config)
  }
  public hasUtility(){
    let token = localStorage.getItem('token');
    if (!token)
      return null;

    let currentUser = this.jwtService.decodeToken(token);
    return currentUser.utilityId != "";
  }

  public decodeJWTToken() {
    var token = localStorage.getItem('token');
    const helper = new JwtHelperService();
    var decoded = helper.decodeToken(token!);
    return decoded;
  }

  sendEmail(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}sendMessage`, obj)
  } 

  changePreferredLanguage(language: Language) {
    return this.http.post<ResponsePackage<UtilityLanguageSuffix[]>>(`${this.pathApi}changePreferredLanguage`, language)
  }
}
