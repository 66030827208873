import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ModalService } from '../_modules/shared/_services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { UtilityLanguageSuffix } from '../models/utility.model';
import { Language } from '../models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CAIMS.Ng';

  constructor(private authService: AuthService, public modalService: ModalService, private translate: TranslateService) {}

  ngOnInit():void {

    var color= localStorage.getItem('colorCode')
    if(color!=null){
      this.setUtilityColors(color);
    }
    else{
      this.setBpsAdminColors();
    }
    this.setLanguage();

  }
  setUtilityColors(color:string){
    document.documentElement.style.setProperty('--primary-color', color);
    document.documentElement.style.setProperty('--bg-color', color+'08');
    document.documentElement.style.setProperty('--table-root-bg-color', color+'15');
    document.documentElement.style.setProperty('--card-color', color+'22');
    document.documentElement.style.setProperty('--bubble-color', color+'95');
  }
  setBpsAdminColors(){
    document.documentElement.style.setProperty('--primary-color', '#03423E');
    document.documentElement.style.setProperty('--bg-color', '#F5FBFA');
    document.documentElement.style.setProperty('--table-root-bg-color', '#EBF1F1');
    document.documentElement.style.setProperty('--card-color', '#e4ecea');
    document.documentElement.style.setProperty('--bubble-color', '#688F8D');
  }
  setLanguage(){
    const lang = parseInt(localStorage.getItem('preferredLanguage') ?? '0', 10);
    const suffixes = this.getLanguageSuffixes();
    
    const translationFileName = this.getTranslationFileName(lang, suffixes);
    this.translate.setDefaultLang('en');
    // this.translate.use(translationFileName);
  }
  
  getLanguageSuffixes(): UtilityLanguageSuffix[] {
    return JSON.parse(localStorage.getItem('languageSuffixes') ?? '[]');
  }
  getTranslationFileName(lang: number, suffixes: UtilityLanguageSuffix[]): string {
    const fileName = lang === Language.English ? 'en' : 'fr';
    const suffix = suffixes.find(x => x.language === lang)?.suffix || '';
    return `${fileName}${suffix}`;
  }
}
