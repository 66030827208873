import { FeederType } from "./feeder.model";

export class SurveyDetails {
  id: number = 0;
  customerId: number = 0;
  accountNo: string = '';
  accountName: string = '';
  address: string = '';
  currentTariff: string = '';
  region: string = '';
  district: string = '';
  feederName: string = '';
  dtId: number = 0;
  dtName: string = '';
  oldMeterNo: string = '';
  assignedDate: string = '';
  approvedDate :string='';
  rejectedDate:string='';
  assignedUser: string = '';
  assignedBy: string = '';
  approvedBy: string='';
  rejectedBy : string = '';
  currentStatus: string = '';
}

export class SurveyDetailsPage {
  existingCableSize: string = '';
  existingMeterEnclosure: string = '';
  inputCableDistance: string = '';
  installationType: string = '';
  meterLocation: string = '';
  numberOfServiceWires: string = '';
  outputCableDistance: string = '';
  recommendedCableSize: string = '';
  recommendedMeterType: string = '';
  serviceWireTraceableToMeterPoint: string = '';
  serviceWiresCondition: string = '';
  surveyInstallationStatus: string = '';
  remarksAndRecommendations: string = '';
  existingMeterInLocation:string='';
  dt: SurveyDt = new SurveyDt();
  customer: SurveyCustomer = new SurveyCustomer();
  images: SurveyImage[];
  installationMaterialRequirements: InstallationMaterialRequirements[] = [];
  customerLoadEstimates: CustomerLoadEstimates[] = [];
  isolationPoint: string = '';
  latitude: number = 0;
  longitude: number = 0;
}

export class SurveyDt {
  dtId: string = '';
  dtName: string = '';
  dtNumber: string = '';
  feederId: string = '';
  feederName: string = '';
  feederNumber: string = '';
  injectionSubstation:string='';
  feederType: FeederType.NOT_SELECTED;

}
export class SurveyCustomer {
  accountName: string = '';
  accountNumber: string = '';
  address: string = '';
  physicalAddress :string = '';
  areaName: string = '';
  buildingOwner: string = '';
  customerId: 3;
  customerType: string = '';
  email: string = '';
  latitude: number = 0;
  longitude: number = 0;
  meterBrand: string = '';
  meterBrandId: number = 0;
  meterStatus: string = '';
  name: string = '';
  oldMeterNo: string = '';
  phoneNo: string = '';
  regionName: string = '';
  spn: string = '';
  tariffId: number = 0;
  tariffName: string = '';
  tariffRate: string = '';
  typeOfConnection: string = '';
  itinerary: string = '';
  block: string = '';
  geocode: string = '';
  readingOnMeter: string = '';
  ghanaPostCode: string = '';
  activityCode: string = '';
  currentPlotCode: string = '';
  dtId: number = 0;
}
export class InstallationMaterialRequirements {
  id: number = 0;
  installationMaterial: string = '';
  materialCategory: string = '';
  quantity: number = 0;
  unitOfMeasurement: string = '';
}

export class CustomerLoadEstimates {
  equipmentType: string = '';
  id: number = 0;
  quantity: number = 0;
  rating: number = 0;
  totalWattage: number = 0;
}
export interface SurveyImage {
  url: string;
  imageType: any;
}
export class SurveySave {
  id: number = 0;
  assignedUserId: number = 0;
  customers: number[] = [];
}

export enum FilterByOptions {
  ALL = 0,
  WALL = 1,
  POLE = 2,
}

export enum SurveyInstallationStatus{
  Submitted = 1,
  Approved = 2,
  Rejected = 3,
  Assigned = 4,
  Pending = 5,
  Unvalidated = 6,
}
