import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { SortByOptions, UserType } from 'src/app/models/customer.model';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';
import { PoleDetails, PoleStatsOptions } from 'src/app/models/pole.model';
import { Region } from 'src/app/models/region.model';
import { AreaService } from 'src/app/services/area.service';
import { PoleService } from 'src/app/services/pole.service';
import { RegionService } from 'src/app/services/region.service';
import { TranslationService } from 'src/app/services/translation.service';
import { PermissionsEnum, SystemComponents } from 'src/app/models/role.model';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions.service';
import { OptionObj } from 'src/app/models/util.model';
import { UserForFilter } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-poles',
  templateUrl: './poles.component.html',
  styleUrls: ['./poles.component.scss'],
})
export class PolesComponent implements OnInit, OnDestroy {
  public PermissionsEnum = PermissionsEnum;
  stats: KeyValueItem[] = [];

  poles: PoleDetails[] = [];

  mapSortBy: Record<number, string> = {
    [SortByOptions.NEWEST]: 'COMMON.SORT_BY.NEWEST',
    [SortByOptions.SLRN_ASC]: 'COMMON.SORT_BY.SLRN_ASC',
    [SortByOptions.SLRN_DESC]: 'COMMON.SORT_BY.SLRN_DESC',
    [SortByOptions.ID_ASC]: 'COMMON.SORT_BY.ID_ASC',
    [SortByOptions.ID_DESC]: 'COMMON.SORT_BY.ID_DESC',
  };

  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;
  dateFrom: string;
  dateTo: string;
  searchText: string = '';
  searchTextUpdate = new Subject<string>();
  filterByValue: string = '';

  sortByValue: SortByOptions = SortByOptions.NEWEST;
  sortByOptions = SortByOptions;
  sortByName: string = this.mapSortBy[SortByOptions.NEWEST];

  showUpload: boolean = false;
  showSortBy: boolean = false;
  clickObservable: Observable<Event> = fromEvent(document, 'click');

  historyItems: any[] = [];
  isViewHistory: boolean = true;

  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  utilityId: number = 0;

  private ngUnsubscribe = new Subject<void>();
  selectedFilters: ChecklistItem[] = [];
  clicked: boolean = false;
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  poleTypesOptions: OptionObj[] = [];
  poleType: number = 0;
  poleCondition: number = 0;
  poleStatus: number = 0;
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  poleConditionOptions = [
    { name: 'Good', value: 1, translation: 'COMMON.POLE.CONDITION.GOOD' },
    {
      name: 'Bent Leaning',
      value: 2,
      translation: 'COMMON.POLE.CONDITION.BENT',
    },
    {
      name: 'Vandalized',
      value: 3,
      translation: 'COMMON.POLE.CONDITION.VANDALIZED',
    },
  ];
  poleStatusOptions = [
    { name: 'Pending', value: 1, translation: 'COMMON.ASSET_STATUS.PENDING' },
    { name: 'Complete', value: 2, translation: 'COMMON.ASSET_STATUS.COMPLETE' },
    // { name: 'Incomplete', value: 3 },
    { name: 'Assigned', value: 4, translation: 'COMMON.ASSET_STATUS.ASSIGNED' },
    { name: 'Rejected', value: 5, translation: 'COMMON.ASSET_STATUS.REJECTED' },
    { name: 'Retagged', value: 6, translation: 'COMMON.ASSET_STATUS.RETAGGED' },
  ];
  allCheckboxesChecked: boolean = false;
  dateTypes = [
    {
      name: 'LastUpdateTime',
      value: 'Last Update Time',
      translation: 'COMMON.DATE_TYPE.LAST_UPDATE',
    },
    {
      name: 'TaggedDate',
      value: 'Tagged Date',
      translation: 'COMMON.DATE_TYPE.TAGGED',
    },
    {
      name: 'ValidatedDate',
      value: 'Validated Date',
      translation: 'COMMON.DATE_TYPE.VALIDATED',
    },
  ];
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;
  slrnDropdownOpened: boolean = false;
  searchFilterApplied: boolean = false;

  usersFilter: any; // Stores value of filter
  mobileUsers: UserForFilter[] = []; // Store only mobile users
  adminUsers: UserForFilter[] = []; // Store only admin users
  allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
  filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
  selectedUsers: number[] = [];

  userType?: number;
  userTypeOptions = [
    {
      name: 'First Captured User',
      value: UserType.FirstCapturedUser,
      translation: 'COMMON.USER.TYPE.FIRST_CAPTURED',
    },
    {
      name: 'Last Captured User',
      value: UserType.LastCapturedUser,
      translation: 'COMMON.USER.TYPE.LAST_CAPTURED',
    },
    {
      name: 'Reassigned By',
      value: UserType.ReassignedBy,
      translation: 'COMMON.USER.TYPE.REASSIGNED',
    },
    {
      name: 'Validated By',
      value: UserType.ValidatedBy,
      translation: 'COMMON.USER.TYPE.VALIDATED',
    },
    {
      name: 'Audited By',
      value: UserType.AuditedBy,
      translation: 'COMMON.USER.TYPE.AUDITED',
    },
  ];
  mobileUserType: UserType[] = [
    UserType.FirstCapturedUser,
    UserType.LastCapturedUser,
  ];
  adminUserType: UserType[] = [
    UserType.ValidatedBy,
    UserType.ReassignedBy,
    UserType.AuditedBy,
  ];
  navigationExtras?: NavigationExtras;

  dtId?: number;
  dtName?: string;

  constructor(
    private poleService: PoleService,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private regionService: RegionService,
    private areaService: AreaService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {
    this.navigationExtras = this.router.getCurrentNavigation()?.extras;
  }

  ngOnInit(): void {
    if (this.navigationExtras?.state) {
      this.setDtFilter();
    }
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
    this.setFilters();
    this.getRegions();
    this.setCheckedUsers();
    this.getData();
    this.subscribeToClickEvent();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((_) => {
      if (this.searchText == '') this.search();
      this.searchFilterApplied = false;
    });

    this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay =
      this.selectedDateTypeDisplay ?? this.dateTypes[0].translation;
  }
  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
  }
  private getData(): void {
    this.getPolesStats(true);
    this.getPoleTypesForOptions();
    this.reloadTable();
  }

  setDtFilter() {
    if (!localStorage.getItem('filterPole')) {
      this.dtId = this.navigationExtras!.state!['dtId'];
      this.dtName = this.navigationExtras!.state!['dtName'];
      this.setFilterStorage();
    }
  }

  onFilterBy(option: any) {
    this.filterByValue = option;
    this.reloadTable(1);
  }

  setFilters() {
    var filter = localStorage.getItem('filterPole');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Pole') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.sortByValue = filterObject.filter.filterParams.sortBy;
    this.searchText = filterObject.filter.filterParams.search;
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.selectedDateType = filterObject.filter.filterParams.dateType;
    this.selectedDateTypeDisplay =
      this.dateTypes.find(
        (type) => type.name == filterObject.filter.filterParams.dateType
      )?.translation ?? this.dateTypes[0].translation;
    this.poleStatus = filterObject.filter.filterParams.status;
    this.poleType = filterObject.filter.filterParams.type;
    this.poleCondition = filterObject.filter.filterParams.condition;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
    this.selectedUsers = filterObject.filter.filterParams.selectedUsers
      ? filterObject.filter.filterParams.selectedUsers
      : [];
    this.sortByName = this.mapSortBy[this.sortByValue];
    this.dtId = filterObject.filter.filterParams.dtId
      ? filterObject.filter.filterParams.dtId
      : 0;
    this.dtName = filterObject.filter.filterParams.dtName
      ? filterObject.filter.filterParams.dtName
      : undefined;
  }
  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getPoleTypesForOptions() {
    this.poleService.getPoleTypesForOptions().subscribe(
      (response) => {
        this.poleTypesOptions = response.data;
        this.createFilterCards();
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  private getPolesStats(getAllCounts: boolean = false) {
    var filter = this.getFilterObject();
    this.getTotalStats(filter);
    this.getRetaggedStats(filter);
    this.getGoodConditionStats(filter);

    if (getAllCounts) {
      this.getAvgCustomerCountStats();
    }
  }

  private getTotalStats(obj: any) {
    var filter = {
      filter: obj,
      option: PoleStatsOptions.Total,
    };
    this.poleService.getPolesStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          var stat = this.stats.find(
            (x) => x.key == data.data.statType?.toString()!
          );
          if (stat) {
            stat.value = data.data.count!;
          } else {
            this.addStat(data.data.statType?.toString()!, data.data.count!);
          }
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getRetaggedStats(obj: any) {
    var filter = {
      filter: obj,
      option: PoleStatsOptions.Retagged,
    };
    this.poleService.getPolesStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          var stat = this.stats.find(
            (x) => x.key == data.data.statType?.toString()!
          );
          if (stat) {
            stat.value = data.data.count!;
          } else {
            this.addStat(data.data.statType?.toString()!, data.data.count!);
          }
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAvgCustomerCountStats() {
    var filter = {
      option: PoleStatsOptions.AvgCustomerCount,
    };
    this.poleService.getPolesStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.addStat(data.data.statType?.toString()!, data.data.count!);
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getGoodConditionStats(obj: any) {
    var filter = {
      filter: obj,
      option: PoleStatsOptions.InGoodCondition,
    };
    this.poleService.getPolesStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          var stat = this.stats.find(
            (x) => x.key == data.data.statType?.toString()!
          );
          if (stat) {
            stat.value = data.data.count!;
          } else {
            this.addStat(data.data.statType?.toString()!, data.data.count!);
          }
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };

    // Remove 'T23:59:59' from the dateTo to avoid errors
    if (obj.filterParams.dateTo) {
      obj.filterParams.dateTo = obj.filterParams.dateTo.replace(
        'T23:59:59',
        ''
      );
    }

    localStorage.setItem(
      'filterPole',
      JSON.stringify({ type: 'Pole', filter: obj })
    );
  }
  getFilterObject() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      dateType: this.selectedDateType,
      selectedRegions: this.selectedRegions,
      selectedAreas: this.selectedAreas,
      userType: this.userType
        ? this.selectedUsers.length > 0
          ? this.userType
          : null
        : null,
      selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
      type: this.poleType,
      condition: this.poleCondition,
      status: this.poleStatus,
      selectedIds: this.poles
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
      dtId: this.dtId ? Number(this.dtId) : null,
      dtName: this.dtName ? this.dtName : null,
    };
    return obj;
  }
  datesValid() {
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }
    return true;
  }
  private getAll(obj: any) {
    this.poleService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.poles = responseData?.data?.data?.length
            ? responseData?.data?.data?.map((el: any) => new PoleDetails(el))
            : [];
          this.poles.forEach((x) => {
            x.buildingsSLRN.replace(/,/g, ', ');
          });

          this.count = responseData.data.count ?? this.count;
          this.createFilterCards();
          this.removeFilterStorage();
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  isAllCheckBoxChecked() {
    return false;
  }

  checkAllCheckboxes() {
    this.poles.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }

  checkPole(id: number) {
    const pole = this.poles.find((x) => x.id === id);
    if (pole) {
      pole.isChecked = !pole.isChecked;
      this.areAllCheckboxesChecked();
    }
  }

  areAllCheckboxesChecked() {
    this.allCheckboxesChecked = this.poles.every((x) => x.isChecked);
  }

  reloadTable(page: any = null) {
    this.allCheckboxesChecked = false;
    if (page) this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
    };

    this.analyticsService.logCustomEvent('POLES: Filter list', {
      page: obj.pageInfo.page,
      sort_by: obj.filterParams.sortBy,
      search: obj.filterParams.search,
      filter_card: obj.filterParams.filterBy,
      user_type: obj.filterParams.userType,
      date_from: obj.filterParams.dateFrom,
      date_to: obj.filterParams.dateTo,
      users: obj.filterParams.selectedUsers,
      date_type: obj.filterParams.dateType,
      status: obj.filterParams.status,
      condition: obj.filterParams.condition,
      poleType: obj.filterParams.type,
      areas: obj.filterParams.selectedAreas,
      regions: obj.filterParams.selectedRegions,
      dtId: obj.filterParams.dtId,
    });

    this.getAll(obj);
  }

  search() {
    this.searchFilterApplied = this.searchText ? true : false;
    this.createFilterCards();
  }

  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByName = this.mapSortBy[option];
    // this.reloadTable();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showSortBy = false;
        this.showSelectDateType = false;
      });
  }

  download() {
    let obj = this.getFilterObject();

    this.analyticsService.logCustomEvent('POLES: Download', {
      sort_by: obj.sortBy,
      search: obj.search,
      filter_card: obj.filterBy,
      user_type: obj.userType,
      date_from: obj.dateFrom,
      date_to: obj.dateTo,
      users: obj.selectedUsers,
      date_type: obj.dateType,
      status: obj.status,
      condition: obj.condition,
      pole_type: obj.type,
      areas: obj.selectedAreas,
      regions: obj.selectedRegions,
      dtId: obj.dtId,
    });

    this.poleService.download(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  viewHistory(id: number) {
    this.poleService.getHistory(id).subscribe({
      next: (response) => {
        this.historyItems = response?.body?.data ?? [];
      },
    });
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });

    this.createFilterCards();
  }
  selectArea(e: any) {
    this.createFilterCards();
  }
  selectPoleType(e: any) {
    this.poleType = +e.target.value;
    this.createFilterCards();
  }
  selectPoleCondition(e: any) {
    this.poleCondition = +e.target.value;
    this.createFilterCards();
  }
  selectPoleStatus(e: any) {
    this.poleStatus = +e.target.value;
    this.createFilterCards();
  }
  createFilterCards() {
    this.selectedFilters = [];
    let selectedUsers = this.allUsers.filter((x) => x.checked);
    let selectedAreas = this.areas.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);
    this.selectedUsers = this.allUsers
      .filter((x) => x.checked)
      .map((x) => x.id);

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(
          this.utName,
          selectedAreas[i].name,
          'COMMON.UT.SINGLE'
        )
      );
    }

    if (this.userType) {
      var userTypeName = UserType[this.userType!];
      var userTypeTranslationKey = this.userTypeOptions.find(
        (option) => option.value == this.userType
      )?.translation;
      this.selectedFilters.push(
        new ChecklistItem(
          'User type',
          userTypeName,
          'COMMON.USER.TYPE.TITLE',
          userTypeTranslationKey
        )
      );
    }

    //users
    for (var i = 0; i < selectedUsers.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem('User', selectedUsers[i].name, 'COMMON.USER.SINGLE')
      );
    }

    if (this.poleType != 0 && this.poleTypesOptions.length > 0) {
      let poleName = this.poleTypesOptions.filter(
        (x) => x.key === this.poleType
      )[0].value;
      this.selectedFilters.push(
        new ChecklistItem(
          'Pole Type',
          poleName,
          'COMMON.TABLE_HEADERS.POLE_TYPE',
        )
      );
    }

    if (this.poleCondition != 0) {
      let poleCondition = this.poleConditionOptions.filter(
        (x) => x.value == this.poleCondition
      )[0];
      this.selectedFilters.push(
        new ChecklistItem(
          'Pole Condition',
          poleCondition.name,
          'COMMON.POLE.CONDITION.TITLE',
          poleCondition.translation
        )
      );
    }
    if (this.poleStatus != 0) {
      let poleStatus = this.poleStatusOptions.filter(
        (x) => x.value == this.poleStatus
      )[0];
      this.selectedFilters.push(
        new ChecklistItem(
          'Pole Status',
          poleStatus.name,
          'COMMON.POLE.STATUS',
          poleStatus.translation
        )
      );
    }

    if (this.dtId && this.dtName) {
      //dt from transformers
      this.selectedFilters.push(
        new ChecklistItem('Dt Name', this.dtName, 'COMMON.DT_NAME')
      );
    }

    if (this.dateFrom) {
      this.selectedFilters.push(
        new ChecklistItem(
          'Date From',
          this.dateFrom.toString(),
          'COMMON.DATE_FROM'
        )
      );
    }
    if (this.dateTo) {
      this.selectedFilters.push(
        new ChecklistItem('Date To', this.dateTo.toString(), 'COMMON.DATE_TO')
      );
    }

    if (this.searchText) {
      this.selectedFilters.push(
        new ChecklistItem('Search', this.searchText, 'COMMON.SEARCH')
      );
    }
  }
  removeFilter(item: ChecklistItem) {
    if (item.property == 'User') {
      this.allUsers.forEach((user) => {
        if (user.name === item.selectedValue) {
          user.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'User type') {
      this.userType = undefined;
      this.allUsers.forEach((user) => {
        // Clear list if he selects same filter again to get all cleared
        user.checked = false;
      });
      this.allUsers = this.filteredUsers = [...[]];
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Pole Type') {
      this.poleType = 0;
    } else if (item.property == 'Pole Condition') {
      this.poleCondition = 0;
    } else if (item.property == 'Pole Status') {
      this.poleStatus = 0;
    } else if (item.property == 'Dt Name') {
      this.dtId = 0;
      this.dtName = '';
    } else if (item.property == 'Date From') {
      this.dateFrom = '';
    } else if (item.property == 'Date To') {
      this.dateTo = '';
    } else if (item.property == 'Search') {
      this.searchText = '';
    }

    this.createFilterCards();
    if (this.selectedFilters.length == 0) {
      this.regions.forEach((region) => {
        region.checked = false;
      });
      this.reloadTable(1);
      this.getPolesStats();
    }
  }

  applyFilter() {
    this.reloadTable(1);
    this.getPolesStats();
  }

  resetFilter(e: any) {
    this.selectedFilters = [];
    this.regions.forEach((region) => {
      region.checked = false;
    });
    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];
    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = this.filteredUsers = [...[]];
    this.selectedAreas = [];
    this.selectedRegions = [];
    this.userType = undefined;
    this.poleType = 0;
    this.poleCondition = 0;
    this.poleStatus = 0;
    this.clicked = !this.clicked;

    this.dtId = undefined;
    this.dtName = undefined;
    this.searchText = '';
    this.dateFrom = '';
    this.dateTo = '';
    this.reloadTable(1);
    this.getPolesStats();
  }
  setCheckedAreas() {
    var filter = localStorage.getItem('filterPole');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Pole') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
    this.createFilterCards();
    this.removeFilterStorage();
  }
  setCheckedRegions() {
    var filter = localStorage.getItem('filterPole');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Pole') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
    this.createFilterCards();
    this.removeFilterStorage();
  }

  removeFilterStorage() {
    if (this.allAreas.length > 0 && this.regions.length > 0)
      localStorage.removeItem('filterPole');
  }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.translation;
    this.showSelectDateType = false;
  }

  formatSlrn(originalString: string): string {
    return originalString.split(',', 2).toString().replace(/,/g, ', ');
  }

  async setCheckedUsers() {
    var filter = localStorage.getItem('filterPole');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Pole') return;
    if (
      filterObject.filter.filterParams.selectedUsers &&
      filterObject.filter.filterParams.userType
    ) {
      this.userType = filterObject.filter.filterParams.userType;
      this.getUsersForFilter(true, filterObject);
    }
  }

  resetUserList() {
    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = [];
  }

  selectUserType(e: any) {
    if (this.userType) this.resetUserList();

    if (e.target.value != '') {
      this.userType = e.target.value;
      this.getUsersForFilter(false);
    } else {
      this.userType = undefined;
    }

    this.createFilterCards();
  }

  getUsersForFilter(isFromCache: boolean, filterObject?: any) {
    if (
      this.userType == UserType.FirstCapturedUser ||
      this.userType == UserType.LastCapturedUser
    ) {
      // Dobavi mobilne usere iz baze ako nisi
      if (this.mobileUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Mobile)
          .subscribe((resp) => {
            this.mobileUsers = resp.data;
            this.allUsers = this.filteredUsers = this.mobileUsers;
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
            }
            this.createFilterCards();
          });
      } else {
        this.allUsers = this.filteredUsers = [...this.mobileUsers];
      }
    } else {
      let permission = PermissionsEnum.Pole_Assign;

      switch (Number(this.userType)) {
        case UserType.ValidatedBy:
          permission = PermissionsEnum.Pole_ApproveReject;
          break;
        case UserType.AuditedBy:
          permission = PermissionsEnum.Pole_AuditReview;
          break;
      }
      if (this.adminUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Admin)
          .subscribe((resp) => {
            this.adminUsers = resp.data;
            this.allUsers = this.filteredUsers = [
              ...this.adminUsers.filter((x) =>
                x.permissions?.some((x) => Number(x) == permission)
              ),
            ];
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
              this.createFilterCards();
            }
          });
      } else {
        this.allUsers = this.filteredUsers = [
          ...this.adminUsers.filter((x) =>
            x.permissions?.some((x) => Number(x) == permission)
          ),
        ];
      }
    }
  }

  applySearchFilter(e: any) {
    this.filteredUsers = this.allUsers.filter((user) => user.name.includes(e));
  }

  selectUsers(e: any) {
    if (e.status) {
      this.allUsers.push(e.value);
    } else {
      var index = this.allUsers.indexOf(e.value);
      this.allUsers.splice(index, 1);
    }
    this.createFilterCards();
  }

  private addStat(key: string, value: number) {
    const newItem = new KeyValueItem();
    newItem.key = key;
    newItem.value = value;

    var keyType: PoleStatsOptions =
      PoleStatsOptions[key as keyof typeof PoleStatsOptions];
    switch (keyType) {
      case PoleStatsOptions.Total:
        newItem.order = 1;
        newItem.friendlyName = 'POLES.TOTAL';
        break;
      case PoleStatsOptions.AvgCustomerCount:
        newItem.order = 2;
        newItem.friendlyName = 'POLES.AVG_CUSTOMERS_PER_POLE';
        break;
      case PoleStatsOptions.Retagged:
        newItem.order = 3;
        newItem.friendlyName = 'COMMON.ASSET_STATUS.RETAGGED'
        break;
      case PoleStatsOptions.InGoodCondition:
        newItem.order = 4;
        newItem.friendlyName = 'POLES.IN_GOOD_COND';
        break;
    }

    this.stats.push(newItem);
    if (this.stats.length == 4)
      this.stats.sort((a, b) =>
        a.order < b.order ? -1 : a.order > a.order ? 1 : 0
      );
  }
}
