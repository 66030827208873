import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, fromEvent, debounceTime, takeUntil } from 'rxjs';
import { Area } from '../../../../models/area.model';
import { AssetStatus, BuildingDetails } from '../../../../models/building.model';
import { ChecklistItem } from '../../../../models/checklist-item.model';
import { UserType } from '../../../../models/customer.model';
import { SortByOptions } from '../../../../models/meter.model';
import { Region } from '../../../../models/region.model';
import { PermissionsEnum, SystemComponents } from '../../../../models/role.model';
import { UserForFilter } from '../../../../models/user.model';
import { AreaService } from '../../../../services/area.service';
import { BuildingService } from '../../../../services/building.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { RegionService } from '../../../../services/region.service';
import { TranslationService } from '../../../../services/translation.service';
import { UserService } from '../../../../services/user.service';
import { ReportStateService } from '../../services/report-state.service';

@Component({
  selector: 'buildings-tab',
  templateUrl: './buildings-tab.component.html',
  styleUrls: ['./../data-quality-rejection.component.scss',]
})
export class BuildingsTabComponent implements OnInit {
  PermissionsEnum = PermissionsEnum;

  buildings: BuildingDetails[] = [];

  mapSortBy: Record<number, string> = {
    [SortByOptions.NEWEST]: 'COMMON.SORT_BY.NEWEST',
    [SortByOptions.SLRN_ASC]: 'COMMON.SORT_BY.SLRN_ASC',
    [SortByOptions.SLRN_DESC]: 'COMMON.SORT_BY.SLRN_DESC',
  };
  //filters
  clicked: boolean = false;
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  statusOptions = [
    { name: 'Pending', value: AssetStatus.Pending },
    { name: 'Complete', value: AssetStatus.Complete },
    { name: 'Assigned', value: AssetStatus.Assigned },
    { name: 'Rejected', value: AssetStatus.Rejected },
    { name: 'Retagged', value: AssetStatus.Retagged },
  ];
  tag?: number;
  tagOptions = [
    { name: 'Tagged', value: 0, translation: 'COMMON.BUILDING.TYPE.TAGGED' },
    { name: 'Inaccessible', value: 1, translation: 'COMMON.BUILDING.TYPE.INACCESSIBLE' },
  ];
  selectedFilters: ChecklistItem[] = [];

  @Input() dateFrom: string;
  @Input() dateTo: string;

  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;

  searchText: string = '';
  searchTextUpdate = new Subject<string>();

  sortByValue: SortByOptions = SortByOptions.NEWEST;
  sortByOptions = SortByOptions;
  filterByValue: string = '';
  sortByName: string = this.mapSortBy[SortByOptions.NEWEST];

  showDownload: boolean = false;
  downloadBuilding = false;
  downlaodInaccessible = false;
  showSortBy: boolean = false;
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  private ngUnsubscribe = new Subject<void>();
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  utilityId: number = 0;
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  allCheckboxesChecked: boolean = false;
  dateTypes = [
    { name: 'ValidatedDate', value: 'Validated Date', translation: 'COMMON.DATE_TYPE.VALIDATED' },
    { name: 'LastUpdateTime', value: 'Last Update Time', translation: 'COMMON.DATE_TYPE.LAST_UPDATE' },
    { name: 'TaggedDate', value: 'Tagged Date', translation: 'COMMON.DATE_TYPE.TAGGED' },
  ];
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;

  usersFilter: any; // Stores value of filter
  mobileUsers: UserForFilter[] = []; // Store only mobile users
  adminUsers: UserForFilter[] = []; // Store only admin users
  allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
  filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
  selectedUsers: number[] = [];

  userType?: number;
  userTypeOptions = [
    { name: 'First Captured User', value: UserType.FirstCapturedUser, translation: 'COMMON.USER.TYPE.FIRST_CAPTURED' },
    { name: 'Last Captured User', value: UserType.LastCapturedUser, translation: 'COMMON.USER.TYPE.LAST_CAPTURED' },
    { name: 'Reassigned By', value: UserType.ReassignedBy, translation: 'COMMON.USER.TYPE.REASSIGNED' },
    { name: 'Validated By', value: UserType.ValidatedBy, translation: 'COMMON.USER.TYPE.VALIDATED' },
    { name: 'Audited By', value: UserType.AuditedBy, translation: 'COMMON.USER.TYPE.AUDITED' },
  ];
  mobileUserType: UserType[] = [
    UserType.FirstCapturedUser,
    UserType.LastCapturedUser,
  ];
  adminUserType: UserType[] = [
    UserType.ValidatedBy,
    UserType.ReassignedBy,
    UserType.AuditedBy,
  ];
  @Output() buildingsFetched: EventEmitter<number> = new EventEmitter();

  constructor(
    private buildingService: BuildingService,
    private toastr: ToastrService,
    private regionService: RegionService,
    private areaService: AreaService,
    private translationService: TranslationService,
    public route: ActivatedRoute,
    public permissionsService: PermissionsService,
    private userService: UserService,
    private router: Router,
    private reportStateService: ReportStateService,
  ) { }

  ngOnInit(): void {
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
    this.subscribeToClickEvent();
    this.setFilters();
    this.getRegions();
    this.setCheckedUsers();
    this.getData();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.searchText == '') this.search();
    });
    this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay = this.selectedDateTypeDisplay ?? this.dateTypes[0].translation;
  }
  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
  }
  handler1() {
    console.log(this.dateFrom);
  }
  handler2() {
    console.log(this.dateTo);
  }
  private getData(): void {
    //   this.getBuildingsStats();
    this.reloadTable();
  }

  getFilterObject(isForStorage: boolean = false) {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      userType: isForStorage ? this.userType ? this.userType : null : (this.userType
        ? this.selectedUsers.length > 0
          ? this.userType
          : null
        : null),
      dateFrom: this.dateFrom ? this.dateFrom : null,
      selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
      dateTo: this.dateTo ? (isForStorage ? this.dateTo : `${this.dateTo}T23:59:59`) : null,
      dateType: this.selectedDateType,
      status: AssetStatus.Rejected,
      tag: this.tag != undefined ? (this.tag == 0 ? false : true) : null,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
      selectedIds: this.buildings
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
    };
    return obj;
  }

  selectDataQuality(id: number) {
    this.router.navigate([`/buildings/data-quality/${id}`]);
  }
  async setCheckedUsers() {
    var filter = localStorage.getItem(this.reportStateService.buildingsTabKey);
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Building') return;
    if (
      // filterObject.filter.filterParams.selectedUsers &&
      filterObject.filter.filterParams.userType
    ) {
      this.userType = filterObject.filter.filterParams.userType;
      this.getUsersForFilter(true, filterObject);
    }
  }

  setFilters() {
    var filter = localStorage.getItem(this.reportStateService.buildingsTabKey);
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Building') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.sortByValue = filterObject.filter.filterParams.sortBy;
    this.searchText = filterObject.filter.filterParams.search;
    this.filterByValue = filterObject.filter.filterParams.filterBy;
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.selectedDateType = filterObject.filter.filterParams.dateType;
    this.selectedDateTypeDisplay = this.dateTypes.find((type) => type.name == filterObject.filter.filterParams.dateType)?.translation ?? this.dateTypes[0].translation;
    this.userType = filterObject.filter.filterParams.userType
      ? filterObject.filter.filterParams.userType
      : undefined;
    this.selectedUsers = filterObject.filter.filterParams.selectedUsers
      ? filterObject.filter.filterParams.selectedUsers
      : [];
    this.tag =
      filterObject.filter.filterParams.tag != null
        ? filterObject.filter.filterParams.tag
          ? 1
          : 0
        : undefined;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
    this.sortByName = this.mapSortBy[this.sortByValue];
  }
  private getAll(obj: any) {
    this.buildingService.getAllRejectionReports(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.buildings = responseData.data.data;
          this.count = responseData.data.count ?? this.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  datesValid() {
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }
  
    return true;
  }

  checkAllCheckboxes() {
    this.buildings.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }

  checkBuilding(id: number) {
    const building = this.buildings.find((x) => x.id === id);
    if (building) {
      building.isChecked = !building.isChecked;
      this.areAllCheckboxesChecked();
    }
  }

  areAllCheckboxesChecked() {
    this.allCheckboxesChecked = this.buildings.every((x) => x.isChecked);
  }

  onFilterBy(option: any) {
    this.filterByValue = option;
    this.reloadTable(1);
  }

  reloadTable(page: any = null) {
    this.allCheckboxesChecked = false;
    if (page) this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject(),
    };
    this.getAll(obj);
  }

  search() {
    this.createFilterCards();
  }
  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject(true),
      utilityId: this.utilityId,
    };

    localStorage.setItem('cameFrom', 'Building');
    localStorage.setItem(
      this.reportStateService.buildingsTabKey,
      JSON.stringify({ type: 'Building', filter: obj })
    );
  }
  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByName = this.mapSortBy[option];
    this.reloadTable();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        //   this.showUpload = false;
        this.showSortBy = false;
        this.showSelectDateType = false;
      });
  }
  download() {
    let obj = this.getFilterObject();
    this.buildingService.downloadRejectionReports(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });
    this.createFilterCards();
  }
  selectArea(e: any) {
    this.createFilterCards();
  }

  selectTag(e: any) {
    if (e.target.value != '') {
      this.tag = e.target.value;
    } else {
      this.tag = undefined;
    }
    this.createFilterCards();
  }

  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.setCheckedAreas();
          this.createFilterCards();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == 'User') {
      this.allUsers.forEach((user) => {
        if (user.name === item.selectedValue) {
          user.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'User type') {
      this.userType = undefined;
      this.allUsers.forEach((user) => {
        // Clear list if he selects same filter again to get all cleared
        user.checked = false;
      });
      this.allUsers = this.filteredUsers = [...[]];
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Tag') {
      this.tag = undefined;
    } else if (item.property == 'Search'){
      this.searchText = '';
    } else if (item.property == 'Date From'){
      this.dateFrom = '';
    } else if (item.property == 'Date To'){
      this.dateTo = '';
    }


    this.createFilterCards();

    if (this.selectedFilters.length == 0) {
      this.regions.forEach((region) => {
        region.checked = false;
      });
      this.selectedRegions = [];
      this.setFilterStorage();
      this.reloadTable(1);
    }
  }

  resetFilter(e: any) {
    this.selectedFilters = [];

    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = this.filteredUsers = [...[]];

    this.regions.forEach((region) => {
      region.checked = false;
    });
    this.selectedRegions = [];

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.selectedAreas = [];
    this.areas = []
    this.userType = undefined;
    this.tag = undefined;
    this.searchText = '';
    this.dateFrom = '';
    this.dateTo = '';

    this.clicked = !this.clicked;
    this.setFilterStorage();
    this.reloadTable(1);
  }

  applyFilter(item: ChecklistItem){
    this.reloadTable(1);
  }

  createFilterCards() {
    this.selectedFilters = [];

    let selectedUsers = this.allUsers.filter((x) => x.checked);
    let selectedAreas = this.areas.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedUsers = this.allUsers
      .filter((x) => x.checked)
      .map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name, 'COMMON.UT.SINGLE')
      );
    }

    //search
    if (this.searchText){
      this.selectedFilters.push(
        new ChecklistItem('Search', this.searchText)
      );
    }

    //dates
    if (this.dateFrom){
      this.selectedFilters.push(
        new ChecklistItem('Date From', this.dateFrom)
      );
    }
    
    if (this.dateTo){
      this.selectedFilters.push(
        new ChecklistItem('Date To', this.dateTo)
      );
    }

    if (this.userType) {
      var userType = this.userTypeOptions.filter((option) => option.value == this.userType)[0];
      this.selectedFilters.push(new ChecklistItem('User type', userType.name, 'COMMON.USER.TYPE.TITLE', userType.translation));
    }

    //users
    for (var i = 0; i < selectedUsers.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem('User', selectedUsers[i].name, 'COMMON.USER.SINGLE')
      );
    }

    //tag
    if (this.tag != undefined) {
      var tag = this.tagOptions[this.tag];
          this.selectedFilters.push(new ChecklistItem('Tag', tag.name, 'COMMON.TAG.TITLE', tag.translation));
    }
  }
  setCheckedAreas() {
    var filter = localStorage.getItem(this.reportStateService.buildingsTabKey);
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Building') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  setCheckedRegions() {
    var filter = localStorage.getItem(this.reportStateService.buildingsTabKey);
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Building') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  // removeFilterStorage() {
  //   if (this.allAreas.length > 0 && this.regions.length > 0)
  //     localStorage.removeItem(this.reportStateService.buildingsTabKey);
  // }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.translation;
    this.showSelectDateType = false;
  }

  resetUserList() {
    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = [];
  }

  selectUserType(e: any) {
    if (this.userType)
      this.resetUserList();

    if (e.target.value != '') {
      this.userType = e.target.value;
      this.getUsersForFilter(false);
    } else {
      this.userType = undefined;
    }

    this.createFilterCards();
  }

  getUsersForFilter(isFromCache: boolean, filterObject?: any) {
    if (
      this.userType == UserType.FirstCapturedUser ||
      this.userType == UserType.LastCapturedUser
    ) {
      // Dobavi mobilne usere iz baze ako nisi
      if (this.mobileUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Mobile)
          .subscribe((resp) => {
            this.mobileUsers = resp.data;
            this.allUsers = this.filteredUsers = this.mobileUsers;
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
            }
            this.createFilterCards();
          });
      } else {
        this.allUsers = this.filteredUsers = [...this.mobileUsers];
      }
    } else {
      if (this.adminUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Admin)
          .subscribe((resp) => {
            this.adminUsers = resp.data;
            this.allUsers = this.filteredUsers = this.adminUsers;
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
              this.createFilterCards();
            }
          });
      } else {
        this.allUsers = this.filteredUsers = [...this.adminUsers];
      }
    }
  }

  applySearchFilter(e: any) {
    this.filteredUsers = this.allUsers.filter((user) => user.name.includes(e));
  }

  selectUsers(e: any) {
    if (e.status) {
      this.allUsers.push(e.value);
    } else {
      var index = this.allUsers.indexOf(e.value);
      this.allUsers.splice(index, 1);
    }
    this.createFilterCards();
  }

}

