import { Component, OnInit, ViewChild } from '@angular/core';
import { ReadingsMenuTab } from 'src/app/models/readingsMenuTab.model';
import { Subject } from 'rxjs/internal/Subject';
import { PermissionsEnum } from 'src/app/models/role.model';
import { UtilityService } from 'src/app/services/utility.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import {Location} from '@angular/common'; 
import { MeterReadingsListComponent } from './meter-readings/meter-readings-list/meter-readings-list.component';

@Component({
  selector: 'app-readings',
  templateUrl: './readings.component.html',
  styleUrls: ['./readings.component.scss']
})
export class ReadingsComponent implements OnInit {

  selectedUtilities: number[] = [];
  utilityList: any[];
  selectedUtilityId: number = 0;
  public ReadingsMenuItem = ReadingsMenuTab;
  readingSelected: ReadingsMenuTab = ReadingsMenuTab.MeterReadings;

  public searchText: string = '';
  public PermissionsEnum = PermissionsEnum;
  searchTextUpdate = new Subject<string>();
  readingType: string = "";

  @ViewChild(MeterReadingsListComponent)meterReadingsListComponent!: MeterReadingsListComponent;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public permissionsService: PermissionsService,
    private utilityService: UtilityService,
  ) { }

  ngOnInit(): void {
    var utilityId = parseInt(localStorage.getItem('utilityId')!);
    if(utilityId != 0) {
      this.selectedUtilityId = utilityId;
    }
    if(!this.permissionsService.hasUtility) {
      this.getUtilities();
    }
    else {

      this.readingType = this.route.snapshot.paramMap.get('id') || "";
      if(this.readingType != ""){
        this.readingSelected = Number(this.readingType);
      }
      this.searchTextUpdate.pipe(
        debounceTime(500))
        .subscribe(value => {
          if(this.searchText=="")
            this.search();
        });
      
    }
  }

  getUtilities() {
    this.utilityService.getUtilitiesForSelect().subscribe({
      next: (data) => {
        this.utilityList = data.data;
        this.selectedUtilities = this.utilityList.map(s=>s.key);
      },
      error: (error) => console.log(error.statusText)
    }); 
  }
  selectUtilityChild(e: any) {
    this.selectedUtilityId = parseInt(e.target.value); 
    setTimeout(() => { //timeout 0 milliseconds, just to have some time to update the utilityId in child component
      this.search();
    }, 0);
  }

  switchMenuItem(item: ReadingsMenuTab): void {
    this.searchText = '';
    this.readingSelected = item;
    this.location.replaceState(`readings/tab/${this.readingSelected}`);
  }
  search() : void {
    switch (this.readingSelected) {
      case ReadingsMenuTab.MeterReadings:
        break;
      default:
        break;
    }
  }

}
