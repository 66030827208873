<div class="container-fluid customers-root">
<!-- View Details implemented: -->
    <div class="view-details-container" *ngIf="detailsShowed">
      <app-add-survey
          [isPopUp]="true"
          (closePopUpChange)="detailsShowed = false; reloadTable()"
      ></app-add-survey>
    </div>
    <app-card-analytics [stats]="stats" [reset]="reset" (shareFilterValue)="onFilterBy($event)"></app-card-analytics>

    <div class="table-root">
      <div class="d-flex justify-content-start switch-toggle transparent">
        <a class="separated-toggle-item" [ngClass]="currentTab == 4 ? 'active' : ''" (click)="changeTab(4);reset = !reset"><div><span class="bubble">{{countAssigned | number}}</span> {{'COMMON.ASSET_STATUS.ASSIGNED' | translate}}</div></a>
        <a class="separated-toggle-item" [ngClass]="currentTab == 1 ? 'active' : ''" (click)="changeTab(1);reset = !reset"><div><span class="bubble">{{countSubmited | number}}</span> {{'COMMON.ASSET_STATUS.SUBMITTED' | translate}}</div></a>
        <a class="separated-toggle-item" [ngClass]="currentTab == 2 ? 'active' : ''" (click)="changeTab(2);reset = !reset"><div><span class="bubble">{{countApproved | number}}</span> {{'COMMON.ASSET_STATUS.APPROVED' | translate}}</div></a>
        <a class="separated-toggle-item" [ngClass]="currentTab == 3 ? 'active' : ''" (click)="changeTab(3);reset = !reset"><div><span class="bubble">{{countRejected | number}}</span> {{'COMMON.ASSET_STATUS.REJECTED' | translate}}</div></a>
      </div>
      <div class="table-root-child">
        <div class="table-action-wrapper d-flex align-items-center justify-content-between">
            <div id="survey_search" class="search-input">
              <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()">
              <input type="text" [placeholder]="'COMMON.SEARCH' | translate" [(ngModel)]="searchText" (keydown.enter)="search()" (ngModelChange)="this.searchTextUpdate.next($event)">
            </div>
            <div class="ml-auto d-flex">
              <div>
                <div class="d-flex">
                  <div id="survey_date-from" class="datepicker-container" style="width: 24rem;">
                    <label for="dateFrom">
                      {{'COMMON.FROM' | translate}}:
                    </label>
                    <input type="date" id="dateFrom" name="dateFrom" [ngClass]="(dateFrom>dateTo) ? 'btn datepicker-icon-error' : 'btn datepicker-icon'" [(ngModel)]="dateFrom" (change)="datesValid()==true && search()" >

                  </div>
                  
                  <div id="survey_date-to" class="datepicker-container" >
                    <label for="dateTo" class="right-label">
                      {{'COMMON.TO' | translate}}:
                    </label>
                    <input type="date" id="dateTo" name="dateTo" [ngClass]="(dateFrom>dateTo) ? 'btn datepicker-icon-error' : 'btn datepicker-icon'" [(ngModel)]="dateTo"  (change)="datesValid()==true && search()" >

                    </div>
                </div>
              </div>
              
                <div id="survey_filter-by" class="sort-by-root" (click)="showFilterBy=!showFilterBy;" click-stop-propagation>
                    <div class="dropdown">
                      <span>{{'COMMON.FILTER_BY' | translate}} {{'COMMON.TYPE' | translate | lowercase}}:</span>
                      <span>{{filterByName | translate}}</span>
                      <span><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down"></span>
                    </div>
                    <div class="dropdown-opened" *ngIf="showFilterBy">
                      <div id="survey_filter-by-all" (click)="filterByType(filterByOptions.ALL)">{{'COMMON.FILTER_BY_OPTIONS.ALL' | translate}}</div>
                      <div id="survey_filter-by-wall" (click)="filterByType(filterByOptions.WALL)">{{'COMMON.FILTER_BY_OPTIONS.WALL' | translate}}</div>
                      <div id="survey_filter-by-pole" (click)="filterByType(filterByOptions.POLE)">{{'COMMON.FILTER_BY_OPTIONS.POLE' | translate}}</div>
                    </div>
                </div>
                  <button class="btn btn-icon" (click)="download()" *ngIf="currentTab!=4">
                    <span>{{'COMMON.DOWNLOAD' | translate}}</span>
                  </button>
                <div *ngIf="currentTab==4 && permissionsService.isSurveyCreateEditAllowed">
                  <button class="btn btn-primary btn-upload" (click)="showUpload=!showUpload;" click-stop-propagation>
                    <img src="../../assets/icons/chevron-down.svg" alt="Chevron down">
                  </button>
                  <div *ngIf="showUpload" class="xlsx-upload" [routerLink]="'/upload/6/' + utilityId">
                    <div><img src="../../assets/icons/upload-arrow.svg" alt="Upload icon" /></div>
                    <div>{{'COMMON.UPLOAD_XLSX' | translate}}</div>
                  </div>
                </div>
                <button class="btn btn-primary btn-add-new" (click)="openCreateModal()" *ngIf="currentTab==4 && permissionsService.isSurveyCreateEditAllowed">
                  <img src="../../assets/icons/plus.svg" alt="Plus icon">
                    <span>{{'SURVEYS.NEW' | translate}}</span>
                </button>
            </div>
          </div>


          <div class="filter">
            <div class="filter-list">
              {{'COMMON.FILTER' | translate}}
              <app-multi-select-dropdown class="multiselect"
                                         id="survey_region_filter"
                                         [clicked] = "clicked"
                                         [items]="regions" 
                                         [label]="'COMMON.BU.PLURAL'"
                                         [showMore]="false"
                                         [requireApply]="true"
                                         (shareIndividualCheckedList)="selectRegion($event)"
                                         (applyFilterEmitter)="reloadTable(1)"
                                         >
              </app-multi-select-dropdown>
              <app-multi-select-dropdown class="multiselect"
                                         id="survey_area_filter"
                                         [clicked] = "clicked"
                                         [items]="areas" 
                                         [label]="'COMMON.UT.PLURAL'"
                                         [showMore]="false"
                                         [requireApply]="true"
                                         (shareIndividualCheckedList)="selectArea($event)"
                                         (applyFilterEmitter)="reloadTable(1)"
                                         >
              </app-multi-select-dropdown>
              <select id="survey_tariff_filter" name="tariff-select" (change)="selectTariff($event)" class="form-select" >
                <option value="0" [selected]="tariff == 0" disabled>{{'COMMON.TARIFF.SINGLE' | translate}}</option>
                <option [id]="'survey_tariff_filter-' + (i + 1)" [selected]="tariff == o.id" *ngFor="let o of tariffs; let i = index" value="{{o.id}}">
                    {{o.name}}
                </option>
              </select>  



              <select id="survey_customer-type_filter" name="type-select" (change)="selectCustomerType($event)" class="form-select" >
                <option value="0" [selected]="customerType == 0" disabled>{{'COMMON.MBC_DETAILS.CUSTOMER_TYPE' | translate}}</option>
                <option [id]="'survey_customer-type_filter-' + (i + 1)" [selected]="customerType == o.value" *ngFor="let o of customerTypeOptions; let i = index" value="{{o.value}}">
                    {{o.translation | translate}}
                </option>
              </select>  
              <select id="survey_cutomer-phase_filter" name="phase-select" (change)="selectCustomerPhase($event)" class="form-select" >
                <option value="0" [selected]="customerPhase == -1" disabled>{{'COMMON.CUSTOMER.CUSTOMER_PHASE' | translate}}</option>
                <option [id]="'survey_customer-phase_filter-' + (i + 1)" [selected]="customerPhase == o.value" *ngFor="let o of customerPhaseOptions; let i = index" value="{{o.value}}">
                    {{o.translation | translate}}
                </option>
              </select>  
              <select id="survey_meter-status_filter" name="status-select" (change)="selectMeterStatus($event)" class="form-select" >
                <option value="0" [selected]="meterStatus == 0" disabled>{{'COMMON.MBC_DETAILS.METER_STATUS' | translate}}</option>
                <option [id]="'survey_meter-status_filter-' + (i + 1)" [selected]="meterStatus == o.value" *ngFor="let o of meterStatusOptions; let i = index" value="{{o.value}}">
                    {{o.translation | translate}}
                </option>
              </select>  
              <select id="survey_survey-status_filter" *ngIf="currentTab==1" name="survey-status-select" (change)="selectSurveyStatus($event)" class="form-select" >
                <option value="0" [selected]="surveyStatus == 0" disabled>{{'SURVEYS.STATUS' | translate}}</option>
                <option [id]="'survey_survey-status_filter-' + (i + 1)" [selected]="surveyStatus == o.value" *ngFor="let o of surveyStatusOptions; let i = index" value="{{o.value}}">
                    {{o.translation | translate}}
                </option>
              </select> 
            </div>
            <div *ngIf="selectedFilters.length > 0">
              <app-filter-checklist [filters]="selectedFilters" 
                                    (resetFilterEmitter)="resetFilter($event)"
                                    (removeFilterEmitter)="removeFilter($event)"
                                    >
              </app-filter-checklist>
            </div>
          </div>
          <div class="table">
            <div *ngIf="surveys">
              <table class="table border-bottom">
                <thead class="table-light">
                  <tr>
                    <th id="survey_table_header_checkbox"><input type="checkbox" class="form-check-input" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th>
                    <th id="survey_table_header_survey-id">{{'SURVEYS.ID' | translate}}</th>
                    <th id="survey_table_header_acc-no">{{'COMMON.MBC_DETAILS.ACCOUNT_NO' | translate}}</th>
                    <th id="survey_table_header_building-owner">{{'COMMON.TABLE_HEADERS.BUILDING_OWNER' | translate}}</th>
                    <th id="survey_table_header_address">{{'COMMON.ADDRESS' | translate}}</th>
                    <th id="survey_table_header_current-tariff">{{'COMMON.CURRENT_TARIFF' | translate}}</th>
                    <th id="survey_table_header_region">{{'COMMON.BU.SINGLE' | translate}}</th>
                    <th id="survey_table_header_district">{{'COMMON.UT.SINGLE' | translate}}</th>
                    <th id="survey_table_header_feeder">{{'COMMON.FEEDER' | translate}}</th>
                    <th id="survey_table_header_dt">{{'TRANSFORMERS.DT' | translate}}</th>
                    <th id="survey_table_header_meter-no">{{'COMMON.MBC_DETAILS.METER_NO' | translate}}</th>
                    <th id="survey_table_header_assigned-date" *ngIf="currentTab==1 || currentTab==4">{{'COMMON.TABLE_HEADERS.ASSIGNED_DATE' | translate}}</th>
                    <th id="survey_table_header_rejected-date" *ngIf="currentTab==3">{{'COMMON.TABLE_HEADERS.REJECTED_DATE' | translate}}</th>
                    <th id="survey_table_header_approved-date" *ngIf="currentTab==2">{{'COMMON.TABLE_HEADERS.APPROVED_DATE' | translate}}</th>
                    <th id="survey_table_header_assigned">{{'COMMON.MBC_DETAILS.ASSIGNED' | translate}}</th>
                    <th id="survey_table_header_assigned-by" *ngIf="currentTab==1 || currentTab==4">{{'COMMON.TABLE_HEADERS.ASSIGNED_BY' | translate}}</th>
                    <th id="survey_table_header_rejected-by" *ngIf="currentTab==3">{{'COMMON.TABLE_HEADERS.REJECTED_BY' | translate}}</th>
                    <th id="survey_table_header_approved-by" *ngIf="currentTab==2">{{'COMMON.TABLE_HEADERS.APPROVED_BY' | translate}}</th>
                    <th id="survey_table_header_current-status">{{'COMMON.TABLE_HEADERS.CURRENT_STATUS' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [id]="'survey_table_row-' + (i + 1)" *ngFor="let survey of surveys; let i=index;">
                    <td [id]="'survey_table_checkbox-' + (i + 1)"><input type="checkbox" class="form-check-input" value="{{survey.id}}"></td>
                    <td [id]="'survey_table_survey-id-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey?.id | hasValue}}</td>
                    <td [id]="'survey_table_acc-no-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.accountNo | hasValue}}</td>
                    <td [id]="'survey_table_acc-name-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.accountName | hasValue}} </td>
                    <td [id]="'survey_table_address-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.address | hasValue}} </td>
                    <td [id]="'survey_table_current-tariff-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.currentTariff | hasValue}} </td>
                    <td [id]="'survey_table_region-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.region | hasValue}} </td>
                    <td [id]="'survey_table_district-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.district | hasValue}} </td>
                    <td [id]="'survey_table_feeder-name-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.feederName | hasValue}} </td>
                    <td [id]="'survey_table_dt-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.dtName | hasValue}} </td>
                    <td [id]="'survey_table_old-meter-no-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.oldMeterNo | hasValue}} </td>
                    <td [id]="'survey_table_assigned-date-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==1 || currentTab==4">{{survey.assignedDate | hasValue}} </td>
                    <td [id]="'survey_table_rejected-date-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==3">{{survey.rejectedDate | hasValue}} </td>
                    <td [id]="'survey_table_approved-date-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==2">{{survey.approvedDate | hasValue}} </td>
                    <td [id]="'survey_table_assigned-user-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">{{survey.assignedUser | hasValue}} </td>
                    <td [id]="'survey_table_assigned-by-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==1 || currentTab==4">{{survey.assignedBy | hasValue}} </td>
                    <td [id]="'survey_table_rejected-by-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==3">{{survey.rejectedBy | hasValue}} </td>
                    <td [id]="'survey_table_approved-by-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]" *ngIf="currentTab==2" >{{survey.approvedBy | hasValue}} </td>
                    <td [id]="'survey_table_current-status-' + (i + 1)" (click)="setFilterStorage()" [routerLink]="['details', survey.id]">
                      <div *ngIf="survey.currentStatus" class="building-status" 
                           [ngClass]="{'assigned-building': survey.currentStatus == 'Assigned', 
                                       'rejected-building': survey.currentStatus == 'Rejected', 
                                       'approved-building': survey.currentStatus == 'Approved',
                                       'unvalidated-building': survey.currentStatus == 'Unvalidated', 
                                       'submitted-building': survey.currentStatus == 'Submitted'|| survey.currentStatus=='Pending'
                                      }">
                        {{'COMMON.ASSET_STATUS.' + survey.currentStatus | uppercase | translate}}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" [useDirect]="true" (pageChange)="pageChange($event)"></app-paging>
              </div>
            </div>
          </div>
      </div>
    </div>
</div>
