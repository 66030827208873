import { History } from "./customer.model";
import { DtDto } from "./dt.model";
import { KeyValuePair } from "./keyValueItem.model";
import { InaccessibleMeter } from "./meter.model";

export interface Slrn {
    id: number;
    value: string;
}
export class Building {
    id: number;
    slrn: string;
    closestLandmark: string;
    buildingOwner: string;
    latitude: number;
    longitude: number;
    assignedUserId: number;
    status: string;
    lastUpdateTime: string;
    lastUpdateUser: string;
    assignedUser?: string;
    dtName?: string;
    poleId?: number;
    poleSlrn?: string;
    region?: string;
    customerCount?: number;
    buildingDevelopmentStage: string;
    buildingStructure: string;
    reassignedBy?: string;
    firstCapturedUser? : string;
    creationDate?: string;
    guid: string;

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.slrn = data?.slrn ?? '';
        this.closestLandmark = data?.closestLandmark ?? '';
        this.buildingOwner = data?.buildingOwner ?? '';
        this.latitude = data?.latitude ?? 0;
        this.longitude = data?.longitude ?? 0;
        this.assignedUserId = data?.assignedUserId ?? 0;
        this.status = data?.status ?? '';
        this.lastUpdateTime = data?.lastUpdateTime ?? '';
        this.assignedUser = data?.assignedUser ?? '';
        this.dtName = data?.dtName ?? '';
        this.region = data?.region ?? '';
        this.customerCount = data?.customerCount;
        this.buildingDevelopmentStage = data?.buildingDevelopmentStage ?? '';
        this.buildingStructure = data?.buildingStructure ?? '';
        this.reassignedBy = data?.reassignedBy ?? '';
        this.firstCapturedUser = data?.firstCapturedUser ?? '';
        this.creationDate = data?.creationDate ?? '';
        this.guid = data?.guid ?? '';
        this.lastUpdateUser = data?.lastUpdateUser ?? '';
        this.poleId = data?.poleId ?? 0;
        this.poleSlrn = data?.poleSlrn ?? '';
    }
}

export enum AssetStatus {
    Pending = 0,
    Complete = 1,
    Incomplete = 2,
    Assigned = 3,
    Rejected = 4,
    Retagged = 5,
}

export enum BuildingStructure {
    Movable = 0,
    Unmovable = 1
}

export enum BuildingDevelopmentStage {
    Completed = 0,
    NotCompleted = 1
}

export class BuildingDetails {
    id: number = 0;
    slrn: string;
    latitude: number = 0;
    longitude: number = 0;
    address: string = "";
    guid: string = "";
    status: string = "";
    dataQualityStatus: string = "";
    source: string = "";    
    dtName?: string="";
    feederName?: string="";
    area?: string="";
    region?: string="";
    issueType?: string="";
    customerCount?: number=0;
    isChecked: boolean;
}
export class EditBuilding {
    id: number = 0;
    slrn: string = "";
    latitude: number = 0;
    longitude: number = 0; 
    address: string = "";
    guid: string = "";
    closestLandmark: string = "";
    buildingOwner: string = "";
    numberOfMeters: number = 0;
    meterStatus: number = 0;
    connectedCustomers: BuildingCustomers[] = [];
    inaccessibleMeters:InaccessibleMeter[]=[];
    connectedDt: BuildingDt = new BuildingDt();
    assignedUserId: number = 0;
    assignedUser: string = "";
    images: BuildingImage[];
    comment: string = "";
    areaName: string = "";
    areaId: number = 0;
    poleId?: number;
    poleSlrn?: string;
    regionName: string = "";
    firstCapturedUser: string = "";
    lastUpdatedUser: string = "";
    creationDate: string = "";
    updatedDate: string = "";
    reassignedBy: string = "";
    validatedBy: string = "";
    validatedDate: string = "";
    auditedStatus: string = "";
    auditedBy: string = "";
    auditedDate: string = "";
    postCode?: string;
    postCodeException?: string;
    customerInaccessible?: CustomerInaccesible;
    buildingDevelopmentStage?: BuildingDevelopmentStage;;
    buildingStructure?: BuildingStructure;
    status :string = '';
    numberOfRevisits :number = 0;
    dataQualityStatus:string= "";
    dt: DtDto = new DtDto();
    feederId: number = 0;
    dtId: number = 0;
    buildingHistories:History[];
    dataValidationReview: KeyValuePair[] = [];

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.slrn = data?.slrn ?? '';
        this.latitude = data?.latitude ?? 0;
        this.longitude = data?.longitude ?? 0;
        this.address = data?.address ?? '';
        this.guid = data?.guid ?? '';
        this.closestLandmark = data?.closestLandmark ?? '';
        this.buildingOwner = data?.buildingOwner ?? '';
        this.numberOfMeters = data?.numberOfMeters ?? 0;
        this.meterStatus = data?.meterStatus ?? 0;
        this.connectedCustomers = data?.connectedCustomers?.length ? data.connectedCustomers.map((el: any) => new BuildingCustomers(el)) : [];
        this.inaccessibleMeters = data?.inaccessibleMeters?.length ? data.inaccessibleMeters.map((el: any) => new InaccessibleMeter(el)) : [];
        this.connectedDt = data?.connectedDt?.length ? data.connectedDt.map((el: any) => new BuildingDt(el)) : [];
        this.assignedUserId = data?.assignedUserId ?? 0;
        this.assignedUser = data?.assignedUser ?? '';
        this.images = data?.images?.length ? data.images.map((el: any) => new BuildingImage(el)) : [];
        this.areaName = data?.areaName ?? '';
        this.comment = data?.comment ?? '';
        this.areaId = data?.areaId ?? 0;
        this.regionName = data?.regionName ?? '';
        this.firstCapturedUser = data?.firstCapturedUser ?? '';
        this.lastUpdatedUser = data?.lastUpdatedUser ?? '';
        this.creationDate = data?.creationDate ?? '';
        this.updatedDate = data?.updatedDate ?? '';
        this.reassignedBy = data?.reassignedBy ?? '';
        this.validatedBy = data?.validatedBy ?? '';
        this.validatedDate = data?.validatedDate ?? '';
        this.auditedStatus = data?.auditedStatus ?? '';
        this.auditedBy = data?.auditedBy ?? '';
        this.auditedDate = data?.auditedDate ?? '';
        this.postCode = data?.postCode ?? '';
        this.postCodeException = data?.postCodeException ?? '';
        if(data?.customerInaccessible) {
            this.customerInaccessible = new CustomerInaccesible(data?.customerInaccessible);
        }
        this.buildingDevelopmentStage = data?.buildingDevelopmentStage;
        this.buildingStructure = data?.buildingStructure;
        this.status = data?.status ?? '';
        this.dataQualityStatus = data?.dataQualityStatus ?? '';
        this.numberOfRevisits = data?.numberOfRevisits ?? 0;
        this.dt = new DtDto(data?.dt);
        this.feederId = data?.feederId ?? 0;
        this.dtId = data?.dtId ?? 0;
        this.buildingHistories = data?.buildingHistories?.length ? data.buildingHistories.map((el: any) => new History(el)) : [];
        this.dataValidationReview = data?.dataValidationReview ?? [];
        this.poleId = data?.poleId ?? 0;
        this.poleSlrn = data?.poleSlrn ?? '';
    }
}

export class BuildingDt {
    id: number = 0;
    dtName: string = "";
    feederName: string = "";

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.dtName = data?.dtName ?? '';
        this.feederName = data?.feederName ?? '';
    }
}

export class BuildingCustomers {
    id: number = 0;
    name: string = "";
    tenantName : string = "";
    buildingOwner : string = "";
    accountNumber: string = "";
    meterNumber : string = "";
    useOfPremises: string = "";
    dataQualityId : number = 0;
    dataQualityStatus : string="";
    isOnboard:boolean=false;
    isAudited:boolean=false;

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.name = data?.name ?? '';
        this.tenantName = data?.tenantName ?? '';
        this.buildingOwner = data?.buildingOwner ?? '';
        this.accountNumber = data?.accountNumber ?? '';
        this.meterNumber = data?.meterNumber ?? '';
        this.useOfPremises = data?.useOfPremises ?? '';
        this.dataQualityId = data?.dataQualityId ?? 0;
        this.dataQualityStatus = data?.dataQualityStatus ?? '';
        this.isOnboard = data?.isOnboard ?? false;
        this.isAudited = data?.isAudited ?? false;
    }
}

export class BuildingImage {
    url: string;
    imageType: any;

    constructor(data?: any) {
        this.url = data?.url ?? '';
        this.imageType = data?.imageType;
    }
}

export class BuildingSave {
    id: number = 0;
    closestLandmark: string = "";
    buildingOwner: string = "";
    address: string = "";
    assignedUserId: number = 0;
    dtId: number = 0;
    buildingStructure?: BuildingStructure;
    buildingDevelopmentStage?: BuildingDevelopmentStage;
}

export class CustomerInaccesible {
    issueKind: string = "";
    description: string = "";
    issueType: string = "";

    constructor(data?: any) {
        this.issueKind = data?.issueKind ?? '';
        this.description = data?.description ?? '';
        this.issueType = data?.issueTypeInaccessible ?? '';
    }
}

export class BuildingStats {
    count: number;
    statType: BuildingStatsOptions;
}
export enum BuildingStatsOptions {
    Total,
    Tagged,
    Retagged,
    Inaccessible,
    TotalMeters,
    InaccessibleNumberOfRevisits
}