import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import {
  Customer,
  CustomerStats,
  SortByOptions,
  FilterByOptions,
  AssetStatus,
  ServiceType,
} from 'src/app/models/customer.model';
import { BillingType } from 'src/app/models/meter.model';
import { Region } from 'src/app/models/region.model';
import { Tariff } from 'src/app/models/tariff.model';
import { OptionObj } from 'src/app/models/util.model';
import { AreaService } from 'src/app/services/area.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomerService } from 'src/app/services/customer.service';
import { RegionService } from 'src/app/services/region.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'report-customers',
  templateUrl: './report-customers.component.html',
  styleUrls: ['./report-customers.component.scss'],
})
export class ReportCustomersComponent implements OnInit, OnDestroy {
  customers: Customer[] = [];
  stats: CustomerStats = {};
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');

  mapSortBy: Record<number, any> = {
    [SortByOptions.LAST_UPDATE]: { label: 'Newest', arrow: 'no', translation: 'COMMON.SORT_BY.NEWEST' },
    [SortByOptions.SLRN_ASC]: { label: 'SLRN', arrow: 'up', translation: 'COMMON.SLRN' },
    [SortByOptions.SLRN_DESC]: { label: 'SLRN', arrow: 'down', translation: 'COMMON.SLRN' },
    [SortByOptions.ACCNO_ASC]: { label: 'AccNo.', arrow: 'up', translation: 'COMMON.SORT_BY.ACCOUNT_NO' },
    [SortByOptions.ACCNO_DESC]: { label: 'AccNo.', arrow: 'down', translation: 'COMMON.SORT_BY.ACCOUNT_NO' },
  };
  dateFrom: string;
  dateTo: string;

  searchText: string = '';
  searchTextUpdate = new Subject<string>();

  sortByValue: SortByOptions = SortByOptions.LAST_UPDATE;
  sortByOptions = SortByOptions;
  sortByLabelValue: any = this.mapSortBy[SortByOptions.LAST_UPDATE];
  filterByOptions = FilterByOptions;
  filterByValue: FilterByOptions = FilterByOptions.TOTAL;
  assetStatusOptions = AssetStatus;
  public AssetStatus = AssetStatus;

  count: number = 0;
  pageSize: number = 5;
  currentPage: number = 1;

  showUpload: boolean = false;
  showSortBy: boolean = false;

  historyItems: any[] = [];
  isViewHistory: boolean = true;

  clickObservable: Observable<Event> = fromEvent(document, 'click');
  private ngUnsubscribe = new Subject<void>();

  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  tariff: number = 0;
  tariffs: Tariff[] = [];
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  serviceType?: number;
  serviceTypeOptions = [
    { name: 'Large', value: ServiceType.Large },
    { name: 'Small', value: ServiceType.Small },
    { name: 'Prepaid', value: ServiceType.Prepaid },
    { name: 'AMR', value: ServiceType.AMR },
  ];

  status?: number;
  statusOptions = [
    { name: 'Pending', value: AssetStatus.Pending },
    { name: 'Complete', value: AssetStatus.Complete },
    { name: 'Rejected', value: AssetStatus.Rejected },
  ];

  tag?: number;
  tagOptions = [
    { name: 'Existing', value: 0 },
    { name: 'New entry', value: 1 },
  ];

  sltStatus?: number;
  sltOptions: OptionObj[] = [];

  useOfPremises?: number;
  uopOptions = [
    { name: 'Prepaid', value: BillingType.Prepaid },
    { name: 'Postpaid', value: BillingType.Postpaid },
  ];

  clicked: boolean = false;

  selectedFilters: ChecklistItem[] = [];
  utilityId: number = 0;
  sltStatusName: string =
    this.translationService.getByKeyFromStorage('SltStatus');
  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private router: Router,
    private regionService: RegionService,
    private areaService: AreaService,
    private tariffService: TariffService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
    this.subscribeToClickEvent();
    this.getRegions();
    this.getAreas();
    this.getTariffs();
    this.setFilters();
    this.getData();
    this.getSltStatuses();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.searchText == '') this.search();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.searchTextUpdate.complete();
  }

  private getData(): void {
    this.reloadTable();
  }

  selectCustomer(id: number) {
    this.router.navigate([`/customers/report-view/${id}`]);
    this.setFilterStorage();
  }

  setFilters() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = 5;
    this.count = filterObject.filter.pageInfo.count;
    this.sortByValue = filterObject.filter.filterParams.sortBy;
    this.searchText = filterObject.filter.filterParams.search;
    this.filterByValue = filterObject.filter.filterParams.filterBy;
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.tariff = filterObject.filter.filterParams.tariffId
      ? filterObject.filter.filterParams.tariffId
      : 0;
    this.serviceType = filterObject.filter.filterParams.serviceType
      ? filterObject.filter.filterParams.serviceType
      : undefined;
    this.status = filterObject.filter.filterParams.status
      ? filterObject.filter.filterParams.status
      : undefined;
    this.tag =
      filterObject.filter.filterParams.tag != null
        ? filterObject.filter.filterParams.tag
          ? 1
          : 0
        : undefined;
    this.sltStatus = filterObject.filter.filterParams.sltStatus
      ? filterObject.filter.filterParams.sltStatus
      : undefined;
    this.useOfPremises = filterObject.filter.filterParams.useOfPremises
      ? filterObject.filter.filterParams.useOfPremises
      : undefined;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
    this.sortByLabelValue = this.mapSortBy[this.sortByValue];
    localStorage.removeItem('filterCustomer');
  }
  setCheckedAreas() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  setCheckedRegions() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  reloadTable(page: any = null) {
    if (page) this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };
    this.getAll(obj);
  }

  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };

    // Remove 'T23:59:59' from the dateTo to avoid errors
    if (obj.filterParams.dateTo) {
      obj.filterParams.dateTo = obj.filterParams.dateTo.replace(
        'T23:59:59',
        ''
      );
    }

    localStorage.setItem('cameFrom', 'Customer');
    localStorage.setItem(
      'filterCustomer',
      JSON.stringify({ type: 'Customer', filter: obj })
    );
  }
  private getAll(obj: any) {
    this.customerService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.customers = responseData.data.data;
          this.count = responseData.data.count ?? this.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else if (response?.status == 204) {
          this.toastr.warning('No content');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  checkAllCheckBox(event: any) {}

  isAllCheckBoxChecked() {
    return false;
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByLabelValue = this.mapSortBy[option];
    this.reloadTable();
  }

  search() {
    this.reloadTable(1);
  }

  onFilterBy(option: FilterByOptions) {
    this.filterByValue = option;
    this.reloadTable(1);
  }

  getFilterObject() {
    let obj = {
      misaligned: true,
      sortBy: this.sortByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      tariffId: this.tariff != 0 ? this.tariff : null,
      serviceType: this.serviceType ? this.serviceType : null,
      status: this.status ? this.status : null,
      tag: this.tag ? (this.tag == 0 ? false : true) : null,
      sltStatus: this.sltStatus ? this.sltStatus : null,
      useOfPremises: this.useOfPremises ? this.useOfPremises : null,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
    };
    return obj;
  }

  viewHistory(id: number) {
    this.customerService.getHistory(id).subscribe({
      next: (response) => {
        this.historyItems = response?.body?.data ?? [];
      },
    });
  }

  download() {
    this.customerService.download(this.getFilterObject()).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showSortBy = false;
      });
  }

  datesValid() {
    if (this.dateFrom && this.dateTo) {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }
    return true;
  }

  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.createFilterCards();
          this.removeFilterStorage();
          // this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getTariffs() {
    this.tariffService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.tariffs = responseData.data;
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectTariff(e: any) {
    this.tariff = +e.target.value;
    this.createFilterCards();
    this.reloadTable(1);
  }

  selectServiceType(e: any) {
    if (e.target.value != '') {
      this.serviceType = e.target.value;
    } else {
      this.serviceType = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  selectStatus(e: any) {
    if (e.target.value != '') {
      this.status = e.target.value;
    } else {
      this.status = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  selectTag(e: any) {
    if (e.target.value != '') {
      this.tag = e.target.value;
    } else {
      this.tag = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  selectSltStatus(e: any) {
    if (e.target.value != '') {
      this.sltStatus = parseInt(e.target.value);
    } else {
      this.sltStatus = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  selectUseOfPremises(e: any) {
    if (e.target.value != '') {
      this.useOfPremises = e.target.value;
    } else {
      this.useOfPremises = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });

    this.createFilterCards();
  }

  selectArea(e: any) {
    this.createFilterCards();
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Tariff') {
      this.tariff = 0;
    } else if (item.property == 'Service type') {
      this.serviceType = undefined;
    } else if (item.property == 'Status') {
      this.status = undefined;
    } else if (item.property == 'Tag') {
      this.tag = undefined;
    } else if (item.property == 'Slt status') {
      this.sltStatus = undefined;
    } else if (item.property == 'Use of premises') {
      this.useOfPremises = undefined;
    }

    this.createFilterCards();
    this.reloadTable(1);
  }

  resetFilter(e: any) {
    this.selectedFilters = [];

    this.regions.forEach((region) => {
      region.checked = false;
    });

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];

    this.tariff = 0;
    this.serviceType = undefined;
    this.status = undefined;
    this.tag = undefined;
    this.sltStatus = undefined;
    this.useOfPremises = undefined;

    this.clicked = !this.clicked;

    this.reloadTable(1);
  }

  createFilterCards() {
    this.selectedFilters = [];

    let selectedAreas = this.areas.filter((x) => x.checked);
    let selectedRegions = this.regions.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);
    //regions
    for (var i = 0; i < selectedRegions.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.buName, selectedRegions[i].name)
      );
    }

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }

    //tariff
    if (this.tariff != 0) {
      let tariffName = this.tariffs.filter((x) => x.id == this.tariff)[0].name;
      this.selectedFilters.push(new ChecklistItem('Tariff', tariffName));
    }

    //service type
    if (this.serviceType) {
      var serviceTypeName = ServiceType[this.serviceType!];
      this.selectedFilters.push(
        new ChecklistItem('Service type', serviceTypeName)
      );
    }

    //status
    if (this.status) {
      var statusName = AssetStatus[this.status!];
      this.selectedFilters.push(new ChecklistItem('Status', statusName));
    }

    //tag
    if (this.tag) {
      var tagName = this.tagOptions[this.tag].name;
      this.selectedFilters.push(new ChecklistItem('Tag', tagName));
    }

    //slt
    if (this.sltStatus) {
      var sltStatusOptionName = this.sltOptions.find(
        (x) => x.key == this.sltStatus
      )!.value;
      this.selectedFilters.push(
        new ChecklistItem(this.sltStatusName, sltStatusOptionName)
      );
    }

    //use of premises
    if (this.useOfPremises) {
      var uopName = BillingType[this.useOfPremises];
      this.selectedFilters.push(new ChecklistItem('Use of premises', uopName));
    }
  }
  removeFilterStorage() {
    if (
      this.areas.length > 0 &&
      this.regions.length > 0 &&
      this.tariffs.length > 0
    )
      localStorage.removeItem('filterCustomer');
  }

  getSltStatuses() {
    this.customerService.getSltStatusesForOptions().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.sltOptions = responseData.data;
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
}
