<div class="modal" id="auditReview" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="resetChoices()">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'COMMON.FIELD_AUDIT' | translate}}</h5>
                <button type="button" #closeAudit class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
                    {{'COMMON.CANCEL' | translate}}
                </button>
            </div>
            <div class="modal-footer updateStatus">
                <ng-container *ngIf="!phoneValidationSelected">
                    <div>
                        <h3>{{'AUDIT_REVIEW_CUSTOMER.QUESTION' | translate}}?</h3>
                    </div>

                    <button type="button" class="btn btn-primary approve"
                        (click)="handlePhoneVerificationSelection($event, true)">
                        {{'COMMON.YES' | translate}}
                    </button>
                    <button type="button" (click)="handlePhoneVerificationSelection($event, false)"
                        class="btn btn-danger">
                        {{'COMMON.NO' | translate}}
                    </button>
                </ng-container>
                <ng-container *ngIf="phoneValidationAgreed">
                    <div class="conditional-select-section">
                        <label for="verificationSuccess">{{'AUDIT_REVIEW_CUSTOMER.VALIDATION' | translate}}?</label>
                        <select id="verificationSuccess" class="form-control conditional-select"
                            (change)="selectVerificationSuccess($event)">
                            <option [value]="null" selected disabled>{{'AUDIT_REVIEW_CUSTOMER.SELECT_AN_OPTION' |
                                translate}}</option>
                            <option value="1">{{'COMMON.YES' | translate}}</option>
                            <option value="0">{{'COMMON.NO' | translate}}</option>
                        </select>
                    </div>
                    <div class="conditional-select-section"
                        *ngIf="phoneValidationSuccessful != undefined && !phoneValidationSuccessful">
                        <label for="phoneNoIssue">{{'AUDIT_REVIEW_CUSTOMER.REASON' | translate}}:</label>
                        <select required id="phoneNoIssue" class="form-control conditional-select"
                            (change)="selectVerificationIssue($event)">
                            <option [value]="null" selected disabled>{{'AUDIT_REVIEW_CUSTOMER.SELECT_AN_OPTION' |
                                translate}}</option>
                            <option *ngFor="let item of phoneValidationErrors" [value]="item.value">{{item.name |
                                translate}}</option>
                        </select>
                    </div>
                </ng-container>
                <ng-container *ngIf="phoneValidationSelected && !auditRejected">
                    <div>
                        <h3>{{'AUDIT_REVIEW_CUSTOMER.AUDIT' | translate}}</h3>
                    </div>

                    <button type="button" class="btn btn-primary approve" (click)="submitAudit()">
                        {{'COMMON.YES' | translate}}
                    </button>
                    <button type="button" (click)="rejectAudit($event)" class="btn btn-danger">
                        {{'COMMON.NO' | translate}}
                    </button>
                </ng-container>
                <ng-container *ngIf="auditRejected">
                    <div>
                        <h3>{{'AUDIT_REVIEW_CUSTOMER.FAILURE_REASON' | translate}}</h3>
                    </div>

                    <div class="checkbox-section">
                        <div class="checkbox" *ngFor="let reason of auditFailedReasons">
                            <input type="checkbox" [(ngModel)]="reason.checked" (change)="toggleReason(reason.value)">
                            <label>{{ reason.name | translate }}</label>
                        </div>
                        <div *ngIf="otherSelected">
                            <input class="form-control other-reason" type="text" placeholder="{{'AUDIT_REVIEW_CUSTOMER.STATE_REASON' | translate}}" [(ngModel)]="otherReason" />
                        </div>
                    </div>
                    <button type="button" class="btn btn-primary approve" (click)="submitAudit()">
                        {{'COMMON.SUBMIT' | translate}}
                    </button>
                </ng-container>

            </div>
        </div>
    </div>
</div>