<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">
          {{
          customerHeaderInfo.tenantName
          ? customerHeaderInfo.tenantName
          : ('COMMON.NO_NAME' | translate)
          }}
        </div>
        <div class="slrn">
          {{ customerHeaderInfo.slrn ? customerHeaderInfo.slrn : ('COMMON.NO_SLRN' | translate) }}
        </div>
        <div class="row info-row">
          <div class="green-status v-2" *ngIf="customer.buildingType">{{ 'COMMON.BUILDING_TYPE.' + customer.buildingType | uppercase | translate }}</div>
          <div class="green-status v-2" *ngIf="
              disableUpdateStatusButton() &&
              customer.dataQualityStatus != null &&
              customer.dataQualityStatus!.toString() == 'Approved' &&
              customer.assetStatus !== 'Assigned'
            ">
            {{'COMMON.ASSET_STATUS.COMPLETE' | translate}}
          </div>
          <div class="dark-red-status v-2" *ngIf="
              disableUpdateStatusButton() &&
              customer.dataQualityStatus != null &&
              customer.dataQualityStatus!.toString() == 'Rejected' &&
              customer.assetStatus !== 'Assigned'
            ">
            {{'COMMON.ASSET_STATUS.REJECTED' | translate}}
          </div>
          <div class="gray-status v-2" *ngIf="
              !disableUpdateStatusButton() &&
              customer.assetStatus !== 'Assigned'
            ">
            {{'COMMON.ASSET_STATUS.PENDING' | translate}}
          </div>
          <div class="orange-status v-2" *ngIf="customer.assetStatus === 'Assigned'">
            {{'COMMON.ASSET_STATUS.ASSIGNED' | translate}}
          </div>
          <div *ngIf="customer.id" [ngClass]="{
                'dark-green-status': !customer.isNew,
                'green-status': customer.isNew }" class="v-2">
            {{ customer.isNew ? ('COMMON.NEW_ENTRY' | translate) : ('COMMON.EXISTING' | translate) }}
          </div>
        </div>
      </div>
      <div class="user-address">
        <div>{{ customerHeaderInfo?.email | hasValue }}</div>
        <div>{{ customerHeaderInfo?.phoneNumber | hasValue }}</div>
        <div>{{ customerHeaderInfo?.physicalAddress | hasValue }}</div>
      </div>
    </div>
    <div class="action-buttons" *ngIf="!onlyView">
      <button class="btn btn-header" (click)="regularize()"
        *ngIf="customer.accountNumber == ''&& customer.isNew && isRegularizationUploadVisible && permissionsService.isCustomerRegularizationAllowed">
        {{'CUSTOMER_DETAILS.REGULARIZE' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal"
        (click)="getMobileUsers(); setModalType(AssetAssignType.Retagged);" data-bs-target="#assignToFieldAgent"
        *ngIf="permissionsService.isCustomerRetagAllowed">
        {{'COMMON.RETAG' | translate}}
      </button>
      <div class="recall-div" *ngIf="
          permissionsService.isCustomerRecallAssignedAllowed ||
          permissionsService.isCustomerRecallValidatedAllowed
        ">
        <button class="btn btn-header recall-button" style="color: black" (click)="showRecallMenu = !showRecallMenu">
          <span class="recall-span">{{'COMMON.RECALL' | translate}}</span>
        </button>
        <div *ngIf="showRecallMenu" class="recall-options">
          <div class="recall-type" [ngClass]="[
              !recallAssignedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="
              recallAssignedAllowed
                ? recallAssigned()
                : null
            ">
            <span>{{'COMMON.ASSIGNED_TASK' | translate}}</span>
          </div>
          <div class="recall-type" [ngClass]="[
              !recallValidatedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="
              recallValidatedAllowed
                ? recallValidation()
                : null
            ">
            <span>{{'COMMON.VALIDATED_TASK' | translate}}</span>
          </div>
        </div>
      </div>

      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#updateStatus"
        [disabled]="disableUpdateStatusButton()" (click)="updateStatusClicked = true"
        *ngIf="permissionsService.isCustomerApproveRejectAllowed">
        {{'COMMON.UPDATE_STATUS' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#auditReview"
        [disabled]="disableAuditReviewButton()" *ngIf="permissionsService.isCustomerAuditReviewAllowed">
        {{'COMMON.FIELD_AUDIT' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal"
        (click)="getMobileUsers(); setModalType(AssetAssignType.Assigned);" data-bs-target="#assignToFieldAgent"
        *ngIf="permissionsService.isCustomerAssignAllowed">
        {{'COMMON.ASSIGN_AGENT' | translate}}
      </button>
      <button class="btn btn-header" *ngIf="!editEnabled && permissionsService.isCustomerCreateEditAllowed"
        (click)="edit()">
        {{'COMMON.EDIT' | translate}}
      </button>
      <button class="btn btn-primary" *ngIf="editEnabled" (click)="openEditModal()">
        {{'COMMON.SAVE' | translate}}
      </button>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="main-col">
      <div class="card container">
        <div class="row">
          <div class="col-5">{{'COMMON.SLRN' | translate}}</div>
          <div class="col-7">{{ customer.building.slrn | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.BU.SINGLE' | translate}}</div>
          <div class="col-7">{{ customer.area?.regionName | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.UT.SINGLE' | translate}}</div>
          <div class="col-7">{{ customer.area?.name | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.CUSTOMER_ADDRESS' | translate}}</div>
          <div class="col-7">
            {{ customer.address | hasValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.PHYSICAL_ADDRESS' | translate}}</div>
          <div class="col-7">
            {{ customer.physicalAddress | hasValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.CLOSEST_LANDMARK' | translate}}</div>
          <div class="col-7">
            {{ customer.building?.closestLandmark | hasValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.ACCOUNT_NO' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.accountNumber | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.accountNumber" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.BUILDING_OWNER' | translate}}</div>

          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.buildingOwner | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.buildingOwner" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.CUSTOMER_NAME' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.tenantName | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.tenantName" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.BUILDING_STRUCTURE.NAME' | translate}}</div>
          <div class="col-7">
            {{ customer.building.buildingStructure | hasValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.BUILDING_DEVELOPMENT.NAME' | translate}}</div>
          <div class="col-7">
            {{ customer.building.buildingDevelopmentStage | hasValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{ 'COMMON.MBC_DETAILS.BUILDING_DEVELOPMENT.NAME' | translate }}</div>
          <div class="col-7">
            {{ customer.building.buildingDevelopmentStage | hasValue }}
          </div>
        </div>
        <div class="row conn-assets">
          <div class="col-5">{{ 'COMMON.POLE.CONNECTED_LT_POLE' | translate }}</div>
          <div class="col-7 d-flex align-items-center justify-content-end">
            {{ customer.building.poleSlrn | hasValue }}
            <img *ngIf="customer.building?.poleId" class="ml-3" (click)="viewConnectedAssets(1)" title="View" src="assets/icons/angles-right.svg">
          </div>
        </div>      
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.FEEDER_NAME' | translate}}</div>

          <div class="col-8">
            {{ customer.dt?.feeder?.name | hasValue }}
          </div>
        </div>

        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.FEEDER_NUMBER' | translate}}</div>
          <div class="col-7">{{ customer.dt?.feeder?.number | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.DT_NAME' | translate}}</div>
          <div class="col-8">
            {{ customer.dt?.name | hasValue }}
          </div>

        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.DT_NUMBER' | translate}}</div>
          <div class="col-7">{{ customer.dt?.number | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.NUMBER_IN_HOUSE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.numberOfHouseholds | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" type="number" [(ngModel)]="customer.numberOfHouseholds"
            pattern="[0-9]+" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.TYPE_OF_CONNECTION' | translate}}</div>
          <div class="col-7">{{ customer?.typeOfConnection | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.USE_OF_PREMISES' | translate}}</div>
          <div class="col-7">{{ mapUseOfPremise(customer?.buildingType) | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.PREMISE_TYPE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ customer?.permiseType | hasValue }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="customer.permiseType" (change)="selectPermiseType()">
              <option *ngFor="let option of permiseOptions" [ngValue]="option">
                {{ option }}
              </option>
            </select>
          </div>

        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.PREMISE_CATEGORY' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ customer?.permiseCategory | hasValue }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="customer.permiseCategory">
              <option *ngFor="let option of permiseCategoryOptions" [ngValue]="option">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.ACTIVITY' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ customer?.activity | hasValue }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="customer.activity" (change)="selectActivity()">
              <option *ngFor="let option of activityOptions" [ngValue]="option">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.SUB_ACTIVITY' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ customer?.subActivity | hasValue }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="customer.subActivity">
              <option *ngFor="let option of subactivityOptions" [ngValue]="option">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.NEIGHBORHOOD' | translate}}</div>
          <div class="col-7" *ngIf="!editEnabled">{{ customer?.neighborhood | hasValue }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="customer.neighborhood">
              <option *ngFor="let option of neighborhoodOptions" [ngValue]="neighborhood[option.key]">
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="customer.buildingType.toString() == 'Residential' ">
          <div class="col-5">{{'COMMON.MBC_DETAILS.NUMBER_OF_ROOMS' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ customer?.numberOfRooms | hasValue }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="customer.numberOfRooms">
              <option *ngFor="let option of numberOfRoomsOptions" [ngValue]="option">
                {{ option }}
              </option>
            </select>
          </div>

        </div>
      </div>
      <app-history-tracker [histories]="customer.customerHistories"></app-history-tracker>

      <div *ngIf="isDataQuality && customer.dataValidationReview != null && customer.dataValidationReview.length > 0"
        class="history-card container">
        <h4 style="text-align: center">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h4>
        <div class="row" *ngFor="let dq of customer.dataValidationReview">
          <div class="col-5">{{ dq.key }}</div>
          <div *ngIf="dq.key != 'Reason for rejection'" class="col-7">
            {{ dq.value| hasValue }}
          </div>
          <div *ngIf="dq.key == 'Reason for rejection'" class="col-7">
            <a data-bs-toggle="modal" data-bs-target="#rejectionReason">view</a>
          </div>
        </div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-5">{{ 'COMMON.MBC_DETAILS.METER_TYPE.SINGLE' | translate }}</div>

        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.meter?.meterType?.name | hasValue }}
        </div>
        <div *ngIf="editEnabled" class="col-7">
          <select class="select-dropdown form-control" [(ngModel)]="customer.meterTypeId">
            <option *ngFor="let option of meterTypeOptions" [ngValue]="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{ 'COMMON.MBC_DETAILS.METER_NO' | translate }}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.meter?.number | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.number" />
      </div>
      <div class="row">
        <div class="col-5">{{ 'COMMON.MBC_DETAILS.OLD_METER_NO' | translate }}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.oldMeterNumber | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.number" />
      </div>
      <div class="row" *ngIf="isSPNFieldVisible">
        <div class="col-5">{{ 'COMMON.CONTRACT_NUMBER' | translate }}</div>
        <div class="col-7">{{ customer?.contractNumber | hasValue }}</div>
      </div>

      <div class="row" *ngIf="isServiceTypeFieldVisible">
        <div class="col-5">{{'COMMON.SERVICE_TYPE.TITLE' | translate}}</div>
        <div class="col-7">{{ customer?.serviceType | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{ 'COMMON.MBC_DETAILS.CUSTOMER_TYPE' | translate }}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.customerType | hasValue }}
        </div>
        <div *ngIf="editEnabled" class="col-7">
          <select class="select-dropdown form-control" [(ngModel)]="customer.customerType">
            <option *ngFor="let option of customerTypeOptions" [ngValue]="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-5">{{ 'COMMON.SLT_STATUS.SINGLE' | hasValue | translate }}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.sltStatus?.value?.toString() | hasValue }}
        </div>
        <div *ngIf="editEnabled" class="col-7">
          <select class="select-dropdown form-control" [(ngModel)]="selectedSltStatus.key">
            <option *ngFor="let option of sltOptions" [ngValue]="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.TARIFF_CLASS' | translate}}</div>
        <div class="col-7">{{ customer?.tariffClass | hasValue }}</div>
      </div>
      <div class="row" *ngIf="isActualTariffFieldVisible">
        <div class="col-5">{{'COMMON.MBC_DETAILS.REC_ACT_TARIFF_CLASS' | translate}}</div>
        <div class="col-7">{{ customer?.actualTariffClass | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.CLIENT_TYPE' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">{{ customer?.clientType | hasValue }}</div>
        <div *ngIf="editEnabled" class="col-7">
          <select class="select-dropdown form-control" [(ngModel)]="customer.clientType">
            <option *ngFor="let option of clientTypeOptions" [ngValue]="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.METER_STATUS' | translate}}</div>
        <div *ngIf="customer?.meterStatus" class="col-7">{{ customer?.meterStatus | hasValue }}</div>
        <div *ngIf="!customer?.meterStatus" class="col-7">Unmetered</div>

      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.METER_CONDITION' | translate}}</div>
        <div class="col-7">{{ customer?.meterCondition | hasValue }}</div>
      </div>
      <div class="row" *ngIf="isMeterSlrnFieldVisible">
        <div class="col-5">{{'COMMON.MBC_DETAILS.METER_SLRN' | translate}}</div>
        <div class="col-7">{{ customer?.meterSlrn | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.ID_CARD_NUM' | translate}}</div>
        <div class="col-7" *ngIf="!editEnabled">{{ customer?.cardNumber | hasValue }}</div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.cardNumber" />
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.PHONE_NO' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer.phoneNumber | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.phoneNumber" pattern="[0-9]+" />
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.EMAIL' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.email | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.email" type="email" />
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.BUILDING_CREATION_DATE' | translate}}</div>
        <div class="col-7">
          {{ customer.building.creationDate | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.DATE_TYPE.TAGGED' | translate}}</div>
        <div class="col-7">
          {{ customer.taggedDate | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.USER.TYPE.FIRST_CAPTURED' | translate}}</div>
        <div class="col-7">
          {{ customer.firstCaptureUser | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.LAST_UPDATED' | translate}}</div>
        <div class="col-7">
          {{ customer.lastUpdateUser | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.UPDATED_DATE' | translate}}</div>
        <div class="col-7">
          {{customer.lastUpdateTime| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.USER.TYPE.REASSIGNED' | translate}}</div>
        <div class="col-7">{{ customer.building.reassignedBy | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.ASSIGNED' | translate}}</div>
        <div class="col-7">{{ customer.building.assignedUser | hasValue }}</div>
      </div>
      <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
        <div class="col-5">{{'COMMON.USER.TYPE.VALIDATED' | translate}}</div>
        <div class="col-7">{{ customer.validatedBy | hasValue }}</div>
      </div>
      <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
        <div class="col-5">{{'COMMON.DATE_TYPE.VALIDATED' | translate}}</div>
        <div class="col-7">
          {{customer.validatedDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
        </div>
      </div>
      <div class="row" *ngIf="permissionsService.isAuditStatusAllowed">
        <div class="col-5">{{'COMMON.MBC_DETAILS.AUDITED_STATUS' | translate}}</div>
        <div class="col-7">{{ customer.auditedStatus | hasValue }}</div>
      </div>
      <div class="row" *ngIf="permissionsService.isAuditStatusAllowed">
        <div class="col-5">{{'COMMON.USER.TYPE.AUDITED' | translate}}</div>
        <div class="col-7">{{ customer.auditedBy | hasValue }}</div>
      </div>
      <div class="row" *ngIf="permissionsService.isAuditStatusAllowed">
        <div class="col-5">{{'COMMON.MBC_DETAILS.AUDITED_DATE' | translate}}</div>
        <div class="col-7">
          {{customer.auditedDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
        </div>
      </div>
      <div *ngIf="permissionsService.isValidationStatusAllowed" class="row">
        <div class="col-5">{{'COMMON.VALIDATION_STATUS' | translate}}</div>
        <div class="col-7">{{ customer.building?.status | hasValue }}</div>
      </div>
      <div class="row" *ngIf="customer.customerType?.toString() == 'Prepaid'">
        <div class="col-5">{{'COMMON.MBC_DETAILS.LAST_VEND_AMOUNT' | translate}}</div>
        <div class="col-7">{{ customer?.lastVendAmount | hasValue }}</div>
      </div>
      <div class="row" *ngIf="customer.customerType?.toString() == 'Prepaid'">
        <div class="col-5">{{'COMMON.MBC_DETAILS.LAST_VEND_DATE' | translate}}</div>
        <div class="col-7">{{ customer?.lastVendDate | hasValue }}</div>
      </div>
      <div class="row" *ngIf="customer.customerType?.toString() == 'Postpaid'">
        <div class="col-5">{{'COMMON.MBC_DETAILS.LAST_PAYMENT_AMOUNT' | translate}}</div>
        <div class="col-7">{{ customer?.lastPaymentAmount | hasValue }}</div>
      </div>
      <div class="row" *ngIf="customer.customerType?.toString() == 'Postpaid'">
        <div class="col-5">{{'COMMON.MBC_DETAILS.LAST_PAYMENT_DATE' | translate}}</div>
        <div class="col-7">{{ customer?.lastPaymentDate | hasValue }}</div>
      </div>
      <div class="row" *ngIf="customer.customerType?.toString() == 'Postpaid'">
        <div class="col-5">{{'COMMON.MBC_DETAILS.CURRENT_DEBT' | translate}}</div>
        <div class="col-7">{{ customer?.currentDebtBalance | hasValue }}</div>
      </div>
      <div class="row" *ngIf="isGhanaPostFieldsVisible">
        <div class="col-5">{{'COMMON.GHANA_POST_CODE' | translate}}</div>
        <div class="col-7">{{ customer.ghanaPostGps | hasValue }}</div>
      </div>
      <div class="row" *ngIf="customer.postCodeException && isGhanaPostFieldsVisible">
        <div class="col-5">{{ 'COMMON.GHANA_POST_CODE_EXC' | translate }}</div>
        <div class="col-7">{{ customer.postCodeException | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.GEO_LOCATION' | translate}}</div>
        <div class="locationText col-6">
          {{ customer.building.latitude }}, {{ customer.building.longitude }}
        </div>
        <div class="location col-1" (click)="isMapVisible = !isMapVisible">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" data-bs-toggle="modal"
            data-bs-target="#showMap">
            <path
              d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
              fill="#026962" />
          </svg>
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.CUSTOMER_INSPECTION' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.customerInspectionConclusion | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.customerInspectionConclusion" />
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.REMARKS' | translate}}</div>
        <div class="col-7">
          {{ customer.remarksAndRecommendations | hasValue }}
        </div>
      </div>
    </div>
    <div class="image-container main-col">
      <div class="card container">
        <div>{{'COMMON.PICTURES.FRONT' | translate}}</div>
        <div class="images-list">
          <div *ngIf="getFrontViewImage(0)">
            <img src="{{ getFrontViewImage(0) }}" (click)="showImage(0)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front image">
          </div>
          <div *ngIf="!getFrontViewImage(0)">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileFw1" type="file"
              (change)="fileBrowserHandler($event, ImageType.Frontview)" />
            <label for="fileFw1" [ngClass]="{
                'disabled-label':
                  !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
          <div *ngIf="getFrontViewImage(1)">
            <img src="{{ getFrontViewImage(1) }}" (click)="showImage(1)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front image" />
          </div>
          <div *ngIf="!getFrontViewImage(1)">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileFw2" type="file"
              (change)="fileBrowserHandler($event, ImageType.Frontview)" />
            <label for="fileFw2" [ngClass]="{
                'disabled-label':
                  !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
          <div *ngIf="getFrontViewImage(2)">
            <img src="{{ getFrontViewImage(2) }}" (click)="showImage(2)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front image" />
          </div>
          <div *ngIf="!getFrontViewImage(2)">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileFw3" type="file"
              (change)="fileBrowserHandler($event, ImageType.Frontview)" />
            <label for="fileFw3" [ngClass]="{
                'disabled-label':
                  !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>{{'COMMON.PICTURES.BUILDING_TAG' | translate}}</div>
        <div>
          <div *ngIf="getBuildingMountedImage()">
            <img src="{{ getBuildingMountedImage() }}" (click)="showImage(3)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Building image" />
          </div>
          <div *ngIf="!getBuildingMountedImage()">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileMounted" type="file"
              (change)="fileBrowserHandler($event, ImageType.MountedTag)" />
            <label for="fileMounted" [ngClass]="{
                'disabled-label':
                  !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
        </div>
      </div>
      <div class="card container">
        <div class="title-list">
          <div>{{'COMMON.PICTURES.BILL' | translate}}</div>
          <div>{{'COMMON.PICTURES.ID_CARD' | translate}}</div>
          <div *ngIf="isImageMeterFieldVisible">
            {{'COMMON.PICTURES.CUSTOMER_TAG' | translate}}
          </div>
          <div *ngIf="!isImageMeterFieldVisible">{{'COMMON.PICTURES.METER_NUMBER' | translate}}</div>
        </div>
        <div class="images-list">
          <div *ngIf="customer.billImageUrl">
            <img src="{{ customer.billImageUrl }}" (click)="showImage(4)" data-bs-toggle="modal"
              data-bs-target="#modalImage" />
          </div>
          <div *ngIf="!customer.billImageUrl">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileBill" type="file"
              (change)="fileBrowserHandler($event, ImageType.Bill)" />
            <label for="fileBill" [ngClass]="{
                'disabled-label':
                  !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>

          <div *ngIf="customer.cardImageUrl">
            <img src="{{ customer.cardImageUrl }}" (click)="showImage(5)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Card image" />
          </div>
          <div *ngIf="!customer.cardImageUrl">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="filecard" type="file"
              (change)="fileBrowserHandler($event, ImageType.Card)" />
            <label for="filecard" [ngClass]="{
                'disabled-label':
                  !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>

          <div *ngIf="customer.meterImageUrl && isImageMeterFieldVisible">
            <img src="{{ customer.meterImageUrl }}" (click)="showImage(6)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Meter image" />
          </div>
          <div *ngIf="!customer.meterImageUrl && isImageMeterFieldVisible">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileCustomerTag" type="file"
              (change)="fileBrowserHandler($event, ImageType.Meter)" />
            <label for="fileCustomerTag" [ngClass]="{
                'disabled-label':
                  !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
          <div *ngIf="
          customer.meterNumberImageUrl && !isImageMeterFieldVisible
        ">
            <img src="{{ customer.meterNumberImageUrl }}" (click)="showImage(7)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Meter number image" />
          </div>
          <div *ngIf="
          !customer.meterNumberImageUrl && !isImageMeterFieldVisible
        ">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileMeter" type="file"
              (change)="fileBrowserHandler($event, ImageType.MeterNumber)" />
            <label for="fileMeter" [ngClass]="{
            'disabled-label':
              !permissionsService.isCustomerCreateEditAllowed
          }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
        </div>
        <div class="title-list">
          <div class="title-list-margin" *ngIf="isImageMeterFieldVisible">
            {{'COMMON.PICTURES.METER_NUMBER' | translate}}
          </div>
          <div style="margin-right: 17rem" *ngIf="isBackViewPictureFieldVisible">
            {{'COMMON.PICTURES.CARD_BACK' | translate}}
          </div>
        </div>
        <div class="images-list">
          <div *ngIf="isImageMeterFieldVisible">
            <div *ngIf="customer.meterNumberImageUrl">
              <img src="{{ customer.meterNumberImageUrl }}" (click)="showImage(7)" data-bs-toggle="modal"
                data-bs-target="#modalImage" alt="Meter number image" />
            </div>
            <div *ngIf="!customer.meterNumberImageUrl">
              <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileMeter" type="file"
                (change)="fileBrowserHandler($event, ImageType.MeterNumber)" />
              <label for="fileMeter" [ngClass]="{
                  'disabled-label':
                    !permissionsService.isCustomerCreateEditAllowed
                }">
                <img src="../../../../assets/missing_image.png" alt="Missing image" />
              </label>
            </div>
          </div>

          <div *ngIf="customer.backCardViewImageUrl && isBackViewPictureFieldVisible">
            <img src="{{ customer.backCardViewImageUrl }}" (click)="showImage(8)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Back card image" />
          </div>
          <div *ngIf="!customer.backCardViewImageUrl && isBackViewPictureFieldVisible">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileCardBackView" type="file"
              (change)="fileBrowserHandler($event, ImageType.CardBackViewImage)" />
            <label for="fileCardBackView" [ngClass]="{
                'disabled-label':
                  !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="assignToFieldAgent" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onModalAssignFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="assetAssignType == AssetAssignType.Assigned" class="modal-title">{{'COMMON.ASSIGN_AGENT' | translate}}</h5>
        <h5 *ngIf="assetAssignType == AssetAssignType.Retagged" class="modal-title">{{'COMMON.RETAG_TASK' | translate}}</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="openDropdown()" placeholder=" " />
          <label for="floatingInput">{{'COMMON.FIELD_AGENT' | translate}}</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">
              {{ u.fullName }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          <span *ngIf="assetAssignType == AssetAssignType.Assigned">{{'COMMON.ASSIGN_TASK' | translate}}</span>
          <span *ngIf="assetAssignType == AssetAssignType.Retagged">{{'COMMON.RETAG_TASK' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()"
  (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage"
            [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">
            &lt;
          </button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">
            &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal modalMap" id="showMap" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #closeAddModal class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map [setMapVisibility]="isMapVisible" [longitude]="longitude"
          [latitude]="latitude"></app-building-map>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="updateStatus" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h5>
        <button type="button" #closeDQModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <app-data-quality (closeModal)="cancelModal($event)" (closeRedirectModal)="closeRedirectModal()"
          assetType="Customer" [assetId]="customerId" [areaId]="customer.area.id"
          *ngIf="updateStatusClicked"></app-data-quality>
      </div>
    </div>
  </div>
</div>
<app-audit-review-customer (closeModal)="cancel($event)" assetType="Customer" [assetId]="customerId"></app-audit-review-customer>

<div class="modal" id="rejectionReason" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.REASON_FOR_REJECT' | translate}}</h5>
      </div>
      <div class="p-4">
        {{ modalRejectionReason }}
      </div>
      <button type="button" #closeRejectionReason class="btn btn-primary w-50 mx-auto" data-bs-dismiss="modal"
        aria-label="Close">
        {{'COMMON.CLOSE' | translate}}
      </button>
    </div>
  </div>
</div>
<button class="btn btn-primary" #openUpdateDescriptionModal data-bs-toggle="modal" data-bs-target="#history"
  hidden></button>
<app-history (update)="updateHistory($event)"></app-history>