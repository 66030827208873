import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { PaginationDto, ResponsePackage } from '../models/util.model';
import {
  MeterReadingCount,
    MeterReadingsList,
    MeterReadingsStats,
} from '../models/meterReadings.model';
@Injectable({
  providedIn: 'root',
})
export class MeterReadingService {
  private pathApi = this.config.setting['pathApi'] + 'reading/';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<MeterReadingsList>>>(
      `${this.pathApi}getAll`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }
  getStats(id: number) {
    return this.http.get<ResponsePackage<MeterReadingCount>>(
      `${this.pathApi}getStats/${id}`,
      { headers: this.headers, observe: 'response' }
    );
  }
  get(id: number) {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}${id}`, {
      headers: this.headers,
      observe: 'response',
    });
  }
}
