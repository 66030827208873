<div *ngIf="histories != null && histories.length > 0" class="history-card container">
    <h4 style="text-align: center">{{'COMMON.HISTORY' | translate}}</h4>

    <div *ngFor="let history of histories; let i = index">
        <div class="row history-item" *ngIf="history.values.length > 0">
            <div class="col-6 label-name">
                {{ history.label | translate }}
            </div>
            <div class="col-6">
                <div class="row" *ngFor="let item of history.values">
                    {{ item }}
                </div>
            </div>
        </div>
    </div>
</div>