<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS_INSTALLATIONS.INSTALL_DETAILS' | translate}}</div>
        <div class="slrn">{{ selectedInstallation.customer.accountNumber | hasValue }}</div>
        <div [ngClass]="selectedInstallation.installationStatus">
          {{ selectedInstallation.installationStatus }}
        </div>
      </div>
      <div class="user-address">
        <div>{{ selectedInstallation.customer.accountName | hasValue}}</div>
        <div>{{ selectedInstallation.customer.address | hasValue }}</div>
      </div>
    </div>
    <div     *ngIf="selectedInstallation.installationStatus == 'Submitted'"    >
      <div class="row">
        <div class="col-6">
          <button
            *ngIf="permissionsService.isInstallationApproveRejectAllowed"
            class="btn btn-outline-danger"
            data-bs-toggle="modal"
            data-bs-target="#modalReject"
          >
            {{'COMMON.REJECT' | translate}}
          </button>
        </div>
        <div class="col-6">
          <button
            *ngIf="permissionsService.isInstallationApproveRejectAllowed"
            class="btn btn-outline-success"
            data-bs-toggle="modal"
            data-bs-target="#modalApprove"
          >
            {{'COMMON.APPROVE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">{{'COMMON.CUSTOMER.INFO' | translate}}</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.METER_STATUS' | translate}}</div>
        <div class="col-8">{{ selectedInstallation.customer.meterStatus | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.METER_TYPE.SINGLE' | translate}}</div>
        <div class="col-8">{{ selectedInstallation.customer.customerType | hasValue }}</div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">{{'SURVEYS_INSTALLATIONS.FEEDER_INFO' | translate}}</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.FEEDER_NUMBER' | translate}}</div>
        <div class="col-8">{{ selectedInstallation.dt.feederNumber | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.FEEDER_NAME' | translate}}</div>
        <div class="col-8">{{ selectedInstallation.dt.feederName | hasValue }}</div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">{{'COMMON.BU.SINGLE' | translate}}/{{'COMMON.UT.SINGLE' | translate}}</div>
        <div class="col-8"><hr /></div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.BU.SINGLE' | translate}}</div>
        <div class="col-8">{{ selectedInstallation.customer.regionName | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.UT.SINGLE' | translate}}</div>
        <div class="col-8">{{ selectedInstallation.customer.areaName | hasValue }}</div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'COMMON.CUSTOMER.DETAILS' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.CUSTOMER_NAME' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.name | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.ADDRESS' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.address | hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.TARIFF.T_NAME' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.tarifName | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.TARIFF.T_RATE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.tarifRate | hasValue }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.DT_NAME' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.dt.dtName | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.DT_NUMBER' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.dt.dtNumber | hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.SS.SINGLE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.dt.injectionSubstation | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.TABLE_HEADERS.BUILDING_OWNER' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.buildingOwner | hasValue }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.USER.EMAIL' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.email | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.OLD_METER_NO' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.oldMeterNo | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.METER_TYPE.SINGLE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.customerType | hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.TABLE_HEADERS.METER_MAKE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterManufacturer | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.CONTRACT_NUMBER' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.spn | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.GEOCODE' | translate | uppercase}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.geoCode | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'INSTALLATIONS.DETAILS.METER_PHASE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.typeOfConnection| hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.CUSTOMER.ACTIVITY_CODE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.activityCode | hasValue }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.CURR_PLOT_CODE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.currentPlotCode | hasValue }}</div>
        </div>
      </div>
      <div *ngIf="isGhanaPostFieldsVisible" class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.GHANA_POST_GPS' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.ghanaPostGPS | hasValue }}</div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'INSTALLATIONS.DETAILS.PRELOADED_UNIT' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.readingOnMeter | hasValue }}</div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.CUSTOMER.ACTIVITY_CODE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">{{ selectedInstallation.customer.activityCode | hasValue }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS_INSTALLATIONS.INSTALL_DETAILS' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.TYPE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.installationType | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.METER_LOC' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.meterLocation | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.SERVICE_WIRES_NUM' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.numberOfServiceWires | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.SERVICE_WIRES_COND' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.serviceWiresCondition | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.OUTPUT_CABLE_DIST' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.outputCableDistance | hasValue }} m
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.INPUT_CABLE_DIST' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.inputCableDistance | hasValue }} m
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.EXIST_CABLE_SIZE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.existingCableSize | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.EXIST_METER_LOC' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.existingMeterInLocation | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.METER_ENCLOSURE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.existingMeterEnclosure | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.RECOMMENDED_METER' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.recommendedMeterType | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'SURVEYS_INSTALLATIONS.RECOMMENDED_CABLE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.recommendedCableSize | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.GEOLOCATION' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.latitude | hasValue }},{{
              selectedInstallation.longitude | hasValue
            }}
          </div>
          <div class="col-1" style="margin-left: -2rem" (click)="isMapVisible = !isMapVisible">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 500 500"
              data-bs-toggle="modal"
              data-bs-target="#showMap"
            >
              <path
                d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
                fill="#026962"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row" style="margin-left: 0.3rem;">
      <div class="col-6">
        <div class="row">
          <div class="edit-customers-header" style="margin-top: 2rem">
            <div class="user-header-info">
              <div class="user-info">
                <div class="name">{{'SURVEYS_INSTALLATIONS.MATERIAL_REQ' | translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row tb-border">
          <table class="table table-striped">
            <thead>
              <th>{{'SURVEYS_INSTALLATIONS.MATERIAL_CATEGORY' | translate}}</th>
              <th>{{'SURVEYS_INSTALLATIONS.INSTALL_MATERIAL' | translate}}</th>
              <th>{{'COMMON.TABLE_HEADERS.QUANTITY' | translate}}</th>
              <th>{{'COMMON.TABLE_HEADERS.UNIT' | translate}}</th>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let requirament of selectedInstallation.installationMaterialRequirements
                "
              >
                <td>{{ requirament.materialCategory | hasValue }}</td>
                <td>{{ requirament.installationMaterial | hasValue }}</td>
                <td>{{ requirament.quantity | number | hasValue }}</td>
                <td>{{ requirament.unitOfMeasurement | hasValue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'COMMON.MBC_DETAILS.METER_INFO' | translate}}</div>
      </div>
    </div>
  </div>

  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.TABLE_HEADERS.METER_MAKE' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterManufacturer | hasValue }}
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.TABLE_HEADERS.METER_SERIAL_NUM' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterSerialNumber | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.MBC_DETAILS.METER_NO' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterNumber | hasValue }}
          </div>
        </div>
      </div>
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'INSTALLATIONS.DETAILS.METER_SEAL_NO' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.customer.meterSealNumber | hasValue }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS_INSTALLATIONS.LOC_IMAGES' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="location-images">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">{{'INSTALLATIONS.DETAILS.FRONT_VIEW' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="getFrontInstallationImage()">
            <img
              alt="Installation image"
              src="{{ getFrontInstallationImage() }}"
              (click)="showImage(3)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFrontInstallationImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'INSTALLATIONS.DETAILS.CUSTOMER_BILL' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getCustomerBillImage()">
            <img
              alt="Bill image"
              src="{{ getCustomerBillImage() }}"
              (click)="showImage(4)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getCustomerBillImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">{{'INSTALLATIONS.DETAILS.PROPOSED_METER_POINT' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="getProposedMeterPointBeforeInstallationImage()">
            <img
              alt="Installation image"
              src="{{ getProposedMeterPointBeforeInstallationImage() }}"
              (click)="showImage(5)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getProposedMeterPointBeforeInstallationImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="location-images">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'INSTALLATIONS.DETAILS.INSTALL_IN_PROGRESS' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getInstallationInProgressImage()">
            <img
              alt="Installation image"
              src="{{ getInstallationInProgressImage() }}"
              (click)="showImage(6)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getInstallationInProgressImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'INSTALLATIONS.DETAILS.SEAL_PIC' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getSealImage()">
            <img
              alt="Seal image"
              src="{{ getSealImage() }}"
              (click)="showImage(7)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getSealImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'INSTALLATIONS.DETAILS.INSTALL_W_SEAL' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getInstallationWithSealImage()">
            <img
              src="{{ getInstallationWithSealImage() }}"
              (click)="showImage(8)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getInstallationWithSealImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="location-images">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'INSTALLATIONS.DETAILS.METER_CARD' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getMeterCardImage()">
            <img
              alt="Meter card image"
              src="{{ getMeterCardImage() }}"
              (click)="showImage(9)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getMeterCardImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'INSTALLATIONS.DETAILS.SUPPLY_CABLE_FULL' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getFullViewOfSupplyImage()">
            <img
              src="{{ getFullViewOfSupplyImage() }}"
              (click)="showImage(10)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFullViewOfSupplyImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'INSTALLATIONS.DETAILS.SUPPLY_CABLE_PREMISES' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getSupplyCableImage()">
            <img
              alt="Cable image"
              src="{{ getSupplyCableImage() }}"
              (click)="showImage(11)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getSupplyCableImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="location-images">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'INSTALLATIONS.DETAILS.RETRIEVED_METER' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getRetrievedMeterImage()">
            <img
              alt="Meter image"
              src="{{ getRetrievedMeterImage() }}"
              (click)="showImage(12)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getRetrievedMeterImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">
            {{'INSTALLATIONS.DETAILS.MONITOR_OF_RETRIEVED_METER' | translate}}
          </div>
        </div>
        <div class="row">
          <div *ngIf="getMonitorUIUImage()">
            <img
              alt="Monitor image"
              src="{{ getMonitorUIUImage() }}"
              (click)="showImage(13)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getMonitorUIUImage()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'COMMON.MBC_DETAILS.REMARKS' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row" style="width: 98.5%;">
      <div class="card container col-12">
        {{ selectedInstallation.remarksAndRecommendations | hasValue }}
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'INSTALLATIONS.DETAILS.REVIEWED_BY' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.reviewedBy | hasValue }}
          </div>
        </div>
      </div>

      <div class="card container col-3">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.USERNAME' | translate}}</div>
        </div>
        <div class="row">
          <div class="green-text">
            {{ selectedInstallation.username | hasValue }}
          </div>
        </div>
      </div>
      <div class=" col-3">
      </div>
      <div class="col-3">
      </div>
    </div>
  </div>
  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS_INSTALLATIONS.FIELD_SIGNATURE' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.SIGNATURE' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="getFieldRepSignature()">
            <img
              alt="Field signature image"
              src="{{ getFieldRepSignature() }}"
              (click)="showImage(2)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getFieldRepSignature()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
      <div class="col-4"></div>
    </div>
  </div>

  <div class="edit-customers-header" style="margin-top: 2rem">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'SURVEYS_INSTALLATIONS.CUSTOMER_SIGNATURE' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="customer-survey-body">
    <div class="row">
      <div class="card container col-4">
        <div class="row">
          <div class="col header-title-text">{{'COMMON.SIGNATURE' | translate}}</div>
        </div>
        <div class="row">
          <div *ngIf="getCustomersSignature()">
            <img
              alt="Signature image"
              src="{{ getCustomersSignature() }}"
              (click)="showImage(1)"
              data-bs-toggle="modal"
              data-bs-target="#modalImage"
            />
          </div>
          <div *ngIf="!getCustomersSignature()">
            <label for="fileFw1" class="disabled-label">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
      <div class="col-4"></div>
    </div>
  </div>

</div>

<div
  class="modal modalMap"
  id="showMap"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          #closeAddModal
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map
          [setMapVisibility]="isMapVisible"
          [longitude]="selectedInstallation.longitude"
          [latitude]="selectedInstallation.latitude"
        ></app-building-map>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()" (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage" [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">&lt;</button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">&gt;</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="modalApprove"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'INSTALLATIONS.DETAILS.APPROVE' | translate}}</h5>
      </div>
      <div>
        <div>
          {{'INSTALLATIONS.DETAILS.CONFIRM' | translate}}
        </div>
        <div class="row">
          <div class="col-6">
            <button
              type="button"
              class="btn btn-outline-danger"
              style="width: 100%"
              data-bs-dismiss="modal"
            >
              {{'COMMON.CANCEL' | translate}}
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-success"
              style="width: 100%"
              (click)="approve()"
            >
              {{'COMMON.APPROVE' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div
  class="modal fade"
  id="modalReject"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'INSTALLATIONS.DETAILS.REJECT' | translate}}</h5>
      </div>
      <div>
        <div>
          {{'INSTALLATIONS.DETAILS.REASON' | translate}}
        </div>
        <form id="rejectForm" ngNativeValidate (submit)="reject()">
          <div style="padding: 10px">
            <textarea
              required
              rows="10"
              cols="50"
              name="note"
              [(ngModel)]="rejectionNote"
              class="form-control"
            ></textarea>
          </div>
          <div class="row">
            <div class="col-6">
              <button
                type="button"
                data-bs-dismiss="modal"
                style="width: 100%"
                class="btn btn-outline-danger"
              >
                {{'COMMON.CANCEL' | translate}}
              </button>
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-danger" style="width: 100%">
                {{'COMMON.REJECT' | translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
