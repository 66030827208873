import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, fromEvent, debounceTime, takeUntil } from 'rxjs';
import { Area } from '../../../../models/area.model';
import { ChecklistItem } from '../../../../models/checklist-item.model';
import { SortByOptions, UserType } from '../../../../models/customer.model';
import { PoleDetails } from '../../../../models/pole.model';
import { Region } from '../../../../models/region.model';
import { PermissionsEnum, SystemComponents } from '../../../../models/role.model';
import { UserForFilter } from '../../../../models/user.model';
import { OptionObj } from '../../../../models/util.model';
import { AreaService } from '../../../../services/area.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { PoleService } from '../../../../services/pole.service';
import { RegionService } from '../../../../services/region.service';
import { TranslationService } from '../../../../services/translation.service';
import { UserService } from '../../../../services/user.service';
import { PoleStatus } from '../../../../enums/poles-status.enum';
import { ReportStateService } from '../../services/report-state.service';

@Component({
  selector: 'lt-poles-tab',
  templateUrl: './poles-tab.component.html',
  styleUrls: ['./../data-quality-rejection.component.scss', ]
})
export class LtPolesTabComponent implements OnInit {
    @Output() polesCount = new EventEmitter<any>();

    public PermissionsEnum = PermissionsEnum;  
    poles: PoleDetails[] = [];
  
    mapSortBy: Record<number, string> = {
      [SortByOptions.NEWEST]: 'COMMON.SORT_BY.NEWEST',
      [SortByOptions.SLRN_ASC]: 'COMMON.SORT_BY.SLRN_ASC',
      [SortByOptions.SLRN_DESC]: 'COMMON.SORT_BY.SLRN_DESC',
      [SortByOptions.ID_ASC]: 'COMMON.SORT_BY.ID_ASC',
      [SortByOptions.ID_DESC]: 'COMMON.SORT_BY.ID_DESC',
    };
  
    count: number = 2;
    pageSize: number = 10;
    currentPage: number = 1;
    dateFrom: string;
    dateTo: string;
    searchText: string = '';
    searchTextUpdate = new Subject<string>();
    filterByValue: string = '';
  
    sortByValue: SortByOptions = SortByOptions.NEWEST;
    sortByOptions = SortByOptions;
    sortByName: string = this.mapSortBy[SortByOptions.NEWEST];
  
    showUpload: boolean = false;
    showSortBy: boolean = false;
    clickObservable: Observable<Event> = fromEvent(document, 'click');
  
    historyItems: any[] = [];
    isViewHistory: boolean = true;
  
    buName: string = this.translationService.getByKeyFromStorage('BU');
    utName: string = this.translationService.getByKeyFromStorage('UT');
    utilityId: number = 0;
  
    private ngUnsubscribe = new Subject<void>();
    selectedFilters: ChecklistItem[] = [];
    clicked: boolean = false;
    regions: Region[] = [];
    areas: Area[] = [];
    allAreas: Area[] = [];
    poleTypesOptions: OptionObj[] = [];
    poleType: number = 0;
    poleCondition: number = 0;
    poleStatus: number = 0;
    selectedAreas: number[] = [];
    selectedRegions: number[] = [];
    poleConditionOptions = [
      { name: 'Good', value: 1, translation: 'COMMON.POLE.CONDITION.GOOD' },
      { name: 'Bent Leaning', value: 2, translation: 'COMMON.POLE.CONDITION.BENT' },
      { name: 'Vandalized', value: 3, translation: 'COMMON.POLE.CONDITION.VANDALIZED' },
    ];

    allCheckboxesChecked: boolean = false;
    dateTypes = [
      { name: 'ValidatedDate', value: 'Validated Date', translation: 'COMMON.DATE_TYPE.VALIDATED' },
      { name: 'LastUpdateTime', value: 'Last Update Time', translation: 'COMMON.DATE_TYPE.LAST_UPDATE' },
      { name: 'TaggedDate', value: 'Tagged Date', translation: 'COMMON.DATE_TYPE.TAGGED' },
    ];
    selectedDateType: string;
    selectedDateTypeDisplay: string;
    showSelectDateType: boolean = false;
    slrnDropdownOpened: boolean = false;
    
    usersFilter: any; // Stores value of filter
    mobileUsers: UserForFilter[] = []; // Store only mobile users
    adminUsers: UserForFilter[] = []; // Store only admin users
    allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
    filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
    selectedUsers: number[] = [];
  
    userType?: number;
    userTypeOptions = [
      { name: 'First Captured User', value: UserType.FirstCapturedUser, translation: 'COMMON.USER.TYPE.FIRST_CAPTURED' },
      { name: 'Last Captured User', value: UserType.LastCapturedUser, translation: 'COMMON.USER.TYPE.LAST_CAPTURED' },
      { name: 'Reassigned By', value: UserType.ReassignedBy, translation: 'COMMON.USER.TYPE.REASSIGNED' },
      { name: 'Validated By', value: UserType.ValidatedBy, translation: 'COMMON.USER.TYPE.VALIDATED' },
      { name: 'Audited By', value: UserType.AuditedBy, translation: 'COMMON.USER.TYPE.AUDITED' },
    ];
    mobileUserType: UserType[] = [
      UserType.FirstCapturedUser,
      UserType.LastCapturedUser,
    ];
    adminUserType: UserType[] = [
      UserType.ValidatedBy,
      UserType.ReassignedBy,
      UserType.AuditedBy,
    ];

    @Output() ltPolesFetched: EventEmitter<number> = new EventEmitter();

    constructor(
      private poleService: PoleService,
      private toastr: ToastrService,
      public permissionsService: PermissionsService,
      private regionService: RegionService,
      private areaService: AreaService,
      private translationService: TranslationService,
      private route: ActivatedRoute,
      private userService: UserService,
      private router:Router,
      private reportStateService: ReportStateService,
    ) {}
  
    ngOnInit(): void {
      this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
      this.setFilters();
      this.getRegions();
      this.setCheckedUsers();
      this.getData();
      this.subscribeToClickEvent();
      this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
        if (this.searchText == '') this.search();
      });
  
      this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
      this.selectedDateTypeDisplay =
        this.selectedDateTypeDisplay ?? this.dateTypes[0].translation;
    }
    ngOnDestroy(): void {
      this.searchTextUpdate.complete();
    }
    private getData(): void {
      this.getPoleTypesForOptions();
      this.reloadTable();
    }
  
    // onFilterBy(option: any) {
    //   this.filterByValue = option;
    //   this.reloadTable(1);
    // }
  
    setFilters() {
      var filter = localStorage.getItem(this.reportStateService.ltPoleTabKey);
      if (!filter) return;
      var filterObject = JSON.parse(filter);
      if (filterObject.type != 'Pole') return;
      this.currentPage = filterObject.filter.pageInfo.page;
      this.pageSize = filterObject.filter.pageInfo.pageSize;
      this.sortByValue = filterObject.filter.filterParams.sortBy;
      this.searchText = filterObject.filter.filterParams.search;
      this.dateFrom = filterObject.filter.filterParams.dateFrom;
      this.dateTo = filterObject.filter.filterParams.dateTo;
      this.selectedDateType = filterObject.filter.filterParams.dateType;
      this.selectedDateTypeDisplay =
        this.dateTypes.find(
          (type) => type.name == filterObject.filter.filterParams.dateType
        )?.translation ?? this.dateTypes[0].translation;
      this.poleStatus = filterObject.filter.filterParams.status;
      this.poleType = filterObject.filter.filterParams.type ?? 0;
      this.poleCondition = filterObject.filter.filterParams.condition ?? 0;
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas
        ? filterObject.filter.filterParams.selectedAreas
        : [];
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions
        ? filterObject.filter.filterParams.selectedRegions
        : [];
        this.userType = filterObject.filter.filterParams.userType
        ? filterObject.filter.filterParams.userType
        : undefined;
        this.selectedUsers = filterObject.filter.filterParams.selectedUsers
      ? filterObject.filter.filterParams.selectedUsers
      : [];
      this.sortByName = this.mapSortBy[this.sortByValue];
    }
    private getRegions() {
      this.regionService.getAllForSelect().subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.regions = responseData.data;
            this.setCheckedRegions();
            this.getAreas();
          } else if (response?.status == 204) {
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
    private getAreas() {
      this.areaService.getAllForSelect().subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.allAreas = responseData.data;
            this.areas = this.allAreas.filter((area) =>
            this.regions.some(
                (region) => region.id == area.regionId && region.checked
              )
            );
            this.setCheckedAreas();
            this.createFilterCards();
          } else if (response?.status == 204) {
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
    selectDataQuality(id: number) {
      this.router.navigate([`/poles/data-quality/${id}`]);
    }
    getPoleTypesForOptions() {
      this.poleService.getPoleTypesForOptions().subscribe(
        (response) => {
          this.poleTypesOptions = response.data;
        },
        (error) => {
          this.toastr.error('An error occurred.');
        }
      );
    }

  
    setFilterStorage() {
      let obj = {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize,
        },
        filterParams: this.getFilterObject(true),
        utilityId: this.utilityId,
      };
  
      localStorage.setItem(
        this.reportStateService.ltPoleTabKey,
        JSON.stringify({ type: 'Pole', filter: obj })
      );
    }

    getFilterObject(isForStorage: boolean = false) {
      let obj = {
        sortBy: this.sortByValue,
        search: this.searchText,
        filterBy: this.filterByValue,
        dateFrom: this.dateFrom ? this.dateFrom : null,
        dateTo: this.dateTo ? (isForStorage ? this.dateTo : `${this.dateTo}T23:59:59`) : null,
        dateType: this.selectedDateType,
        selectedRegions: this.selectedRegions,
        selectedAreas: this.selectedAreas,
        userType: isForStorage ? this.userType ? this.userType : null : (this.userType
            ? this.selectedUsers.length > 0
              ? this.userType
              : null
            : null),
        selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
        type: this.poleType,
        condition: this.poleCondition,
        status: PoleStatus.Rejected,
        // status: this.poleStatus,
        selectedIds: this.poles
          .filter((x) => x.isChecked == true)
          .map((x) => x.id),
      };
      return obj;
    }
    datesValid() {
      if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
        const valid: boolean =
          new Date(this.dateFrom).getTime() <
          new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
        return valid;
      }
  
      return true;
    }
    private getAll(obj: any) {
      this.poleService.getAllRejectionReports(obj).subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.poles = responseData?.data?.data?.length ? responseData?.data?.data?.map((el: any) => new PoleDetails(el)) : [];
            this.poles.forEach(x => {
              x.buildingsSLRN.replace(/,/g, ', ');
            });
            this.count = responseData.data.count ?? this.count;
            this.polesCount.emit(this.count);

            this.createFilterCards();
            if (responseData.message != '') {
              this.toastr.warning(responseData.message);
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => this.toastr.error('Error occured'),
      });
    }
  
    isAllCheckBoxChecked() {
      return false;
    }
  
    checkAllCheckboxes() {
      this.poles.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
      this.allCheckboxesChecked = !this.allCheckboxesChecked;
    }
  
    checkPole(id: number) {
      const pole = this.poles.find((x) => x.id === id);
      if (pole) {
        pole.isChecked = !pole.isChecked;
        this.areAllCheckboxesChecked();
      }
    }
  
    areAllCheckboxesChecked() {
      this.allCheckboxesChecked = this.poles.every((x) => x.isChecked);
    }
  
    reloadTable(page: any = null) {
      this.allCheckboxesChecked = false;
      if (page) this.currentPage = page;
      let obj = {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize,
        },
        filterParams: this.getFilterObject(),
      };
      this.setFilterStorage();
  
      this.getAll(obj);
    }
  
    search() {
      this.createFilterCards();
    }
  
    sortBy(option: SortByOptions) {
      this.sortByValue = option;
      this.sortByName = this.mapSortBy[option];
      this.reloadTable();
    }
  
    pageChange(value: any) {
      this.currentPage = value;
      this.reloadTable();
    }
  
    private subscribeToClickEvent() {
      this.clickObservable
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((e: any) => {
          this.showUpload = false;
          this.showSortBy = false;
          this.showSelectDateType = false;
        });
    }
  
    download() {
      let obj = this.getFilterObject();
      this.poleService.downloadRejectionReports(obj).subscribe({
        next: (response) => {
          const data = response.body;
          if (data?.status === 'OK' || data?.status === '200') {
            window.location.href = data.data;
          } else if (data?.status === 'Pending' || data?.status === '600') {
            this.toastr.info(data?.message || '');
          } else this.toastr.error(data?.message || 'Request failed');
        },
        error: (error) => console.log(error),
      });
    }
  
    viewHistory(id: number) {
      this.poleService.getHistory(id).subscribe({
        next: (response) => {
          console.log(response);
          this.historyItems = response?.body?.data ?? [];
        },
      });
    }
  
    selectRegion(e: any) {
      //list of areas for selected regions
      this.areas = this.allAreas.filter((area) =>
        this.regions.some(
          (region) => region.id == area.regionId && region.checked
        )
      );
      this.allAreas.forEach((area) => {
        if (!this.areas.some((a) => a.id === area.id)) {
          area.checked = false; //uncheck areas for unchecked regions
        }
      });
  
      this.createFilterCards();
    }
    selectArea(e: any) {
      this.createFilterCards();
    }
    selectPoleType(e: any) {
      this.poleType = +e.target.value;
      this.createFilterCards();
    }
    selectPoleCondition(e: any) {
      this.poleCondition = +e.target.value;
      this.createFilterCards();
    }

    applyFilter(item: ChecklistItem){
      this.reloadTable(1);
    }

    createFilterCards() {
      this.selectedFilters = [];
      let selectedUsers = this.allUsers.filter((x) => x.checked);
      let selectedAreas = this.areas.filter((x) => x.checked);
      this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
      this.selectedRegions = this.regions
        .filter((x) => x.checked)
        .map((x) => x.id);
      this.selectedUsers = this.allUsers
        .filter((x) => x.checked)
        .map((x) => x.id);
      //areas
      for (var i = 0; i < selectedAreas.length; i++) {
        this.selectedFilters.push(
          new ChecklistItem(this.utName, selectedAreas[i].name, 'COMMON.UT.SINGLE')
        );
      }
      
      if (this.userType) {
        var userType = this.userTypeOptions.filter((option) => option.value == this.userType)[0];
        this.selectedFilters.push(new ChecklistItem('User type', userType.name, 'COMMON.USER.TYPE.TITLE', userType.translation));
      }

      //search
      if (this.searchText){
        this.selectedFilters.push(
          new ChecklistItem('Search', this.searchText)
        );
      }

      //dates
      if (this.dateFrom){
        this.selectedFilters.push(
          new ChecklistItem('Date From', this.dateFrom)
        );
      }
      
      if (this.dateTo){
        this.selectedFilters.push(
          new ChecklistItem('Date To', this.dateTo)
        );
      }
  
      //users
      for (var i = 0; i < selectedUsers.length; i++) {
        this.selectedFilters.push(
          new ChecklistItem('User', selectedUsers[i].name, 'COMMON.USER.SINGLE')
        );
      }
  
      if (this.poleType != 0) {
        let poleName = this.poleTypesOptions.filter(
          (x) => x.key === this.poleType
        )[0].value;
        this.selectedFilters.push(new ChecklistItem('Pole Type', poleName, 'COMMON.TABLE_HEADERS.POLE_TYPE'));
      }
  
      if (this.poleCondition != 0) {
        let poleCondition = this.poleConditionOptions.filter(
          (x) => x.value == this.poleCondition
        )[0];
        this.selectedFilters.push(
          new ChecklistItem('Pole Condition', poleCondition.name, 'COMMON.POLE.CONDITION.TITLE', poleCondition.translation)
        );
      }
    }
    removeFilter(item: ChecklistItem) {
      if (item.property == 'User') {
        this.allUsers.forEach((user) => {
          if (user.name === item.selectedValue) {
            user.checked = false;
          }
        });
        this.clicked = !this.clicked;
      } else if (item.property == 'User type') {
        this.userType = undefined;
        this.allUsers.forEach((user) => {
          // Clear list if he selects same filter again to get all cleared
          user.checked = false;
        });
        this.allUsers = this.filteredUsers = [...[]];
      } else if (item.property == this.utName) {
        this.areas.forEach((area) => {
          if (area.name === item.selectedValue) {
            area.checked = false;
          }
        });
        this.clicked = !this.clicked;
      } else if (item.property == 'Pole Type') {
        this.poleType = 0;
      } else if (item.property == 'Pole Condition') {
        this.poleCondition = 0;
      } else if (item.property == 'Pole Status') {
        this.poleStatus = 0;
      } else if (item.property == 'Search'){
        this.searchText = '';
      } else if (item.property == 'Date From'){
        this.dateFrom = '';
      } else if (item.property == 'Date To'){
        this.dateTo = '';
      }
  
      this.createFilterCards();
      this.setFilterStorage();
      if(this.selectedFilters.length == 0){
        this.regions.forEach((region) => {       
            region.checked = false;        
        });
        this.reloadTable(1);
      }
    }
  
    resetFilter(e: any) {
      this.selectedFilters = [];
      this.regions.forEach((region) => {
        region.checked = false;
      });
      this.selectedRegions = [];
      this.areas.forEach((area) => {
        area.checked = false;
      });
      this.areas = [];
      this.selectedAreas=[];
      this.selectedRegions = [];
      this.allUsers.forEach((user) => {
        user.checked = false;
      });
      this.allUsers = this.filteredUsers = [...[]];

      this.searchText = '';
      this.dateFrom = '';
      this.dateTo = '';
  
      this.userType = undefined;
      this.poleType = 0;
      this.poleCondition = 0;
      this.poleStatus = 0;
      this.clicked = !this.clicked;
      this.setFilterStorage();
      this.reloadTable(1);
    }

    setCheckedAreas() {
      var filter = localStorage.getItem(this.reportStateService.ltPoleTabKey);
      if (!filter) return;
      var filterObject = JSON.parse(filter);
      if (filterObject.type != 'Pole') return;
      if (filterObject.filter.filterParams.selectedAreas) {
        this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
        this.areas.forEach((element) => {
          if (this.selectedAreas.includes(element.id)) element.checked = true;
          else element.checked = false;
        });
      }
      this.createFilterCards();
    }
    setCheckedRegions() {
      var filter = localStorage.getItem(this.reportStateService.ltPoleTabKey);
      if (!filter) return;
      var filterObject = JSON.parse(filter);
      if (filterObject.type != 'Pole') return;
      if (filterObject.filter.filterParams.selectedRegions) {
        this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
        this.regions.forEach((element) => {
          if (this.selectedRegions.includes(element.id)) element.checked = true;
          else element.checked = false;
        });
      }
      this.createFilterCards();
    }
  
    selectDateType(dateType: any){
      this.selectedDateType = dateType.name;
      this.selectedDateTypeDisplay = dateType.translation;
      this.showSelectDateType = false;
    }
  
    formatSlrn(originalString: string): string {
      return originalString.split(",", 2).toString().replace(/,/g, ', ');
    }
  
    async setCheckedUsers() {
      var filter = localStorage.getItem(this.reportStateService.ltPoleTabKey);
      if (!filter) return;
      var filterObject = JSON.parse(filter);
      if (filterObject.type != 'Pole') return;
      if (
        // filterObject.filter.filterParams.selectedUsers &&
        filterObject.filter.filterParams.userType
      ) {
        this.userType = filterObject.filter.filterParams.userType;
        this.getUsersForFilter(true, filterObject);
      }
    }
    
    resetUserList() {
      this.allUsers.forEach((user) => {
        user.checked = false;
      });
      this.allUsers = [];
    }
  
    selectUserType(e: any) {
      if (this.userType)
        this.resetUserList();
  
      if (e.target.value != '') {
        this.userType = e.target.value;
        this.getUsersForFilter(false);
      } else {
        this.userType = undefined;
      }
  
      this.createFilterCards();
    }
  
    getUsersForFilter(isFromCache: boolean, filterObject?: any) {
      if (
        this.userType == UserType.FirstCapturedUser ||
        this.userType == UserType.LastCapturedUser
      ) {
        // Dobavi mobilne usere iz baze ako nisi
        if (this.mobileUsers.length === 0) {
          this.userService
            .getUsersForFilter(SystemComponents.Mobile)
            .subscribe((resp) => {
              this.mobileUsers = resp.data;
              this.allUsers = this.filteredUsers = this.mobileUsers;
              if (isFromCache) {
                this.selectedUsers =
                  filterObject.filter.filterParams.selectedUsers;
                this.allUsers.forEach((element) => {
                  if (this.selectedUsers.includes(element.id))
                    element.checked = true;
                  else element.checked = false;
                });
              }
              this.createFilterCards();
            });
        } else {
          this.allUsers = this.filteredUsers = [...this.mobileUsers];
        }
      } else {
        if (this.adminUsers.length === 0) {
          this.userService
            .getUsersForFilter(SystemComponents.Admin)
            .subscribe((resp) => {
              this.adminUsers = resp.data;
              this.allUsers = this.filteredUsers = this.adminUsers;
              if (isFromCache) {
                this.selectedUsers =
                  filterObject.filter.filterParams.selectedUsers;
                this.allUsers.forEach((element) => {
                  if (this.selectedUsers.includes(element.id))
                    element.checked = true;
                  else element.checked = false;
                });
                this.createFilterCards();
              }
            });
        } else {
          this.allUsers = this.filteredUsers = [...this.adminUsers];
        }
      }
    }
  
    applySearchFilter(e: any) {
      this.filteredUsers = this.allUsers.filter((user) => user.name.includes(e));
    }
  
    selectUsers(e: any) {
      if (e.status) {
        this.allUsers.push(e.value);
      } else {
        var index = this.allUsers.indexOf(e.value);
        this.allUsers.splice(index, 1);
      }
      this.createFilterCards();
    }
  }
  
