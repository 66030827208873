<div class="container-fluid">
  <div class="regular-tabs">
    <div class="d-flex justify-content-start switch-toggle transparent">
      <a
        class="separated-toggle-item regularization-separated-toggle-item"
        [ngClass]="selectedTab == tabs.Regularized ? 'active' : ''"
        (click)="changeTab(tabs.Regularized)"
      >
        <div>
          <span class="bubble">{{ stats.regularized ?? 0 | number }}</span>
          {{ "COMMON.FILTER_BY_OPTIONS.REGULARIZED" | translate }}
        </div>
      </a>
      <a
        class="separated-toggle-item regularization-separated-toggle-item"
        [ngClass]="selectedTab == tabs.Queue ? 'active' : ''"
        (click)="changeTab(tabs.Queue)"
      >
        <div>
          <span class="bubble">{{ stats.queued ?? 0 | number }}</span>
          {{ "COMMON.IN_QUEUE" | translate }}
        </div>
      </a>
      <a
        class="separated-toggle-item regularization-separated-toggle-item"
        [ngClass]="selectedTab == tabs.Failed ? 'active' : ''"
        (click)="changeTab(tabs.Failed)"
      >
        <div>
          <span class="bubble">{{ stats.failed ?? 0 | number }}</span>
          {{ "COMMON.FAILED" | translate }}
        </div>
      </a>
    </div>
  </div>
  <div class="green-table">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="d-flex">
        <div id="reports_r_search" class="search-input">
          <img
            src="../../assets/icons/search.svg"
            alt="Search icon"
            (click)="search()"
          />
          <input
            class="smaller-font"
            type="text"
            [placeholder]="'COMMON.SEARCH' | translate"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
        </div>
        <div id="reports_r_search-by" class="search-type-picker">
          <div
            class="dropdown"
            (click)="showSelectSearchType = !showSelectSearchType"
            click-stop-propagation
          >
            <span class="label smaller-font"
              >{{ "COMMON.BY" | translate }}:</span
            >
            <span class="smaller-font" style="width: 60%">{{ selectedSearchTypeDisplay | translate }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" style="width: 130%;" *ngIf="showSelectSearchType">
            <div
              [id]="'reports_r_search-by-' + (i + 1)" (click)="selectSearchType(searchType)"
              *ngFor="let searchType of searchByOptions; let i = index"
            >
              <div>{{ searchType.translation | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div id="reports_r_date-type" class="datetype-picker">
              <div
                class="dropdown"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span class="smaller-font"
                  >{{ "COMMON.DATE_TYPE.TITLE" | translate }}:</span
                >
                <span class="smaller-font" style="width: 55%">{{ selectedDateTypeDisplay | translate }}</span>
                <i class="arrow-custom --small down"></i>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <ng-container  *ngFor="let dateType of dateTypes; let i = index">
                  <div
                    [id]="'reports_r_date-type-' + (i + 1)"
                    *ngIf="!dateType.hidden"
                    (click)="
                      selectDateType(dateType);
                      datesValid() == true && (dateTo || dateFrom) && search()
                    "
                  >
                    <div>{{ dateType.translation | translate }}</div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div id="reports_r_date-from" class="datepicker-box space-right smaller-font">
              <label class="smaller-font" for="dateFrom">
                {{ "COMMON.FROM" | translate }}:
              </label>
              <input
                #fromPicker
                onclick="this.showPicker()"
                placeholder="dd/mm/yyyy"
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>

            <div id="reports_r_date-to" class="datepicker-box space-right smaller-font">
              <label class="smaller-font" for="dateTo"> {{'COMMON.TO' | translate}}: </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>
          </div>
        </div>
        <div
          id="reports_r_sort-by"
          class="sort-by-root"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown">
            <span class="smaller-font">{{ "COMMON.SORT_BY.TITLE" | translate }}:</span>
            <span class="smaller-font" style="width: 40%;">{{ sortByLabelValue.translation | translate }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" style="width: 115%;" *ngIf="showSortBy">
            <div id="reports_r_sort-by-newest" (click)="sortBy(sortByOptions.NEWEST)">
              <div>{{ "COMMON.SORT_BY.NEWEST" | translate }}</div>
            </div>
            <div id="reports_r_sort-by-slrn-asc" (click)="sortBy(sortByOptions.SLRN_ASC)">
              <div>{{ "COMMON.SLRN" | translate }}</div>
              <img
                class="arrow-up"
                src="../../../assets/icons/arrow.svg"
                alt="Arrow icon"
              />
            </div>
            <div id="reports_r_sort-by-slrn-desc" (click)="sortBy(sortByOptions.SLRN_DESC)">
              <div>{{ "COMMON.SLRN" | translate }}</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
            <div id="reports_r_sort-by-acc-no-asc" (click)="sortBy(sortByOptions.ACCNO_ASC)">
              <div>{{ "COMMON.SORT_BY.ACCOUNT_NO" | translate }}</div>
              <img
                class="arrow-up"
                src="../../../assets/icons/arrow.svg"
                alt="Arrow icon"
              />
            </div>
            <div id="reports_r_sort-by-acc-no-desc" (click)="sortBy(sortByOptions.ACCNO_DESC)">
              <div>{{ "COMMON.SORT_BY.ACCOUNT_NO" | translate }}</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
          </div>
        </div>
        <button class="button primary--white four-radius" (click)="download()">
          <span class="smaller-font">{{ "COMMON.DOWNLOAD" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list smaller-font">
        <span class="label">{{ "COMMON.FILTER" | translate }}</span>
        <app-multi-select-dropdown
          id="reports_r_region_filter"
          class="multiselect"
          [clicked]="clicked"
          [items]="regions"
          [label]="'COMMON.BU.PLURAL'"
          [borderRadius]="'0.4rem'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          id="reports_r_area_filter"
          class="multiselect"
          [clicked]="clicked"
          [items]="areas"
          [label]="'COMMON.UT.PLURAL'"
          [borderRadius]="'0.4rem'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
        >
        </app-multi-select-dropdown>
        <select
          id="reports_r_tariff_filter"
          name="tariff-select"
          (change)="selectTariff($event)"
          class="form-select"
        >
          <option value="0" [selected]="tariff == 0" disabled>
            {{ "COMMON.TARIFF.SINGLE" | translate }}
          </option>
          <option
            [id]="'reports_r_tariff_filter-' + (i + 1)"
            [selected]="tariff == o.id"
            *ngFor="let o of tariffs; let i = index"
            value="{{ o.id }}"
          >
            {{ o.name }}
          </option>
        </select>

        <select
          id="reports_r_user-type_filter"
          *ngIf="isServiceTypeFieldVisible"
          name="st-select"
          (change)="selectUserType($event)"
          class="form-select"
        >
          <option value="" [selected]="!userType" disabled>
            {{ "COMMON.USER.TYPE.TITLE" | translate }}
          </option>
          <option
            [id]="'reports_r_user-type_filter-' + (i + 1)"
            [selected]="userType == o.value"
            *ngFor="let o of userTypeOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>

        <app-multi-select-dropdown
          id="reports_r_user_filter"
          class="multiselect"
          [searchFilterActive]="true"
          [borderRadius]="'0.4rem'"
          (filterValue)="applySearchFilter($event)"
          [clicked]="clicked"
          [items]="filteredUsers"
          [label]="'COMMON.USER.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectUsers($event)"
        >
        </app-multi-select-dropdown>
        <select
          id="reports_r_tag_filter"
          name="tag-select"
          (change)="selectTag($event)"
          class="form-select"
        >
          <option value="" [selected]="!tag" disabled>
            {{ "COMMON.TAG.TITLE" | translate }}
          </option>
          <option
            [id]="'reports_r_tag_filter-' + (i + 1)"
            [selected]="tag == t.value"
            *ngFor="let t of tagOptions; let i = index"
            value="{{ t.value }}"
          >
            {{ t.translation | translate }}
          </option>
        </select>
        <select
          id="reports_r_slt-status_filter"
          name="slt-select"
          (change)="selectSltStatus($event)"
          class="form-select"
        >
          <option value="" [selected]="!sltStatus" disabled>
            {{ 'COMMON.SLT_STATUS.SINGLE' | translate }}
          </option>
          <option
            [id]="'reports_r_slt-status_filter-' + (i + 1)" [selected]="sltStatus == s.key"
            *ngFor="let s of sltOptions; let i = index"
            value="{{ s.key }}"
          >
            {{ s.value }}
          </option>
        </select>

        <select
          id="reports_r_uop_filter"
          name="uop-select"
          (change)="selectUseOfPremises($event)"
          class="form-select"
        >
          <option value="" [selected]="!useOfPremises" disabled>
            {{ "COMMON.USE_OF_PREMISES" | translate }}
          </option>
          <option
            [id]="'reports_r_uop_filter-' + (i + 1)" [selected]="useOfPremises == u.value"
            *ngFor="let u of uopOptions; let i = index"
            value="{{ u.value }}"
          >
            {{ u.translation | translate }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist [filters]="selectedFilters" [useApply]="true" (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)" (applyFilterEmitter)="applyFilter()">
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="customers">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th id="reports_r_table_header_checkbox" class="checkbox">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th id="reports_r_table_header_slrn">{{ "COMMON.SLRN" | translate }}</th>
              <th id="reports_r_table_header_building-owner">{{ "COMMON.TABLE_HEADERS.BUILDING_OWNER" | translate }}</th>
              <th id="reports_r_table_header_acc-no">{{ "COMMON.TABLE_HEADERS.ACCT_NO" | translate }}</th>
              <th id="reports_r_table_header_meter-no">{{ "COMMON.TABLE_HEADERS.METER_NO" | translate }}</th>
              <th id="reports_r_table_header_dt">{{ "COMMON.DT_NAME" | translate }}</th>
              <th id="reports_r_table_header_empty"></th>
              <th id="reports_r_table_header_region">{{ "COMMON.BU.SINGLE" | translate }}</th>
              <th id="reports_r_table_header_district">{{ "COMMON.UT.SINGLE" | translate }}</th>
              <th id="reports_r_table_header_tariff">{{ "COMMON.TARIFF.SINGLE" | translate }}</th>
              <th id="reports_r_table_header_validation-status">{{ "COMMON.VALIDATION_STATUS" | translate }}</th>
              <th id="reports_r_table_header_tag">{{ "COMMON.TAG.TITLE" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr [id]="'reports_r_table_row-' + (i + 1)" *ngFor="let customer of customers; let i = index">
              <td [id]="'reports_r_table_chechbox-' + (i + 1)">
                <input
                  type="checkbox"
                  (click)="checkCustomer(customer.id)"
                  class="form-check-input"
                  [ngModel]="customer.isChecked"
                />
              </td>
              <td [id]="'reports_r_table_slrn-' + (i + 1)">
                {{ customer.slrn | hasValue }}
              </td>
              <td [id]="'reports_r_table_building-owner-' + (i + 1)">
                {{ customer.buildingOwner | hasValue }}
              </td>
              <td [id]="'reports_r_table_acc-no-' + (i + 1)">
                {{ customer.accountNumber | hasValue }}
              </td>
              <td [id]="'reports_r_table_meter-no-' + (i + 1)">
                {{ customer.meterNo | hasValue }}
              </td>
              <td [id]="'reports_r_table_dt-' + (i + 1)">
                {{ customer.dtName | hasValue }}
              </td>
              <td [id]="'reports_r_table_address-' + (i + 1)">
                {{ customer.physicalAddress | hasValue }}
              </td>
              <td [id]="'reports_r_table_region-' + (i + 1)">
                {{ customer.area.regionName | hasValue }}
              </td>
              <td [id]="'reports_r_table_district-' + (i + 1)">
                {{ customer.area.name | hasValue }}
              </td>
              <td [id]="'reports_r_table_tariff-' + (i + 1)">
                {{ customer.tariffName | hasValue }}
              </td>
              <td [id]="'reports_r_table_validation-status-' + (i + 1)">
                <div
                  *ngIf="
                    !customer.dqStatus &&
                    customer.assetStatus !== AssetStatus.Assigned
                  "
                  class="gray-status v-2"
                >
                  {{ "COMMON.ASSET_STATUS.PENDING" | translate }}
                </div>
                <div
                  *ngIf="customer.assetStatus === AssetStatus.Assigned"
                  class="orange-status v-2"
                >
                  {{ "COMMON.ASSET_STATUS.ASSIGNED" | translate }}
                </div>
                <div
                  *ngIf="
                    customer.dqStatus &&
                    customer.dqStatus?.toString() == 'Approved' &&
                    customer.assetStatus !== AssetStatus.Assigned
                  "
                  class="green-status v-2"
                >
                  {{ "COMMON.ASSET_STATUS.COMPLETE" | translate }}
                </div>
                <div
                  *ngIf="
                    customer.dqStatus &&
                    customer.dqStatus?.toString() == 'Rejected' &&
                    customer.assetStatus !== AssetStatus.Assigned
                  "
                  class="dark-red-status v-2"
                >
                  {{ "COMMON.ASSET_STATUS.REJECTED" | translate }}
                </div>
              </td>
              <td [id]="'reports_r_table_tag-' + (i + 1)">
                <div *ngIf="!customer.isOnBoard">
                  <div *ngIf="!customer.isNew" class="dark-green-status v-2">
                    {{ "COMMON.TAG.EXISTING" | translate }}
                  </div>
                  <div *ngIf="customer.isNew" class="green-status v-2">
                    {{ "COMMON.TAG.NEW_ENTRY" | translate }}
                  </div>
                </div>
                <div *ngIf="customer.isOnBoard">
                  <div class="olive-green-status v-2">
                    {{ "COMMON.FILTER_BY_OPTIONS.ONBOARD" | translate }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <app-paging
        *ngIf="count > 0"
        [pageSize]="pageSize"
        [useDirect]="true"
        [count]="count"
        [currentPage]="currentPage"
        (pageChange)="pageChange($event)"
      ></app-paging>
    </div>
  </div>
</div>
