<div class="container-fluid customers-root">
  <div class="stats-root">
    <div
      class="stats-item row regularized-tab total-count total-count__container"
      [ngClass]="{ active: filterByValue === filterByOptions.TOTAL }"
    >
      <div
        class="col-12 regularized"
        (click)="onFilterBy(filterByOptions.TOTAL)"
      >
        <div class="regularized-title">{{ "CUSTOMERS.TOTAL" | translate }}</div>

        <div></div>
        <div></div>
        <div></div>
        <div></div>

        <div *ngIf="true" class="count-number">
          {{ count }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="table-root">
  <div
    class="table-action-wrapper d-flex align-items-center justify-content-between"
  >
    <div id="reports_m_search" class="search-input">
      <img
        src="../../assets/icons/search.svg"
        alt="Search icon"
        (click)="search()"
      />
      <input
        type="text"
        [placeholder]="'COMMON.SEARCH' | translate"
        [(ngModel)]="searchText"
        (keydown.enter)="search()"
        (ngModelChange)="this.searchTextUpdate.next($event)"
      />
    </div>
    <div class="ml-auto d-flex">
      <div>
        <div class="d-flex">
          <div id="reports_m_date-from" class="datepicker-container" style="width: 23rem;">
            <label for="dateFrom"> {{ "COMMON.FROM" | translate }}: </label>
            <input
              type="date"
              id="dateFrom"
              name="dateFrom"
              [ngClass]="
                dateFrom > dateTo
                  ? 'btn datepicker-icon-error'
                  : 'btn datepicker-icon'
              "
              [(ngModel)]="dateFrom"
              (change)="datesValid() == true && search()"
            />
          </div>

          <div id="reports_m_date-to" class="datepicker-container">
            <label for="dateTo" class="right-label">
              {{ "COMMON.TO" | translate }}:
            </label>
            <input
              type="date"
              id="dateTo"
              name="dateTo"
              [ngClass]="
                dateFrom > dateTo
                  ? 'btn datepicker-icon-error'
                  : 'btn datepicker-icon'
              "
              [(ngModel)]="dateTo"
              (change)="datesValid() == true && search()"
            />
          </div>
        </div>
      </div>
      <div
        id="reports_m_sort-by"
        class="sort-by-root"
        (click)="showSortBy = !showSortBy"
        click-stop-propagation
      >
        <div class="dropdown">
          <span>{{ "COMMON.SORT_BY.TITLE" | translate }}:</span>
          <span
            >{{ sortByLabelValue?.translation | translate
            }}<img
              *ngIf="sortByLabelValue?.arrow !== 'no'"
              [ngClass]="{ 'arrow-up': sortByLabelValue?.arrow === 'up' }"
              src="../../../assets/icons/arrow.svg"
              alt="Arrow"
          /></span>
          <span
            ><img
              src="../../assets/icons/gray-arrow-down.svg"
              alt="Gray arrow down"
          /></span>
        </div>
        <div class="dropdown-opened v-2" *ngIf="showSortBy">
          <div id="reports_m_search-by-last-update" (click)="sortBy(sortByOptions.LAST_UPDATE)">
            <div>{{ "COMMON.SORT_BY.NEWEST" | translate }}</div>
          </div>
          <div id="reports_m_search-by-slrn-asc" (click)="sortBy(sortByOptions.SLRN_ASC)">
            <div>{{ "COMMON.SLRN" | translate }}</div>
            <img
              class="arrow-up"
              src="../../../assets/icons/arrow.svg"
              alt="Arrow icon"
            />
          </div>
          <div id="reports_m_search-by-slrn-desc" (click)="sortBy(sortByOptions.SLRN_DESC)">
            <div>{{ "COMMON.SLRN" | translate }}</div>
            <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
          </div>
          <div id="reports_m_search-by-acc-no-asc" (click)="sortBy(sortByOptions.ACCNO_ASC)">
            <div>{{ "COMMON.SORT_BY.ACCOUNT_NO" | translate }}</div>
            <img
              class="arrow-up"
              src="../../../assets/icons/arrow.svg"
              alt="Arrow icon"
            />
          </div>
          <div id="reports_m_search-by-last-acc-no-desc" (click)="sortBy(sortByOptions.ACCNO_DESC)">
            <div>{{ "COMMON.SORT_BY.ACCOUNT_NO" | translate }}</div>
            <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
          </div>
        </div>
      </div>
      <button class="btn btn-icon" (click)="download()">
        <span>{{ "COMMON.DOWNLOAD" | translate }}</span>
      </button>
    </div>
  </div>
  <div class="filter">
    <div class="filter-list">
      {{ "COMMON.FILTER" | translate }}
      <app-multi-select-dropdown
        id="reports_m_region_filter"
        class="multiselect"
        [clicked]="clicked"
        [items]="regions"
        [label]="buName + 's'"
        [showMore]="false"
        [requireApply]="true"
        (shareIndividualCheckedList)="selectRegion($event)"
        (applyFilterEmitter)="reloadTable(1)"
      >
      </app-multi-select-dropdown>
      <app-multi-select-dropdown
        id="reports_m_area_filter"
        class="multiselect"
        [clicked]="clicked"
        [items]="areas"
        [label]="utName + 's'"
        [showMore]="false"
        [requireApply]="true"
        (shareIndividualCheckedList)="selectArea($event)"
        (applyFilterEmitter)="reloadTable(1)"
      >
      </app-multi-select-dropdown>
      <select
        id="reports_m_tariff_filter"
        name="tariff-select"
        (change)="selectTariff($event)"
        class="form-select"
      >
        <option value="0" [selected]="tariff == 0" disabled>
          {{ "COMMON.TARIFF.SINGLE" | translate }}
        </option>
        <option
          [id]="'reports_m_tariff_filter-' + (i + 1)"
          [selected]="tariff == o.id"
          *ngFor="let o of tariffs; let i = index"
          value="{{ o.id }}"
        >
          {{ o.name }}
        </option>
      </select>

      <select
        id="reports_m_service-type_filter"
        name="st-select"
        (change)="selectServiceType($event)"
        class="form-select"
      >
        <option value="" [selected]="!serviceType" disabled>
          {{ "COMMON.SERVICE_TYPE.TITLE" | translate }}
        </option>
        <option
          [id]="'reports_m_service-type_filter-' + (i + 1)" [selected]="serviceType == o.value"
          *ngFor="let o of serviceTypeOptions; let i = index"
          value="{{ o.value }}"
        >
          {{ o.name }}
        </option>
      </select>

      <select
        id="reports_m_status_filter"
        name="status-select"
        (change)="selectStatus($event)"
        class="form-select"
      >
        <option value="" [selected]="!status" disabled>
          {{ "COMMON.FILTER_BY_OPTIONS.STATUS" | translate }}
        </option>
        <option
          [id]="'reports_m_status_filter-' + (i + 1)"
          [selected]="status == s.value"
          *ngFor="let s of statusOptions; let i = index"
          value="{{ s.value }}"
        >
          {{ s.name }}
        </option>
      </select>

      <select
        id="reports_m_tag_filter"
        name="tag-select"
        (change)="selectTag($event)"
        class="form-select"
      >
        <option value="" [selected]="!tag" disabled>
          {{ "COMMON.TAG.TITLE" | translate }}
        </option>
        <option
          [id]="'reports_m_tag_filter-' + (i + 1)"
          [selected]="tag == t.value"
          *ngFor="let t of tagOptions; let i = index"
          value="{{ t.value }}"
        >
          {{ t.name }}
        </option>
      </select>

      <select
        id="reports_m_slt-status_filter"
        name="slt-select"
        (change)="selectSltStatus($event)"
        class="form-select"
      >
        <option value="" [selected]="!sltStatus" disabled>
          {{ sltStatusName }}
        </option>
        <option
          [id]="'reports_m_slt-status_filter-' + (i + 1)" [selected]="sltStatus == s.key"
          *ngFor="let s of sltOptions;let i = index"
          value="{{ s.key }}"
        >
          {{ s.value }}
        </option>
      </select>

      <select
        id="reports_m_uop_filter"
        name="uop-select"
        (change)="selectUseOfPremises($event)"
        class="form-select"
      >
        <option value="" [selected]="!useOfPremises" disabled>
          {{ "COMMON.USE_OF_PREMISES" | translate }}
        </option>
        <option
          [id]="'reports_m_uop_filter-' + (i + 1)" [selected]="useOfPremises == u.value"
          *ngFor="let u of uopOptions; let i = index"
          value="{{ u.value }}"
        >
          {{ u.name }}
        </option>
      </select>
    </div>
    <div *ngIf="selectedFilters.length > 0">
      <app-filter-checklist
        [filters]="selectedFilters"
        (resetFilterEmitter)="resetFilter($event)"
        (removeFilterEmitter)="removeFilter($event)"
      >
      </app-filter-checklist>
    </div>
  </div>
  <div class="container-fluid customer-table-container" *ngIf="customers">
    <table class="table">
      <thead class="table-light">
        <tr>
          <th id="reports_m_table_header_checkbox">
            <input
              type="checkbox"
              class="form-check-input"
              [checked]="isAllCheckBoxChecked()"
              (change)="checkAllCheckBox($event)"
            />
          </th>
          <th id="reports_m_table_header_slrn">{{ "COMMON.SLRN" | translate }}</th>
          <th id="reports_m_table_header_building-owner">{{ "COMMON.TABLE_HEADERS.BUILDING_OWNER" | translate }}</th>
          <th id="reports_m_table_header_acc-no">{{ "COMMON.TABLE_HEADERS.ACCT_NO" | translate }}</th>
          <th id="reports_m_table_header_meter-no">{{ "COMMON.TABLE_HEADERS.METER_NO" | translate }}</th>
          <th id="reports_m_table_header_dt">{{ "COMMON.DT_NAME" | translate }}</th>
          <th id="reports_m_table_header_address">{{ "COMMON.ADDRESS" | translate }}</th>
          <th id="reports_m_table_header_region">{{ "COMMON.BU.SINGLE" | translate }}</th>
          <th id="reports_m_table_header_district">{{ "COMMON.UT.SINGLE" | translate }}</th>
          <th id="reports_m_table_header_tariff">{{ "COMMON.TARIFF.SINGLE" | translate }}</th>
          <th id="reports_m_table_header_status">{{ "COMMON.STATUS" | translate }}</th>
          <th id="reports_m_table_header_tag">{{ "COMMON.TAG.TITLE" | translate }}</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          [id]="'reports_m_table_row-' + (i + 1)" *ngFor="let customer of customers; let i = index"
          (click)="selectCustomer(customer.id)"
        >
          <td [id]="'reports_m_table_checkbox-' + (i + 1)">
            <input
              type="checkbox"
              (click)="setFilterStorage()"
              class="form-check-input"
              value="{{ customer.id }}"
            />
          </td>
          <td [id]="'reports_m_table_slrn-' + (i + 1)" (click)="setFilterStorage()">{{ customer.slrn | hasValue }}</td>
          <td [id]="'reports_m_table_building-owner-' + (i + 1)" (click)="setFilterStorage()">
            {{ customer.buildingOwner | hasValue }}
          </td>
          <td [id]="'reports_m_table_acc-no-' + (i + 1)" (click)="setFilterStorage()">
            {{ customer.accountNumber | hasValue }}
          </td>
          <td [id]="'reports_m_table_meter-number-' + (i + 1)" (click)="setFilterStorage()">
            {{ customer.meterNo | hasValue }}
          </td>
          <td [id]="'reports_m_table_dt-' + (i + 1)" (click)="setFilterStorage()">{{ customer.dtName | hasValue }}</td>
          <td [id]="'reports_m_table_address-' + (i + 1)" (click)="setFilterStorage()">
            {{ customer.physicalAddress | hasValue }}
          </td>
          <td [id]="'reports_m_table_region-' + (i + 1)" (click)="setFilterStorage()">
            {{ customer.area.regionName | hasValue }}
          </td>
          <td [id]="'reports_m_table_district-' + (i + 1)" (click)="setFilterStorage()">
            {{ customer.area.name | hasValue }}
          </td>
          <td [id]="'reports_m_table_tariff-' + (i + 1)" (click)="setFilterStorage()">
            {{ customer.tariffName | hasValue }}
          </td>
          <td [id]="'reports_m_table_status-' + (i + 1)">
            <div
              *ngIf="!customer.dqStatus"
              class="building-status pending-building"
            >
              {{ "COMMON.ASSET_STATUS.PENDING" | translate }}
            </div>
            <div
              *ngIf="
                customer.dqStatus && customer.dqStatus?.toString() == 'Approved'
              "
              class="building-status complete-building"
            >
              {{ "COMMON.ASSET_STATUS.COMPLETE" | translate }}
            </div>
            <div
              *ngIf="
                customer.dqStatus && customer.dqStatus?.toString() == 'Rejected'
              "
              class="building-status rejected-building"
            >
              {{ "COMMON.ASSET_STATUS.REJECTED" | translate }}
            </div>
          </td>
          <td [id]="'reports_m_table_tag-' + (i + 1)">
            <div *ngIf="!customer.isOnBoard">
              <div *ngIf="!customer.isNew" class="tag-customer">
                {{ "COMMON.TAG.EXISTING" | translate }}
              </div>
              <div *ngIf="customer.isNew" class="tag-new-customer">
                {{ "COMMON.TAG.NEW_ENTRY" | translate }}
              </div>
            </div>
            <div *ngIf="customer.isOnBoard">
              <div class="tag-onboard-customer">
                {{ "COMMON.TAG.ONBOARD" | translate }}
              </div>
            </div>
          </td>
          <td class="td-svg" click-stop-propagation>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              data-bs-toggle="modal"
              data-bs-target="#history"
              (click)="viewHistory(customer.id)"
              width="22"
              height="22"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"
              />
            </svg>
          </td>
          <td (click)="setFilterStorage()" class="more-icon">
            <img
              src="../../assets/icons/more-icon.svg"
              alt="More icon"
              class="more-icon"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <app-paging
      *ngIf="count > 0"
      [pageSize]="pageSize"
      [useDirect]="true"
      [count]="count"
      [currentPage]="currentPage"
      (pageChange)="pageChange($event)"
    ></app-paging>
  </div>
</div>
<button
  class="btn btn-primary"
  #openUpdateDescriptionModal
  data-bs-toggle="modal"
  data-bs-target="#history"
  hidden
></button>

<app-history
  [isViewHistory]="isViewHistory"
  [historyItems]="historyItems"
></app-history>
