import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { AssetStatus, CustomerType } from 'src/app/models/customer.model';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';
import {
  MeterListElement,
  MeterType,
  SortByOptions,
} from 'src/app/models/meter.model';
import { Region } from 'src/app/models/region.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { AreaService } from 'src/app/services/area.service';
import { MeterService } from 'src/app/services/meter.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegionService } from 'src/app/services/region.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-meters-list',
  templateUrl: './meters-list.component.html',
  styleUrls: ['./meters-list.component.scss'],
})
export class MetersListComponent implements OnInit {
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');

  stats: KeyValueItem[] = [];
  public PermissionsEnum = PermissionsEnum;
  meters: MeterListElement[] = [];

  mapSortBy: Record<number, string> = {
    [SortByOptions.NEWEST]: 'COMMON.SORT_BY.NEWEST',
    [SortByOptions.SLRN_ASC]: 'COMMON.SORT_BY.SLRN_ASC',
    [SortByOptions.SLRN_DESC]: 'COMMON.SORT_BY.SLRN_DESC',
    [SortByOptions.NUMBER_ASC]: 'COMMON.SORT_BY.NUMBER_ASC',
    [SortByOptions.NUMBER_DESC]: 'COMMON.SORT_BY.NUMBER_DESC',
  };

  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;
  dateFrom: string;
  dateTo: string;
  searchText: string = '';
  searchTextUpdate = new Subject<string>();
  filterByValue: string = '';

  sortByValue: SortByOptions = SortByOptions.NEWEST;
  sortByOptions = SortByOptions;
  sortByName: string = this.mapSortBy[SortByOptions.NEWEST];

  showUpload: boolean = false;
  showSortBy: boolean = false;
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  clicked: boolean = false;
  selectedFilters: ChecklistItem[] = [];
  meterTypes: MeterType[] = [];
  public AssetStatus = AssetStatus;

  customerType?: number;
  customerTypeOptions = [
    { name: 'Prepaid', value: CustomerType.Prepaid, translation: 'COMMON.CUSTOMER_TYPE_OPTIONS.PREPAID' },
    { name: 'Postpaid', value: CustomerType.Postpaid, translation: 'COMMON.CUSTOMER_TYPE_OPTIONS.POSTPAID' },
  ];

  status?: number;
  statusOptions = [
    { name: 'Pending', value: AssetStatus.Pending, translation: 'COMMON.ASSET_STATUS.PENDING' },
    { name: 'Complete', value: AssetStatus.Complete, translation: 'COMMON.ASSET_STATUS.COMPLETE' },
    { name: 'Assigned', value: AssetStatus.Assigned, translation: 'COMMON.ASSET_STATUS.ASSIGNED' },
    { name: 'Rejected', value: AssetStatus.Rejected, translation: 'COMMON.ASSET_STATUS.REJECTED' },
    { name: 'Retagged', value: AssetStatus.Retagged, translation: 'COMMON.ASSET_STATUS.RETAGGED' },
  ];
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  tag?: number;
  tagOptions = [
    { name: 'Existing', value: 0, translation: 'COMMON.TAG.EXISTING' },
    { name: 'New entry', value: 1, translation: 'COMMON.TAG.NEW_ENTRY' },
  ];

  meterType?: number;
  allCheckboxesChecked: boolean = false;
  dateTypes = [
    { name: 'LastUpdateTime', value: 'Last Update Time', translation: 'COMMON.DATE_TYPE.LAST_UPDATE' },
    { name: 'TaggedDate', value: 'Tagged Date', translation: 'COMMON.DATE_TYPE.TAGGED' },
    { name: 'ValidatedDate', value: 'Validated Date', translation: 'COMMON.DATE_TYPE.VALIDATED' },
  ];
  searchByOptions = [
    { name: 'MeterNumber', value: 'Meter No.', translation: 'CUSTOMERS.SEARCH_BY.METER_NO' },
    { name: 'AccountNumber', value: 'Account No.', translation: 'CUSTOMERS.SEARCH_BY.ACCOUNT_NO' },
    { name: 'Slrn', value: 'SLRN' , translation: 'CUSTOMERS.SEARCH_BY.SLRN' },
  ];
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;
  selectedSearchType: string;
  selectedSearchTypeDisplay: string;
  showSelectSearchType: boolean = false;
  searchFilterApplied: boolean = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private meterService: MeterService,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private regionService: RegionService,
    private areaService: AreaService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.setFilters();

    this.getData();
    this.subscribeToClickEvent();
    this.getAreas();
    this.getRegions();
    this.getMeterTypesForOptions();

    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.searchText == '') this.search();
    });
    this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay =
      this.selectedDateTypeDisplay ?? this.dateTypes[0].translation;

    this.selectedSearchType =
    this.selectedSearchType ?? this.searchByOptions[0].name;
    this.selectedSearchTypeDisplay =
      this.selectedSearchTypeDisplay ?? this.searchByOptions[0].translation;
  }

  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
  }
  private getData() {
    this.getStats();
    this.reloadTable();
  }
  setFilters() {
    var filter = localStorage.getItem('filterMeter');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Meter') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.sortByValue = filterObject.filter.filterParams.sortBy;
    this.searchText = filterObject.filter.filterParams.search;
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.selectedDateType = filterObject.filter.filterParams.dateType;
    this.selectedSearchType = filterObject.filter.filterParams.searchType;
    this.selectedDateTypeDisplay =
      this.dateTypes.find(
        (type) => type.name == filterObject.filter.filterParams.dateType
      )?.translation ?? this.dateTypes[0].translation;
    this.selectedSearchTypeDisplay =
      this.searchByOptions.find(
        (type) => type.name == filterObject.filter.filterParams.searchType
      )?.translation ?? this.searchByOptions[0].translation;
    this.meterType = filterObject.filter.filterParams.meterType
      ? filterObject.filter.filterParams.meterType
      : null;
    this.customerType = filterObject.filter.filterParams.customerType
      ? filterObject.filter.filterParams.customerType
      : null;
    this.status = filterObject.filter.filterParams.status
      ? filterObject.filter.filterParams.status
      : null;
    this.tag =
      filterObject.filter.filterParams.tag != null
        ? filterObject.filter.filterParams.tag
          ? 1
          : 0
        : undefined;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
    this.sortByName = this.sortByOptions[this.sortByValue];
    this.filterByValue = filterObject.filter.filterParams.filterBy;
    localStorage.removeItem('filterMeter');
  }
  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject(),
    };

    // Remove 'T23:59:59' from the dateTo to avoid errors
    if (obj.filterParams.dateTo) {
        obj.filterParams.dateTo = obj.filterParams.dateTo.replace('T23:59:59', '');
    }

    localStorage.setItem(
      'filterMeter',
      JSON.stringify({ type: 'Meter', filter: obj })
    );
  }

  onFilterBy(option: any) {
    this.filterByValue = option;
    this.reloadTable(1);
  }

  private getStats() {
    this.meterService.getMeterStats().subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats = data.data;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getFilterObject() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      dateType: this.selectedDateType,
      searchType: this.selectedSearchType,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
      status: this.status ? this.status : null,
      tag: this.tag ? (this.tag == 0 ? false : true) : null,
      customerType: this.customerType ? this.customerType : null,
      meterType: this.meterType ? +this.meterType : null,
      selectedIds: this.meters
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
      filterBy: this.filterByValue,
    };

    return obj;
  }

  reloadTable(page: any = null) {
    this.allCheckboxesChecked = false;
    if (page) this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject(),
    };

    
    this.getAll(obj);
  }

  private getAll(obj: any) {
    obj.isCrud = false;
    this.meterService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        console.log(responseData);
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.meters = responseData.data.data;
          this.count = responseData.data.count ?? this.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  checkAllCheckboxes() {
    this.meters.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }

  checkMeter(id: number) {
    const meter = this.meters.find((x) => x.id === id);
    if (meter) {
      meter.isChecked = !meter.isChecked;
      this.areAllCheckboxesChecked();
    }
    console.log(this.meters);
  }

  areAllCheckboxesChecked() {
    this.allCheckboxesChecked = this.meters.every((x) => x.isChecked);
  }

  search() {
    this.searchFilterApplied = this.searchText ? true : false;
    this.reloadTable(1);
  }

  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByName = this.mapSortBy[option];
    this.reloadTable();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showSortBy = false;
        this.showSelectDateType = false;
        this.showSelectSearchType = false;
      });
  }

  download() {
    let obj = this.getFilterObject();

  
    this.meterService.download(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  datesValid() {
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
        const valid: boolean = new Date(this.dateFrom).getTime() < new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
        return valid;
    }
    return true;
  }

  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.createFilterCards();
          this.removeFilterStorage();
          // this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });

    this.createFilterCards();
  }

  selectArea(e: any) {
    this.createFilterCards();
  }

  createFilterCards() {
    this.selectedFilters = [];

    let selectedAreas = this.areas.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name, 'COMMON.UT.SINGLE')
      );
    }

    if (this.meterType) {
      var meterTypeName = this.meterTypes.filter(
        (x) => x.id == this.meterType
      )[0].name;
      this.selectedFilters.push(new ChecklistItem('Meter type', meterTypeName, 'COMMON.MBC_DETAILS.METER_TYPE.SINGLE'));
    }

    //service type
    if (this.customerType) {
      var customerTypeName = CustomerType[this.customerType!];
      this.selectedFilters.push(
        new ChecklistItem('Customer type', customerTypeName, 'COMMON.MBC_DETAILS.CUSTOMER_TYPE')
      );
    }

    //status
    if (this.status) {
      var status = this.statusOptions.filter(option => option.value == this.status)[0];
      this.selectedFilters.push(new ChecklistItem('Status', status.name, 'COMMON.STATUS', status.translation));
    }

    //tag
    if (this.tag) {
      var tag = this.tagOptions[this.tag];
      this.selectedFilters.push(new ChecklistItem('Tag', tag.name, 'COMMON.TAG.TITLE', tag.translation));
    }
  }

  getMeterTypesForOptions() {
    this.meterService.getAllMeterTypesForSelect().subscribe(
      (response) => {
        this.meterTypes = response.body?.data ? response.body?.data : [];
        this.createFilterCards();
        this.removeFilterStorage();
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  resetFilter(e: any) {
    this.selectedFilters = [];

    this.regions.forEach((region) => {
      region.checked = false;
    });

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];
    this.selectedAreas = [];
    this.selectedRegions = [];
    this.meterType = undefined;
    this.customerType = undefined;
    this.status = undefined;
    this.tag = undefined;

    this.clicked = !this.clicked;

    this.reloadTable(1);
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Customer type') {
      this.customerType = undefined;
    } else if (item.property == 'Meter type') {
      this.meterType = undefined;
    } else if (item.property == 'Status') {
      this.status = undefined;
    } else if (item.property == 'Tag') {
      this.tag = undefined;
    }
    this.createFilterCards();

    if (this.selectedFilters.length == 0) {
      this.regions.forEach((region) => {
        region.checked = false;
      });
      this.selectedRegions = [];
      this.reloadTable(1);
    }
  }

  selectCustomerType(e: any) {
    if (e.target.value != '') {
      this.customerType = e.target.value;
    } else {
      this.customerType = undefined;
    }
    this.createFilterCards();
  }

  selectMeterType(e: any) {
    if (e.target.value != '') {
      this.meterType = e.target.value;
    } else {
      this.meterType = undefined;
    }
    this.createFilterCards();
  }

  selectStatus(e: any) {
    if (e.target.value != '') {
      this.status = e.target.value;
    } else {
      this.status = undefined;
    }
    this.createFilterCards();
  }

  selectTag(e: any) {
    if (e.target.value != '') {
      this.tag = e.target.value;
    } else {
      this.tag = undefined;
    }
    this.createFilterCards();
  }
  setCheckedAreas() {
    var filter = localStorage.getItem('filterMeter');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Meter') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  setCheckedRegions() {
    var filter = localStorage.getItem('filterMeter');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Meter') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  removeFilterStorage() {
    if (
      this.areas.length > 0 &&
      this.regions.length > 0 &&
      this.meterTypes.length > 0
    )
      localStorage.removeItem('filterMeter');
  }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.translation;
    this.showSelectDateType = false;
  }

  selectSearchType(searchType: any) {
    if (this.searchText && this.searchFilterApplied) {
      this.searchText = '';
      this.search();
    }
    this.selectedSearchType = searchType.name;
    this.selectedSearchTypeDisplay = searchType.translation;
    this.showSelectSearchType = false;
  }
  
  applyFilter() {
    this.reloadTable();
  }
}
