<div class="container-fluid regular-tab-content">
  <div class="green-table">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div id="reports_dqr_ltpole_search" class="search-input">
        <img
          src="../../assets/icons/search.svg"
          alt="Search icon"
          (click)="search()"
        />
        <input
          class="smaller-font"
          type="text"
          [placeholder]="'COMMON.SEARCH' | translate"
          [(ngModel)]="searchText"
          (keydown.enter)="search()"
          (ngModelChange)="this.searchTextUpdate.next($event)"
        />
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div id="reports_dqr_ltpole_date-type" class="datetype-picker">
              <div
                class="dropdown date-type-dropdown smaller-font"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span class="smaller-font">{{ "COMMON.DATE_TYPE.TITLE" | translate }}:</span>
                <span class="smaller-font" style="width: 50%">{{ selectedDateTypeDisplay | translate }}</span>
                <i class="arrow-custom --small down"></i>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  [id]="'reports_dqr_ltpole_date-type-' + (i + 1)"
                  (click)="
                    selectDateType(dateType);
                    datesValid() == true && (dateTo || dateFrom) && search()
                  "
                  *ngFor="let dateType of dateTypes; let i = index"
                >
                  <div>{{ dateType.translation | translate }}</div>
                </div>
              </div>
            </div>
            <div id="reports_dqr_ltpole_date-from" class="datepicker-box space-right smaller-font">
              <label class="smaller-font" for="dateFrom"> {{ "COMMON.FROM" | translate }}: </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  dateFrom > dateTo
                    ? 'datepicker-input-error'
                    : 'datepicker-input'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>

            <div id="reports_dqr_ltpole_date-to" class="datepicker-box space-right smaller-font">
              <label class="smaller-font" for="dateTo" class="right-label">
                {{ "COMMON.TO" | translate }}:
              </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  dateFrom > dateTo
                    ? 'datepicker-input-error'
                    : 'datepicker-input'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>
          </div>
        </div>
        <div
          id="reports_dqr_ltpole_sort-by"
          class="sort-by-root"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown">
            <span class="smaller-font">{{ "COMMON.SORT_BY.TITLE" | translate }}:</span>
            <span class="smaller-font" style="width: 40%;">{{ sortByName | translate }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" style="width: 115%;" *ngIf="showSortBy">
            <div id="reports_dqr_ltpole_sort-by-newest" (click)="sortBy(sortByOptions.NEWEST)">
              {{ "COMMON.SORT_BY.NEWEST" | translate }}
            </div>
            <div id="reports_dqr_ltpole_sort-by-slrn-asc" (click)="sortBy(sortByOptions.SLRN_ASC)">
              {{ "COMMON.SORT_BY.SLRN_ASC" | translate }}
            </div>
            <div id="reports_dqr_ltpole_sort-by-slrn-desc" (click)="sortBy(sortByOptions.SLRN_DESC)">
              {{ "COMMON.SORT_BY.SLRN_DESC" | translate }}
            </div>
            <div id="reports_dqr_ltpole_sort-by-id-asc" (click)="sortBy(sortByOptions.ID_ASC)">
              {{ "COMMON.SORT_BY.ID_ASC" | translate }}.
            </div>
            <div id="reports_dqr_ltpole_sort-by-id-desc" (click)="sortBy(sortByOptions.ID_DESC)">
              {{ "COMMON.SORT_BY.ID_DESC" | translate }}.
            </div>
          </div>
        </div>
        <button class="button primary--white four-radius" (click)="download()">
          <span class="smaller-font">{{ "COMMON.DOWNLOAD" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list smaller-font">
        <span class="label">{{ "COMMON.FILTER" | translate }}</span>
        <app-multi-select-dropdown
          id="reports_dqr_ltpole_region_filter"
          class="multiselect"
          [clicked]="clicked"
          [items]="regions"
          [label]="'COMMON.BU.PLURAL'"
          [borderRadius]="'0.4rem'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          id="reports_dqr_ltpole_area_filter" 
          class="multiselect"
          [clicked]="clicked"
          [items]="areas"
          [label]="'COMMON.UT.PLURAL'"
          [borderRadius]="'0.4rem'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
        >
        </app-multi-select-dropdown>

        <select
          id="reports_dqr_ltpole_user-type_filter"
          name="st-select"
          (change)="selectUserType($event)"
          class="form-select"
        >
          <option value="" [selected]="!userType" disabled>
            {{ "COMMON.USER.TYPE.TITLE" | translate }}
          </option>
          <option
            [id]="'reports_dqr_ltpole_user-type_filter-' + (i + 1)" [selected]="userType == o.value"
            *ngFor="let o of userTypeOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>

        <app-multi-select-dropdown
          id="reports_dqr_ltpole_user_filter"
          class="multiselect"
          [searchFilterActive]="true"
          [borderRadius]="'0.4rem'"
          (filterValue)="applySearchFilter($event)"
          [clicked]="clicked"
          [items]="filteredUsers"
          [label]="'COMMON.USER.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectUsers($event)"
        >
        </app-multi-select-dropdown>

        <select
          id="reports_dqr_ltpole_pole-type_filter"
          name="pole-type"
          (change)="selectPoleType($event)"
          class="form-select"
        >
          <option value="0" [selected]="poleType == 0" disabled>
            {{ "COMMON.TABLE_HEADERS.POLE_TYPE" | translate }}
          </option>
          <option
            [id]="'reports_dqr_ltpole_pole-type_filter-' + (i + 1)"  [selected]="poleType == o.key"
            *ngFor="let o of poleTypesOptions; let i = index"
            value="{{ o.key }}"
          >
            {{ o.value | translate }}
          </option>
        </select>
        <select
          id="reports_dqr_ltpole_pole-condition_filter"
          name="pole-condition"
          (change)="selectPoleCondition($event)"
          class="form-select"
        >
          <option value="0" [selected]="poleCondition == 0" disabled>
            {{ "COMMON.POLE.CONDITION.TITLE" | translate }}
          </option>
          <option
            [id]="'reports_dqr_ltpole_pole-condition_filter-' + (i + 1)"  [selected]="poleCondition == o.value"
            *ngFor="let o of poleConditionOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
      </div>

      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist [filters]="selectedFilters" (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)" [useApply]="true" (applyFilterEmitter)="applyFilter($event)">
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="poles">
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th id="reports_dqr_ltpole_table_header_checkbox" class="checkbox">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th id="reports_dqr_ltpole_table_header_slrn">{{ "COMMON.TABLE_HEADERS.LT_POLE_SLRN" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_id">{{ "POLES.EDIT.LT_ID" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_number">{{ "COMMON.TABLE_HEADERS.LT_POLE_NUM" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_building-slrn" class="slrn-header">
                {{ "COMMON.TABLE_HEADERS.ASSOC_BUILDING_SLRN" | translate }}
              </th>
              <th id="reports_dqr_ltpole_table_header_uprises-number">{{ "COMMON.TABLE_HEADERS.UPRISER_NUM" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_pole-type">{{ "COMMON.TABLE_HEADERS.POLE_TYPE" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_feeder">{{ "COMMON.MBC_DETAILS.FEEDER_NAME" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_name">{{ "COMMON.DT_NAME" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_region">{{ "COMMON.BU.SINGLE" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_district">{{ "COMMON.UT.SINGLE" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_address">{{ "COMMON.ADDRESS" | translate }}</th>
              <th id="reports_dqr_ltpole_table_header_validation-status">{{ "COMMON.VALIDATION_STATUS" | translate }}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              [id]="'reports_dqr_ltpole_table_row-' + ( i + 1)"  *ngFor="let pole of poles; let i = index"
              (click)="selectDataQuality(pole.id)"
            >
              <td [id]="'reports_dqr_ltpole_table_checkbox-' + (i + 1)">
                <input
                  type="checkbox"
                  class="form-check-input"
                  value="{{ pole.id }}"
                  [ngModel]="pole.isChecked"
                  (click)="checkPole(pole.id)"
                />
              </td>
              <td [id]="'reports_dqr_ltpole_table_slrn-' + (i + 1)" (click)="setFilterStorage()">
                {{ pole?.slrn | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_id-' + (i + 1)" (click)="setFilterStorage()">
                {{ pole.poleId | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_number-' + (i + 1)" (click)="setFilterStorage()">
                {{ pole.poleNumber | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_building-slrn-' + (i + 1)" class="slrn-row">
                <span (click)="setFilterStorage()">
                  {{ formatSlrn(pole.buildingsSLRN) | hasValue }}
                </span>

                <span
                  (click)="pole.showDropdown = !pole.showDropdown"
                  *ngIf="pole.buildingsSLRNList.length > 2"
                  class="slrn-show-more"
                >
                  +{{ pole.buildingsSLRNList.length - 2 }}
                  {{ "COMMON.MORE" | translate }}
                  <span class="arrow"
                    ><svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.29104 1.70743C9.92144 1.07759 9.47537 0 8.58425 0H1.41268C0.521988 0 0.0757232 1.07669 0.705251 1.70679L4.28781 5.29257C4.67815 5.68327 5.31132 5.68356 5.70202 5.29321L9.29104 1.70743Z"
                        fill="white"
                        fill-opacity="1"
                      />
                    </svg>
                  </span>
                </span>
                <div
                  *ngIf="pole.showDropdown"
                  class="slrn-info"
                  [ngClass]="{
                    'slrn-info-bigger': pole.buildingsSLRNList.length > 4
                  }"
                >
                  <div class="slrn-dropdown">
                    <li *ngFor="let slrn of pole.buildingsSLRNList">
                      {{ slrn }}
                    </li>
                  </div>
                </div>
              </td>
              <td [id]="'reports_dqr_ltpole_table_upriser-number-' + (i + 1)" (click)="setFilterStorage()">
                {{ pole.upriserNumber | number | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_pole-type-' + (i + 1)" (click)="setFilterStorage()">
                {{ pole.poleType | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_feeder-' + (i + 1)" (click)="setFilterStorage()">
                {{ pole.feederName | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_dt' + (i + 1)" (click)="setFilterStorage()">
                {{ pole.dtName | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_region-' + (i + 1)" (click)="setFilterStorage()">
                {{ pole.region | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_district-' + (i + 1)" (click)="setFilterStorage()">
                {{ pole.area | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_address-' + (i + 1)" (click)="setFilterStorage()">
                {{ pole.address | hasValue }}
              </td>
              <td [id]="'reports_dqr_ltpole_table_validation-status-' + (i + 1)" (click)="setFilterStorage()">
                <div
                  *ngIf="pole.status"
                  class="building-status"
                  [ngClass]="{
                    'complete-building': pole.status.toString() == 'Complete',
                    'incomplete-building':
                      pole.status.toString() == 'Incomplete',
                    'pending-building': pole.status.toString() == 'Pending',
                    'assigned-building':
                      pole.status.toString() == 'Assigned' ||
                      pole.status.toString() == 'Retagged',
                    'dark-red-status v-2': pole.status.toString() == 'Rejected'
                  }"
                >
                  {{ pole.status.toString() }}
                </div>
                <div *ngIf="!pole.status" class="building-status">--------</div>
              </td>
              <td class="td-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-bs-toggle="modal"
                  data-bs-target="#history"
                  (click)="viewHistory(pole.id)"
                  width="22"
                  height="22"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"
                  />
                </svg>
              </td>
              <td class="more-icon" (click)="setFilterStorage()" s>
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging
            *ngIf="count > 0"
            [pageSize]="pageSize"
            [useDirect]="true"
            [count]="count"
            [currentPage]="currentPage"
            (pageChange)="pageChange($event)"
          ></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>
<button
  class="btn btn-primary"
  #openUpdateDescriptionModal
  data-bs-toggle="modal"
  data-bs-target="#history"
  hidden
></button>

<app-history
  [isViewHistory]="isViewHistory"
  [historyItems]="historyItems"
></app-history>
