import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subject } from 'rxjs';
import { Area, Itinerary, ItineraryPart } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { SortByOptions } from 'src/app/models/customer.model';
import { MeterReadingsList, MeterReadingsStats } from 'src/app/models/meterReadings.model';
import { Region } from 'src/app/models/region.model';
import { AreaService } from 'src/app/services/area.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { RegionService } from 'src/app/services/region.service';
import { TariffService } from 'src/app/services/tariff.service';
import { MeterReadingService } from 'src/app/services/meter-readings.service';
import { TranslationService } from 'src/app/services/translation.service';
import { OptionObj } from 'src/app/models/util.model';
import { CustomerService } from 'src/app/services/customer.service';
import { BillingAccountService } from 'src/app/services/billing-account.service';
import { ReadingsMenuTab } from 'src/app/models/readingsMenuTab.model';

@Component({
  selector: 'app-meter-readings-list',
  templateUrl: './meter-readings-list.component.html',
  styleUrls: ['./meter-readings-list.component.scss']
})
export class MeterReadingsListComponent implements OnInit, OnChanges {

  @Input() readingSelected: ReadingsMenuTab;
  public ReadingsMenuTab = ReadingsMenuTab;

  dateFrom: string;
  dateTo: string;
  mapSortBy: Record<number, any> = {
    [SortByOptions.NEWEST]: { label: 'Newest', arrow: 'no' },
    [SortByOptions.OLDEST]: { label: 'Oldest', arrow: 'no' },
    [SortByOptions.PRIORITY_DESC]: { label: 'Priority_Desc', arrow: 'no' },
    [SortByOptions.PRIORITY_ASC]: { label: 'Priority_Asc', arrow: 'no' },
  };
  sortByValue: SortByOptions = SortByOptions.NEWEST;

  sltStatus?: number;
  sltOptions: OptionObj[] = [];
  sltStatusName: string =
    this.translationService.getByKeyFromStorage('SltStatus');

  showSortBy: boolean = false;
  readingsTabOpened: boolean = false;
  assignedTabOpened: boolean = false;

  stats: MeterReadingsStats = {};
  sortByOptions = SortByOptions;
  sortByLabelValue: any = this.mapSortBy[SortByOptions.NEWEST];
  pageSize: number = 5;
  currentPage: number = 1;
  currentTab: number = 1;
  searchText: string = '';
  searchTextUpdate = new Subject<string>();
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  clicked: boolean = false;
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  blocks: Itinerary[] = [];
  rounds: Itinerary[] = [];
  plots: Itinerary[] = [];
  selectedFilters: ChecklistItem[] = [];
  meterReadings: MeterReadingsList[] = [];
  count: number = 0;
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];

  constructor(
    private toastr: ToastrService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private regionService: RegionService,
    private areaService: AreaService,
    public authService: AuthService,
    private billingAccountService: BillingAccountService,
    private route: ActivatedRoute,
    private MeterReadingService: MeterReadingService,
    private customerService: CustomerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getRegions();
    this.getAreas();
    this.getData();    
    this.getSltStatuses();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.searchText == '') this.search();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['readingSelected']) {
      if(this.readingSelected === ReadingsMenuTab.MeterReadings){
        this.changeTab(1);
        if(!this.readingsTabOpened){
          for(let i = 1; i <= 5; i++){
            this.getStats(i);
          }
        }
        this.readingsTabOpened = !this.readingsTabOpened;
      }else if(this.readingSelected === ReadingsMenuTab.AssignedReadings){
        this.changeTab(0);
        if(!this.assignedTabOpened)
        this.getStats(0);
        this.assignedTabOpened = !this.assignedTabOpened;
      }
    }
  }


  private getData(): void {
    this.getSiteVerificationStats();
    this.reloadTable();
  }

  getSltStatuses() {
    this.customerService.getSltStatusesForOptions().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.sltOptions = responseData.data;
          this.createFilterCards();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectSltStatus(e: any) {
    if (e.target.value != '') {
      this.sltStatus = parseInt(e.target.value);
    } else {
      this.sltStatus = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  getSiteVerificationStats() { }
  changeTab(page: number) {
    this.currentTab = page;
    this.searchText = '';
    this.resetFilter(1);
    this.reloadTable();
  }

  search() {
    this.reloadTable(1);
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  setFilterStorage() { }
  datesValid() {
    if (this.dateFrom && this.dateTo) {
      const valid: boolean = new Date(this.dateFrom).getTime() < new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }
    return false;
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter(area => this.regions.some(region => region.id == area.regionId && region.checked));
    this.blocks = this.blocks.filter(block => this.areas.some(area => area.id == block.parentId && area.checked));
    this.rounds = this.rounds.filter(round => this.blocks.some(block => block.name == round.parentId.toString() && block.checked));
    this.plots = this.plots.filter(plot => this.rounds.some(round => round.name == plot.parentId.toString() && round.checked));
    this.allAreas.forEach(area => {
      if (!this.areas.some(a => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });

    this.createFilterCards();
  }

  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.createFilterCards();
          // this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.createFilterCards();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  reloadTable(page: any = null) {
    if (page) this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count
      },
      filterParams: this.getFilterObject()
    };
    this.getAll(obj);
  }

  selectArea(e: any) {
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);

    this.billingAccountService.getItineraryData(
      {
        "itineraryPart": ItineraryPart.Block,
        "parentIds": this.selectedAreas.map(num => num.toString())
      }
    ).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          let previeusSelected = this.blocks.filter(block => block.checked);
          this.blocks = responseData.data.map((name: string) => new Itinerary({ name, type: ItineraryPart.Block, parentId: e.id }));
          this.blocks.forEach(block => {
            if (previeusSelected.some(b => b.name === block.name)) {
              block.checked = true;
            }
          });
          this.createFilterCards();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectBlock(e: any) {
    this.billingAccountService.getItineraryData(
      {
        "itineraryPart": ItineraryPart.Round,
        "parentIds": this.blocks.filter(block => block.checked).map(block => block.name)
      }
    ).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          let previeusSelected = this.rounds.filter(round => round.checked);
          
          this.rounds = responseData.data.map((name: string) => new Itinerary({ name, type: ItineraryPart.Round, parentId: e.name }));
          
          this.rounds.forEach(round => {
            if (previeusSelected.some(b => b.name === round.name)) {
              round.checked = true;
            }
          });
          this.createFilterCards();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectRound(e: any){
    this.billingAccountService.getItineraryData(
      {
        "itineraryPart": ItineraryPart.Plot,
        "parentIds": this.rounds.filter(round => round.checked).map(round => round.name)
      }
    ).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          let previeusSelected = this.plots.filter(plot => plot.checked);
          
          this.plots = responseData.data.map((name: string) => new Itinerary({ name, type: ItineraryPart.Plot, parentId: e.name }));
          
          this.plots.forEach(plot => {
            if (previeusSelected.some(b => b.name === plot.name)) {
              plot.checked = true;
            }
          });
          this.createFilterCards();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectPlot(e: any){
    this.createFilterCards();
  }

  resetFilter(e: any) {
    this.selectedFilters = [];
    this.regions.forEach((region) => {
      region.checked = false;
    });

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.blocks.forEach((block) => {
      block.checked = false;
    });
    this.rounds.forEach((round) => {
      round.checked = false;
    });
    this.plots.forEach((plot) => {
      plot.checked = false;
    });
    this.areas = [];
    this.blocks = [];
    this.rounds = [];
    this.plots = [];
    this.sltStatus = undefined;
    this.clicked = !this.clicked;
    this.selectedRegions = [];
    this.selectedAreas = [];
    this.reloadTable(1);
  }

  isAllCheckBoxChecked() {
    return false;
  }

  checkAllCheckBox(event: any) { }
  getFilterObject() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      status: this.currentTab,
      sltStatus: this.sltStatus ? this.sltStatus : null,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
      selectedBlocks: this.blocks.filter(block => block.checked).map(block => block.name),
      selectedRounds: this.rounds.filter(round => round.checked).map(round => round.name),
    };
    return obj;
  }
  private getStats(id: number) {
    this.MeterReadingService.getStats(id).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          switch (id) {
            case 0:
              this.stats.assigned = data.data.count;
              break;
            case 1:
              this.stats.submitted = data.data.count;
              break;
            case 2:
              this.stats.failed = data.data.count;
              break;
            case 3:
              this.stats.rejected = data.data.count;
              break;
            case 4:
              this.stats.inaccessible = data.data.count;
              break;
            case 5:
              this.stats.inoperableMeters = data.data.count;
              break;
            default:
              break;
          }

        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByLabelValue = this.mapSortBy[option];
    this.reloadTable();
  }

  private getAll(obj: any) {
    
    this.MeterReadingService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.meterReadings = responseData.data.data;
          this.count = responseData.data.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else if (response?.status == 204) {
          this.toastr.warning('No content');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      var area = this.areas.find(x => x.name === item.selectedValue);
      if (area){
        area.checked = false;
        this.selectedAreas = this.selectedAreas.filter(x => { return x !== area!.id});
      }
      this.blocks = [];
      this.rounds = [];
      this.plots = [];
      this.clicked = !this.clicked;
    } else if (item.property == 'Slt status') {
      this.sltStatus = undefined;
    } else if (item.property == 'Block') {
      this.blocks.forEach((block) => {
        if (block.name === item.selectedValue) {
          block.checked = false;
        }
        this.rounds = [];
        this.plots = [];
        this.clicked = !this.clicked;
      })
    } else if (item.property == 'Round') {
      this.rounds.forEach((round) => {
        if (round.name === item.selectedValue) {
          round.checked = false;
        }
        this.plots = [];
        this.clicked = !this.clicked;
      })
    } else if (item.property == 'Plot') {
      this.plots.forEach((plot) => {
        if (plot.name === item.selectedValue) {
          plot.checked = false;
        }
        this.clicked = !this.clicked;
      })
    }
    if (this.selectedFilters.length == 0) {
      this.selectedRegions = [];
      this.selectedAreas = [];
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  createFilterCards() {
    this.selectedFilters = [];

    let selectedAreas = this.areas.filter((x) => x.checked);
    let selectedRegions = this.regions.filter((x) => x.checked);
    let selectedBlocks = this.blocks.filter((x) => x.checked);
    let selectedRounds = this.rounds.filter((x) => x.checked);
    let selectedPlots = this.plots.filter((x) => x.checked);
    this.selectedRegions = this.regions.filter((x) => x.checked).map((x) => x.id);
    //regions
    for (var i = 0; i < selectedRegions.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.buName, selectedRegions[i].name)
      );
    }

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }

    //slt
    if (this.sltStatus) {
      var sltStatusOptionName = this.sltOptions.find(
        (x) => x.key == this.sltStatus
      )!.value;
      this.selectedFilters.push(
        new ChecklistItem(this.sltStatusName, sltStatusOptionName)
      );
    }

    //block
    for (var i = 0; i < selectedBlocks.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem('Block', selectedBlocks[i].name)
      );
    }

    //round
    for (var i = 0; i < selectedRounds.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem('Round', selectedRounds[i].name)
      );
    }

    //plot
    for (var i = 0; i < selectedPlots.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem('Plot', selectedPlots[i].name)
      );
    }
  }

  download() {
    let obj = this.getFilterObject();

    // this.analyticsService.logCustomEvent('TRANSFORMERS: Download', {
    //   sort_by: obj.sortBy,
    //   search: obj.search,
    //   filter_card: obj.filterBy,
    //   user_type: obj.userType,
    //   date_from: obj.dateFrom,
    //   date_to: obj.dateTo,
    //   users: obj.selectedUsers,
    //   date_type: obj.dateType,
    //   status: obj.status,
    //   kva_rating: obj.kvaRating,
    //   meter_status: obj.meterStatus,
    //   dt_status: obj.dtStatus,
    //   dt_type: obj.dtType,
    //   mount_position: obj.mountPosition,
    //   feeder_voltage: obj.feederVoltage,
    //   transmission_station: obj.transmissionStation,
    //   areas: obj.selectedAreas,
    //   regions: obj.selectedRegions,
    // });

    // this.dtService.download(obj).subscribe({
    //   next: (response) => {
    //     const data = response.body;
    //     if (data?.status === 'OK' || data?.status === '200') {
    //       window.location.href = data.data;
    //     } else if (data?.status === 'Pending' || data?.status === '600') {
    //       this.toastr.info(data?.message || '');
    //     } else this.toastr.error(data?.message || 'Request failed');
    //   },
    
    // });
  }

  selectReading(id: number) {
    this.router.navigate([`readings/reading-details/${id}`])
    this.setFilterStorage();
  }
}
