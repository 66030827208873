import {
  Component,
  Input,
  OnInit,
  SimpleChange,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NextDataQuality } from 'src/app/models/dataQuality.model';
import {
  AuditFailedReasons,
  PhoneNumberVerificationIssue,
} from 'src/app/models/customer.model';
@Component({
  selector: 'app-audit-review-customer',
  templateUrl: './audit-review-customer.component.html',
  styleUrls: ['./audit-review-customer.component.scss'],
})
export class AuditReviewCustomerComponent implements OnInit {
  @Input() assetType: string;
  @Input() assetId: number;
  @Input() fieldAuditDisabled: boolean = false;
  @Output() closeModal = new EventEmitter<NextDataQuality | null>();
  @ViewChild('closeAudit') auditReviewModal: ElementRef;

  phoneValidationSelected: boolean = false;
  phoneValidationAgreed: boolean = false;
  phoneValidationSuccessful?: boolean;
  auditRejected: boolean = false;
  otherSelected: boolean = false;
  otherReason?: string;
  phoneValidationErrors = [
    {
      name: 'PHONE_VALID_ERRORS.INACTIVE',
      value: PhoneNumberVerificationIssue.Inactive,
    },
    {
      name: 'PHONE_VALID_ERRORS.MISMATCH',
      value: PhoneNumberVerificationIssue.Mismatch,
    },
    {
      name: 'PHONE_VALID_ERRORS.DENIED_OWNERSHIP',
      value: PhoneNumberVerificationIssue.DeniedOwnership,
    },
    {
      name: 'PHONE_VALID_ERRORS.INVALID',
      value: PhoneNumberVerificationIssue.Invalid,
    },
  ];

  auditFailedReasons = this.getDefaultReasons();
  selectedValidationError?: number;
  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {}

  submitAudit() {
    this.analyticsService.logCustomEvent('CUSTOMER DETAILS: Field Audit', {
      customer_id: this.assetId,
    });
    var obj = {
      customerId: this.assetId,
      phoneNumberVerificationIssue: this.selectedValidationError,
      failureReasons: this.getSelectedAuditFailedReasons(),
    };

    if (!this.validatePhoneNoInputs()) return;

    this.customerService.auditCustomer(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('Successfully audited customer.');
          this.closeModal.emit(responseData.data);
          this.auditReviewModal?.nativeElement?.click();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured.');
      },
    });
  }

  cancel(event: Event) {
    this.resetChoices();
    this.auditReviewModal?.nativeElement?.click();
    this.closeModal.emit(null);
  }

  handleReturn() {
    var previous = localStorage.getItem('cameFrom');
    if (!previous) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return;
    }

    this.router.navigate(['/customers']);
  }

  handlePhoneVerificationSelection(event: Event, agreed: boolean) {
    event.preventDefault();
    event.stopPropagation();
    this.phoneValidationSelected = true;
    this.phoneValidationAgreed = agreed;
  }

  selectVerificationSuccess(event: Event) {
    const value = (event.target as HTMLSelectElement).value;

    if (value === '1') {
      this.phoneValidationSuccessful = true;
      this.selectedValidationError = undefined;
    } else if (value === '0') {
      this.phoneValidationSuccessful = false;
    } else {
      this.phoneValidationSuccessful = undefined;
    }
  }

  selectVerificationIssue(event: Event) {
    const value = (event.target as HTMLSelectElement).value;
    this.selectedValidationError = parseInt(value);
  }

  resetChoices() {
    this.phoneValidationSelected = false;
    this.phoneValidationAgreed = false;
    this.phoneValidationSuccessful = undefined;
    this.selectedValidationError = undefined;
    this.auditRejected = false;
    this.otherSelected = false;
    this.otherReason = undefined;
    this.auditFailedReasons = this.getDefaultReasons();
  }

  validatePhoneNoInputs() {
    if (
      this.phoneValidationSelected &&
      this.phoneValidationAgreed &&
      this.phoneValidationSuccessful == undefined
    ) {
      this.toastr.warning(
        'Please select whether the phone number validation was successful or not.'
      );

      return false;
    }
    if (
      this.phoneValidationSuccessful != undefined &&
      !this.phoneValidationSuccessful &&
      !this.selectedValidationError
    ) {
      this.toastr.warning(
        'Please select the reason for unsuccessful phone number validation'
      );
      return false;
    }

    return true;
  }

  rejectAudit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.auditRejected = true;
  }

  toggleReason(reasonValue: AuditFailedReasons): void {
    if (reasonValue == AuditFailedReasons.Other) {
      this.otherSelected = !this.otherSelected;
      this.otherReason = undefined;
    }
  }

  getSelectedAuditFailedReasons(): string {
    var reasons = this.auditFailedReasons
      .filter(
        (reason) => reason.checked && reason.value !== AuditFailedReasons.Other
      )
      .map((reason) => AuditFailedReasons[reason.value])
      .join(';');
    const other = this.auditFailedReasons.find(
      (x) => x.value === AuditFailedReasons.Other
    )?.checked
      ? this.otherReason?.replace(';', ' ')
      : undefined;

    if (other) {
      reasons = reasons.concat(reasons ? ';' : '').concat(other);
    }
    return reasons;
  }

  getDefaultReasons() {
    return [
      {
        name: 'AUDIT_FAILED_REASONS.EMAIL',
        value: AuditFailedReasons.Email,
        checked: false,
      },
      {
        name: 'AUDIT_FAILED_REASONS.CARD',
        value: AuditFailedReasons.CardNumber,
        checked: false,
      },
      {
        name: 'AUDIT_FAILED_REASONS.SLRN_MISMATCH',
        value: AuditFailedReasons.SlrnMismatch,
        checked: false,
      },
      {
        name: 'AUDIT_FAILED_REASONS.TAG_NOT_FOUND',
        value: AuditFailedReasons.TagNotFound,
        checked: false,
      },
      {
        name: 'AUDIT_FAILED_REASONS.STRUCTURE',
        value: AuditFailedReasons.BuildingStructure,
        checked: false,
      },
      {
        name: 'AUDIT_FAILED_REASONS.NO_METER_NO',
        value: AuditFailedReasons.NoMeterNo,
        checked: false,
      },
      {
        name: 'AUDIT_FAILED_REASONS.SLRN_INCORRECT',
        value: AuditFailedReasons.SlrnIncorrect,
        checked: false,
      },
      {
        name: 'AUDIT_FAILED_REASONS.INACCESSIBLE',
        value: AuditFailedReasons.Inaccessible,
        checked: false,
      },
      {
        name: 'AUDIT_FAILED_REASONS.DEVELOPMENT_STAGE',
        value: AuditFailedReasons.DevelopmentStage,
        checked: false,
      },
      {
        name: 'AUDIT_FAILED_REASONS.OTHER',
        value: AuditFailedReasons.Other,
        checked: false,
      },
    ];
  }
}
