<div *ngIf="!addNewUser; else addUser" class="container-fluid user-management">
  <div class="table-root">
    <div class="d-flex justify-content-start switch-toggle transparent">
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab === tabs.UserManagementTab ? 'active-tab' : ''"
        (click)="changeTab(tabs.UserManagementTab)"
        ><div>
          <span class="bubble">{{ countUserMgm | number }}</span
          >{{ "ROLE_MANAGEMENT.USER_MANAGEMENT" | translate }}
        </div></a
      >
      <a
        *ngIf="!isBpsAdmin"
        class="separated-toggle-item"
        [ngClass]="currentTab === tabs.OrganizationTeamTab ? 'active-tab' : ''"
        (click)="changeTab(tabs.OrganizationTeamTab)"
        ><div>
          <span class="bubble">{{ countOrgTeam | number }}</span
          >{{ "ROLE_MANAGEMENT.ORGANIZATION_TEAM.SINGLE" | translate }}
        </div></a
      >
    </div>

    <div class="table-root-child">
      <div class="table-action-wrapper d-flex align-items-center">
        <div class="search-input">
          <img src="../../assets/icons/search.svg" alt="Search icon" />
          <input
            type="text"
            [placeholder]="'COMMON.SEARCH' | translate"
            [(ngModel)]="searchText"
            (keyup.enter)="onSearch()"
          />
        </div>

        <div
          class="sort-by-root"
          *ngIf="currentTab === tabs.UserManagementTab"
          (click)="showFilterByStatus = !showFilterByStatus"
          click-stop-propagation
        >
          <div class="dropdown">
            <span
              >{{ "COMMON.FILTER_BY" | translate }}
              {{ "COMMON.FILTER_BY_OPTIONS.STATUS" | translate }}:</span
            >
            <span>{{ filterByStatusName | translate }}</span>
            <span
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showFilterByStatus">
            <div (click)="filterByStatusType(filterByOptions.All)">
              {{ "COMMON.FILTER_BY_OPTIONS.ALL" | translate }}
            </div>
            <div (click)="filterByStatusType(filterByOptions.Active)">
              {{ "COMMON.FILTER_BY_OPTIONS.ACTIVE" | translate }}
            </div>
            <div (click)="filterByStatusType(filterByOptions.Inactive)">
              {{ "COMMON.FILTER_BY_OPTIONS.INACTIVE" | translate }}
            </div>
          </div>
        </div>

        <div
          class="sort-by-root"
          *ngIf="currentTab === tabs.UserManagementTab && !isBpsAdmin"
          (click)="showFilterByOrganization = !showFilterByOrganization"
          click-stop-propagation
        >
          <div class="dropdown">
            <span
              >{{ "COMMON.FILTER_BY" | translate }}
              {{ "COMMON.ORGANIZATION" | translate }}:</span
            >
            <span>{{ filterByOrganizationName | translate }}</span>
            <span
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div
            class="dropdown-opened organization"
            *ngIf="showFilterByOrganization"
          >
            <div (click)="filterByOrganizationType(-1)">
              {{ "COMMON.FILTER_BY_OPTIONS.ALL" | translate }}
            </div>
            <div *ngFor="let item of organizationForSelect">
              <div (click)="filterByOrganizationType(item)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <!-- <app-multi-select-dropdown class="multiselect" [clicked]="false" [items]="roleOptions"
          [label]="'COMMON.USER.ROLES'" [showMore]="false" [requireApply]="true" [alternateLook]="true"
          (shareIndividualCheckedList)="selectRole($event)"
          (applyFilterEmitter)="reloadTable(searchText)">
        </app-multi-select-dropdown> -->

        <div
          *ngIf="currentTab === tabs.UserManagementTab"
          class="ms-auto d-flex"
        >
          <div
            *ngIf="bpsAdminLevel != 2"
            class="ms-auto d-flex"
            style="position: relative"
          >
            <button
              *ngIf="!isBpsAdmin"
              class="btn btn-primary btn-upload"
              (click)="showUpload = !showUpload"
              click-stop-propagation
            >
              <img
                *ngIf="!showUpload"
                src="../../assets/icons/chevron-down.svg"
                alt="Chevron down"
              />
              <img
                *ngIf="showUpload"
                src="../../assets/icons/chevron-down.svg"
                style="transform: rotate(180deg)"
                alt="Chevron up"
              />
            </button>

            <div *ngIf="showUpload" class="xlsx-upload-usr-mngm">
              <div
                *ngIf="permissionsService.isUserManagementBulkAssignAllowed"
                class="upload-options"
                [routerLink]="['/upload/8/' + utilityId]"
              >
                <div>
                  <img
                    src="../../assets/icons/upload-arrow.svg"
                    alt="Upload icon"
                  />
                </div>
                <div class="option-title">
                  {{ "USER_MANAGEMENT.BULK_REASSIGN" | translate }}
                </div>
              </div>
              <div
                *ngIf="permissionsService.isUserManagementCreateEditAllowed"
                class="upload-options"
                [routerLink]="['/upload/9/' + utilityId]"
              >
                <div>
                  <img
                    src="../../assets/icons/upload-arrow.svg"
                    alt="Upload icon"
                  />
                </div>
                <div class="option-title">
                  {{ "USER_MANAGEMENT.BULK_USER_CREATING" | translate }}
                </div>
              </div>
            </div>

            <button
              class="btn btn-primary"
              [ngClass]="{ 'btn-add-new': !isBpsAdmin }"
              (click)="onAddNewUser()"
            >
              <img src="../../assets/icons/plus.svg" alt="Plus icon" />
              <span
                >{{ "COMMON.ADD" | translate }}
                {{ "COMMON.USER.SINGLE" | translate }}</span
              >
            </button>
          </div>
        </div>

        <div
          *ngIf="
            currentTab === tabs.OrganizationTeamTab &&
            permissionsService.isCreateOrganizationAllowed
          "
          class="ms-auto d-flex"
        >
          <div
            *ngIf="bpsAdminLevel != 2"
            class="ms-auto d-flex"
            style="position: relative"
          >
            <button
              data-bs-toggle="modal"
              data-bs-target="#addNewOrgTeam"
              class="btn btn-primary"
              (click)="newOrgTeamName = ''"
            >
              <img src="../../assets/icons/plus.svg" alt="Plus icon" />
              <span
                >{{ "COMMON.ADD" | translate }}
                {{
                  "ROLE_MANAGEMENT.ORGANIZATION_TEAM.SINGLE" | translate
                }}</span
              >
            </button>
          </div>
        </div>
      </div>

      <div class="table">
        <div *ngIf="currentTab === tabs.UserManagementTab">
          <div class="has-bottom-border">
            <table class="table">
              <thead class="table-light">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [checked]="isAllCheckBoxChecked()"
                      (change)="checkAllCheckBox($event)"
                    />
                  </th>
                  <th>{{ "COMMON.USER.FIRST_NAME" | translate }}</th>
                  <th>{{ "COMMON.USER.LAST_NAME" | translate }}</th>
                  <th>{{ "COMMON.USER.EMAIL" | translate }}</th>
                  <th>{{ "COMMON.USER.ROLE" | translate }}</th>
                  <th *ngIf="isBpsAdmin">
                    {{ "COMMON.USER.ADMIN_LEVEL" | translate }}
                  </th>
                  <th *ngIf="!isBpsAdmin">
                    {{ "COMMON.ASSET_STATUS.ASSIGNED" | translate }}
                    {{ "COMMON.UT.SINGLE" | translate }}
                  </th>
                  <th>{{ "COMMON.ASSET_STATUS.STATUS" | translate }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of users; let i = index">
                  <td>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      value="{{ user.id }}"
                      [(ngModel)]="users[i].checked"
                    />
                  </td>
                  <td>{{ user.firstName | hasValue }}</td>
                  <td>{{ user.lastName | hasValue }}</td>
                  <td>{{ user.email | hasValue }}</td>
                  <td>
                    {{
                      user.role && user.role.id != 0
                        ? user.role.name
                        : "BPS Admin"
                    }}
                  </td>
                  <td *ngIf="isBpsAdmin">
                    {{ user.level | hasValue | adminLevel }}
                  </td>
                  <td *ngIf="!isBpsAdmin" style="max-width: 150px">
                    <div class="userAreas" title="{{ user.userAreas }}">
                      {{ user.userAreas | hasValue }}
                    </div>
                  </td>
                  <td>
                    <div class="status {{ user.status | lowercase }}">
                      {{ user.status }}
                    </div>
                  </td>
                  <td class="more-icon">
                    <options-tooltip>
                      <img
                        *ngIf="
                          permissionsService.isUserManagementCreateEditAllowed
                        "
                        tooltipTrigger
                        src="../../assets/icons/more-icon.svg"
                        alt="More icon"
                        class="more-icon"
                      />

                      <ng-container tooltipMenu>
                        <li (click)="onEdit(user, user.role.isDisabled)">
                          <img
                            src="../../assets/icons/edit-user.svg"
                            alt="Edit user icon"
                          />
                          <span
                            >{{ "COMMON.EDIT" | translate }}
                            {{ "COMMON.USER.SINGLE" | translate }}</span
                          >
                        </li>
                        <li
                          *ngIf="user.status === 'Active'; else activateUser"
                          (click)="
                            onSetStatus(
                              user.id,
                              user.status,
                              user.role.isDisabled
                            )
                          "
                          class="color-red"
                        >
                          <img
                            src="../../assets//icons/deactivate-user.svg"
                            alt="Deactivate user icon"
                          />
                          <span
                            >{{ "COMMON.DEACTIVATE" | translate }}
                            {{
                              "COMMON.USER.SINGLE" | translate | lowercase
                            }}</span
                          >
                        </li>
                        <ng-template #activateUser>
                          <li
                            (click)="
                              onSetStatus(
                                user.id,
                                user.status,
                                user.role.isDisabled
                              )
                            "
                            class="color-green"
                          >
                            <img
                              src="../../assets//icons/activate-user.svg"
                              alt="Activate user icon"
                            />
                            <span
                              >{{ "COMMON.ACTIVATE" | translate }}
                              {{
                                "COMMON.USER.SINGLE" | translate | lowercase
                              }}</span
                            >
                          </li>
                        </ng-template>
                      </ng-container>
                    </options-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <app-paging
              *ngIf="countUserMgm > 0"
              [pageSize]="pageSize"
              [count]="countUserMgm"
              [currentPage]="currentPage"
              (pageChange)="pageChange($event)"
            ></app-paging>
          </div>
        </div>

        <div *ngIf="currentTab === tabs.OrganizationTeamTab">
          <div class="has-bottom-border">
            <table class="table">
              <thead class="table-light">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [checked]="isAllCheckBoxChecked()"
                      (change)="checkAllCheckBox($event)"
                    />
                  </th>
                  <th>
                    {{ "ROLE_MANAGEMENT.ORGANIZATION_TEAM.NAME" | translate }}
                  </th>
                  <th>
                    {{ "ROLE_MANAGEMENT.ORGANIZATION_TEAM.STATUS" | translate }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let team of organizationTeams; let i = index">
                  <td>
                    <input
                      type="checkbox"
                      class="form-check-input"
                      value="{{ team.id }}"
                      [(ngModel)]="organizationTeams[i].checked"
                    />
                  </td>
                  <td (click)="selectOrganization(team)">
                    {{ team.name | hasValue }}
                  </td>

                  <td (click)="selectOrganization(team)">
                    <div class="status {{ team.status | lowercase }}">
                      {{ team.status }}
                    </div>
                  </td>
                  <td class="more-icon">
                    <options-tooltip>
                      <img
                        *ngIf="
                          permissionsService.isUserManagementCreateEditAllowed
                        "
                        tooltipTrigger
                        src="../../assets/icons/more-icon.svg"
                        alt="More icon"
                        class="more-icon"
                      />

                      <ng-container tooltipMenu>
                        <li
                          *ngIf="team.status === 'Active'; else activateUser"
                          (click)="
                            onSetStatusOrganizationTeam(team.id, 'Inactive')
                          "
                          class="color-red"
                        >
                          <img
                            src="../../assets//icons/deactivate-user.svg"
                            alt="Deactivate user icon"
                          />
                          <span
                            >{{ "COMMON.DEACTIVATE" | translate }}
                            {{
                              "ROLE_MANAGEMENT.ORGANIZATION_TEAM.SINGLE"
                                | translate
                            }}</span
                          >
                        </li>
                        <ng-template #activateUser>
                          <li
                            (click)="
                              onSetStatusOrganizationTeam(team.id, 'Active')
                            "
                            class="color-green"
                          >
                            <img
                              src="../../assets//icons/activate-user.svg"
                              alt="Activate user icon"
                            />
                            <span
                              >{{ "COMMON.ACTIVATE" | translate }}
                              {{
                                "ROLE_MANAGEMENT.ORGANIZATION_TEAM.SINGLE"
                                  | translate
                              }}</span
                            >
                          </li>
                        </ng-template>
                      </ng-container>
                    </options-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <app-paging
              *ngIf="countOrgTeam > 0"
              [pageSize]="pageSize"
              [count]="countOrgTeam"
              [currentPage]="currentPage"
              (pageChange)="pageChange($event)"
            ></app-paging>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addUser>
  <div class="page-header d-flex justify-content-end">
    <button class="btn btn-outline-danger" (click)="onCancel()">
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button class="btn btn-primary" form="userForm" style="width: auto;">
      {{ "COMMON.SAVE" | translate }}
    </button>
  </div>
  <div class="container-fluid user-management add-new-user">
    <h1 class="page-title">{{ "COMMON.USER.INFORMATION" | translate }}</h1>

    <div class="card">
      <form
        ngNativeValidate
        id="userForm"
        class="form-wrapper"
        (submit)="handleFormSubmit()"
      >
        <div class="form-group">
          <div class="form-item form-floating mb-3">
            <input
              name="name"
              [(ngModel)]="name"
              type="text"
              class="form-control"
              id="floatingInput"
              placeholder=" "
              required
            />
            <label for="floatingInput"
              >{{ "COMMON.USER.FIRST_NAME" | translate }}
            </label>
          </div>
          <div class="form-item form-floating mb-3">
            <input
              name="last-name"
              [(ngModel)]="lastName"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput">{{
              "COMMON.USER.LAST_NAME" | translate
            }}</label>
          </div>
        </div>
        <div class="form-item form-floating mb-3">
          <input
            name="email"
            [(ngModel)]="email"
            type="text"
            class="form-control"
            id="floatingInput3"
            placeholder=" "
            required
          />
          <label for="floatingInput"
            >{{ "COMMON.USER.EMAIL" | translate }}
            {{ "COMMON.ADDRESS" | translate }}</label
          >
        </div>
        <div *ngIf="!isBpsAdmin" class="form-item form-floating mb-3">
          <select
            name="organization"
            class="form-select"
            (change)="selectAddOrganization($event)"
          >
            <option
              [ngValue]="0"
              [selected]="organizationId == 0 || !organizationId"
            >
              {{ "COMMON.SELECT" | translate }}
              {{ "COMMON.ORGANIZATION" | translate }}
            </option>
            <option
              *ngFor="let org of organizationForSelect"
              [selected]="organizationId == org.id"
              value="{{ org.id }}"
            >
              {{ org.name }}
            </option>
          </select>
        </div>

        <div class="form-item form-floating mb-3">
          <input
            [(ngModel)]="pass"
            name="password"
            [type]="showPw ? 'text' : 'password'"
            class="form-control"
            id="floatingPassword"
            placeholder=" "
            [required]="userId === 0"
          />
          <label *ngIf="userId !== 0" for="floatingPassword">{{
            "COMMON.USER.CHANGE_PASSWORD" | translate
          }}</label>
          <label *ngIf="userId === 0" for="floatingPassword">{{
            "COMMON.USER.ENTER_PASSWORD" | translate
          }}</label>
          <div class="show-pw" (click)="showPassword()">
            <ng-template [ngIf]="showPw">
              <img
                src="../../../assets/icons/eye.svg"
                alt="Show password icon"
              />
            </ng-template>
            <ng-template [ngIf]="!showPw">
              <img
                src="../../../assets/icons/eye-slash.svg"
                alt="Show password icon"
              />
            </ng-template>
          </div>
        </div>
        <div *ngIf="!isBpsAdmin" class="form-item form-floating mb-3">
          <select
            name="role"
            required
            class="form-select"
            [ngClass]="{ 'valid selected-option': role?.id !== 0 }"
            (change)="selectOption($event)"
          >
            <option value="" disabled [selected]="role.id == 0">
              {{ "ROLE_MANAGEMENT.ROLE_TYPE" | translate }}
            </option>
            <option
              *ngFor="let o of roleOptions"
              [selected]="role.id == o?.id"
              value="{{ o.id }}"
            >
              {{ o.name }}
            </option>
            <label for="floatingInput">{{
              "USER.LAST_NAME" | translate
            }}</label>
          </select>
        </div>

        <div *ngIf="isBpsAdmin" class="form-item form-floating mb-3">
          <select
            [disabled]="true"
            name="role"
            required
            class="form-select"
            [ngClass]="{ 'valid selected-option': role?.id !== 0 }"
            (change)="selectOption($event)"
          >
            <option value="" disabled [selected]="role.id == 0">
              {{ "ROLE_MANAGEMENT.ROLE_TYPE" | translate }}
            </option>
            <option [selected]="true" value="0">
              {{ "BPS Admin" }}
            </option>
            <label for="floatingInput">{{
              "USER.LAST_NAME" | translate
            }}</label>
          </select>
        </div>
        <div *ngIf="isBpsAdmin" class="form-item form-floating mb-3">
          <select
            name="level"
            required
            class="form-select"
            [ngClass]="{ 'valid selected-option': level != null }"
            (change)="selectLevelOption($event)"
          >
            <option value="" disabled [selected]="level == null">
              {{ "COMMON.BPS_ADMIN" | translate }}
              {{ "COMMON.LEVEL" | translate }}
            </option>
            <option
              *ngFor="let l of levelOptions"
              [selected]="l.value == level"
              value="{{ l.value }}"
            >
              {{ l.name }}
            </option>
            <label for="floatingInput">{{ "COMMON.LEVEL" | translate }}</label>
          </select>
        </div>
        <!-- MOBILE USER REGION SELECT -->
        <div
          class="form-item form-floating mb-3"
          *ngIf="role.systemComponent === sysComponents.Mobile"
        >
          <select
            name="region"
            required
            class="form-select"
            [ngClass]="{ 'valid selected-option': regionIds.length > 0 }"
            (change)="selectRegion($event)"
          >
            <option value="" disabled [selected]="regionIds.length == 0">
              {{ "COMMON.BU.SINGLE" | translate }}
            </option>
            <option
              *ngFor="let o of regionOptions"
              [selected]="isChecked(o.key)"
              value="{{ o.key }}"
            >
              {{ o.value }}
            </option>
            <label for="floatingInput">{{
              "COMMON.USER.LAST_NAME" | translate
            }}</label>
          </select>
        </div>
        <!-- BACKEND USER REGION SELECT -->
        <div
          class="form-item form-floating mb-3"
          *ngIf="role.systemComponent === sysComponents.Admin"
        >
          <div class="dropdown multi-select-dropdown">
            <div class="label-top-left"></div>
            <button
              class="btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span *ngIf="regionIds == null || regionIds.length <= 0">{{
                "COMMON.BU.SINGLE" | translate
              }}</span>
              <span *ngIf="regionIds != null && regionIds.length > 0"
                >{{ regionIds.length }} selected regions</span
              >
              <img src="../../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </button>
            <ul class="dropdown-menu">
              <div class="dropdown-items">
                <label
                  *ngIf="regionOptions.length != regionIds.length"
                  click-stop-propagation
                >
                  <input type="checkbox" (change)="selectAllRegions()" />
                  <span>{{ "COMMON.SELECT_ALL" | translate }}</span></label
                >
                <label
                  *ngIf="regionOptions.length === regionIds.length"
                  click-stop-propagation
                >
                  <input type="checkbox" (change)="deSelectAllRegions()" />
                  <span>{{ "COMMON.DESELECT_ALL" | translate }}</span></label
                >

                <label
                  *ngFor="let item of regionOptions"
                  click="checkRegion(item.key)"
                  click-stop-propagation
                >
                  <input
                    type="checkbox"
                    [checked]="isChecked(item.key)"
                    (change)="checkRegion(item.key)"
                  />
                  <span>{{ item.value }}</span>
                </label>
              </div>
              <div class="apply" (click)="selectRegion($event)">
                {{ "COMMON.APPLY" | translate }}
              </div>
            </ul>
          </div>
        </div>
        <!-- MOBILE AND BACKEND USER AREA SELECT -->

        <div
          class="form-item form-floating mb-3"
          *ngIf="
            role.systemComponent === sysComponents.Admin ||
            role.systemComponent === sysComponents.Mobile
          "
        >
          <div class="dropdown multi-select-dropdown">
            <button
              class="btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span *ngIf="regionIds == null || regionIds.length <= 0">{{
                "COMMON.UT.SINGLE" | translate
              }}</span>
              <span *ngIf="regionIds != null && regionIds.length > 0"
                >{{ areaIds.length }} selected areas</span
              >
              <img src="../../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </button>

            <ul class="dropdown-menu">
              <div class="dropdown-items">
                <label
                  *ngIf="areaOptions.length != areaIds.length"
                  click-stop-propagation
                >
                  <input type="checkbox" (change)="selectAllAreas($event)" />
                  <span>{{ "COMMON.SELECT_ALL" | translate  | lowercase}}</span></label
                >
                <label
                  *ngIf="areaOptions.length === areaIds.length"
                  click-stop-propagation
                >
                  <input
                    type="checkbox"
                    (change)="deSelectAllAreas()"
                    [checked]="areaIds.length === 0"
                  />
                  <span>{{ "COMMON.DESELECT_ALL" | translate  | lowercase}}</span></label
                >
                <label *ngFor="let area of areaOptions" click-stop-propagation>
                  <input
                    type="checkbox"
                    [checked]="handleCheck(area.key)"
                    (change)="handleClick(area.key, $event)"
                  />
                  <span>{{ area.value }}</span>
                </label>
              </div>
              <div class="apply">{{ "COMMON.APPLY" | translate }}</div>
            </ul>
          </div>
        </div>

        <!-- Meter reader permision block select -->

        <div
          class="form-item form-floating mb-3"
          *ngIf="role.permissions.includes(PermissionsEnum.MeterReading)"
        >
          <div class="dropdown multi-select-dropdown">
            <button
              class="btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span *ngIf="areaIds == null || areaIds.length <= 0">
                Blocks
              </span>
              <span *ngIf="areaIds != null && areaIds.length > 0"
                >{{ selectedBlocks.length }} selected blocks</span
              >
              <img src="../../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </button>

            <ul class="dropdown-menu">
              <div class="dropdown-items">
                <label
                  *ngFor="let block of blockOptions"
                  click-stop-propagation
                >
                  <input
                    type="checkbox"
                    [checked]="handleCheckBlocks(block)"
                    (change)="handleClickBlocks(block, $event)"
                  />
                  <span>{{ block }}</span>
                </label>
              </div>
              <div class="apply">Apply</div>
            </ul>
          </div>
        </div>

        <!-- Meter reader permision round select -->

        <div
          class="form-item form-floating mb-3"
          *ngIf="role.permissions.includes(PermissionsEnum.MeterReading)"
        >
          <div class="dropdown multi-select-dropdown">
            <button
              class="btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span
                *ngIf="selectedBlocks == null || selectedBlocks.length <= 0"
              >
                Rounds
              </span>
              <span *ngIf="selectedBlocks != null && selectedBlocks.length > 0"
                >{{ selectedRounds.length }} selected rounds
              </span>
              <img src="../../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </button>

            <ul class="dropdown-menu">
              <div class="dropdown-items">
                <label
                  *ngFor="let round of roundOptions"
                  click-stop-propagation
                >
                  <input
                    type="checkbox"
                    [checked]="handleCheckRounds(round)"
                    (change)="handleClickRounds(round, $event)"
                  />
                  <span>{{ round }}</span>
                </label>
              </div>
              <div class="apply">Apply</div>
            </ul>
          </div>
        </div>

        <!-- Meter reader permision plot select -->

        <div
          class="form-item form-floating mb-3"
          *ngIf="role.permissions.includes(PermissionsEnum.MeterReading)"
        >
          <div class="dropdown multi-select-dropdown">
            <button
              class="btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span
                *ngIf="selectedRounds == null || selectedRounds.length <= 0"
              >
                Rounds
              </span>
              <span *ngIf="selectedRounds != null && selectedRounds.length > 0"
                >{{ selectedPlots.length }} selected plots
              </span>
              <img src="../../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </button>

            <ul class="dropdown-menu">
              <div class="dropdown-items">
                <label *ngFor="let plot of plotOptions" click-stop-propagation>
                  <input
                    type="checkbox"
                    [checked]="handleCheckPlots(plot)"
                    (change)="handleClickPlots(plot, $event)"
                  />
                  <span>{{ plot }}</span>
                </label>
              </div>
              <div class="apply">Apply</div>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<div
  class="modal fade"
  id="addNewOrgTeam"
  tabindex="-1"
  aria-labelledby="addNewOrgTeamTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "COMMON.NEW" | translate }}
          {{
            "ROLE_MANAGEMENT.ORGANIZATION_TEAM.SINGLE" | translate | lowercase
          }}
        </h5>
        <button
          type="button"
          #closeModal
          class="btn-cancel-modal"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          {{ "COMMON.CANCEL" | translate }}
        </button>
      </div>
      <form
        id="addForm"
        class="form-wrapper"
        ngNativeValidate
        (submit)="saveNewOrgTeam()"
      >
        <div class="modal-body">
          <div class="form-item form-floating mb-3">
            <input
              id="orgTeamName"
              name="name"
              type="text"
              [(ngModel)]="newOrgTeamName"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="orgTeamName"
              >{{ "ROLE_MANAGEMENT.ORGANIZATION_TEAM.SINGLE" | translate }}
              {{
                "ROLE_MANAGEMENT.ORGANIZATION_TEAM.NAME" | translate | lowercase
              }}</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button style="width: auto;"
            data-bs-dismiss="modal"
            class="btn btn-primary"
            form="addForm"
          >
            {{ "COMMON.SAVE" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
