<div class="container-fluid regular-tab-content">
  <div class="green-table">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div id="reports_dqr_transformer_search" class="search-input">
        <img
          src="../../assets/icons/search.svg"
          alt="Search icon"
          (click)="search()"
        />
        <input
          class="smaller-font"
          type="text"
          [placeholder]="'COMMON.SEARCH' | translate"
          [(ngModel)]="searchText"
          (keydown.enter)="search()"
          (ngModelChange)="this.searchTextUpdate.next($event)"
        />
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div id="reports_dqr_transformer_date-type" class="datetype-picker">
              <div
                class="dropdown smaller-font"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span class="smaller-font">{{ "COMMON.DATE_TYPE.TITLE" | translate }}:</span>
                <span class="smaller-font" style="width: 50%">{{ selectedDateTypeDisplay | translate }}</span>
                <i class="arrow-custom --small down"></i>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  [id]="'reports_dqr_transformer_date-type-' + (i + 1)" (click)="
                    selectDateType(dateType);
                    datesValid() == true && (dateTo || dateFrom) && search()
                  "
                  *ngFor="let dateType of dateTypes; let i = index"
                >
                  <div>{{ dateType.translation | translate }}</div>
                </div>
              </div>
            </div>
            <div id="reports_dqr_transformer_date-from" class="datepicker-box space-right smaller-font">
              <label class="smaller-font" for="dateFrom"> {{ "COMMON.FROM" | translate }}: </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>

            <div id="reports_dqr_transformer_date-to" class="datepicker-box space-right smaller-font">
              <label for="dateTo" class="right-label smaller-font">
                {{ "COMMON.TO" | translate }}:
              </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>
          </div>
        </div>
        <div
          id="reports_dqr_transformer_sort-by"
          class="sort-by-root"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown">
            <span class="smaller-font">{{'COMMON.SORT_BY.TITLE' | translate}}:</span>
            <span class="smaller-font" style="width: 40%;">{{ sortByName | translate }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" *ngIf="showSortBy">
            <div id="reports_dqr_transformer_sort-by-id" (click)="sortBy(sortByOptions.ID)">
              {{ "COMMON.SORT_BY.ID" | translate }}
            </div>
            <div id="reports_dqr_transformer_sort-by-newest" (click)="sortBy(sortByOptions.NEWEST)">
              {{ "COMMON.SORT_BY.NEWEST" | translate }}
            </div>
            <div id="reports_dqr_transformer_sort-by-slrn-asc" (click)="sortBy(sortByOptions.SLRN_ASC)">
              {{ "COMMON.SORT_BY.SLRN_ASC" | translate }}.
            </div>
            <div id="reports_dqr_transformer_sort-by-slrn-desc" (click)="sortBy(sortByOptions.SLRN_DESC)">
              {{ "COMMON.SORT_BY.SLRN_DESC" | translate }}.
            </div>
          </div>
        </div>

        <button class="button primary--white four-radius" (click)="download()">
          <span class="smaller-font">{{ "COMMON.DOWNLOAD" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list smaller-font">
        <span class="label">{{ "COMMON.FILTER" | translate }}</span>
        <app-multi-select-dropdown
          id="reports_dqr_transformer_region_filter"
          class="multiselect"
          [clicked]="clicked"
          [borderRadius]="'0.4rem'"
          [items]="regions"
          [label]="'COMMON.BU.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          id="reports_dqr_transformer_area_filter"
          class="multiselect"
          [clicked]="clicked"
          [borderRadius]="'0.4rem'"
          [items]="areas"
          [label]="'COMMON.UT.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
        >
        </app-multi-select-dropdown>

        <select
          id="reports_dqr_transformer_user-type_filter"
          name="st-select"
          (change)="selectUserType($event)"
          class="form-select"
        >
          <option value="" [selected]="!userType" disabled>
            {{ "COMMON.USER.TYPE.TITLE" | translate }}
          </option>
          <option
            [id]="'reports_dqr_transformer_user-type_filter-' + (i + 1)" [selected]="userType == o.value"
            *ngFor="let o of userTypeOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>

        <app-multi-select-dropdown
          id="reports_dqr_transformer_user_filter"
          class="multiselect"
          [searchFilterActive]="true"
          [borderRadius]="'0.4rem'"
          (filterValue)="applySearchFilter($event)"
          [clicked]="clicked"
          [items]="filteredUsers"
          [label]="'COMMON.USER.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectUsers($event)"
        >
        </app-multi-select-dropdown>

        <select id="reports_dqr_transformer_transmission-station_filter" name="ts" (change)="selectTS($event)" class="form-select">
          <option value="0" [selected]="selectedTs == 0" disabled>
            {{ "COMMON.TS.SINGLE" | translate }}
          </option>
          <option
            [id]="'reports_dqr_transformer_transmission-station_filter-' + (i + 1)" [selected]="selectedTs == o.id"
            *ngFor="let o of ts; let i = index"
            value="{{ o.id }}"
          >
            {{ o.name }}
          </option>
        </select>

        <select
          id="reports_dqr_transformer_feeder-voltage_filter"
          name="voltage"
          (change)="selectFeederVoltage($event)"
          class="form-select"
        >
          <option value="0" [selected]="feederVoltage == 0" disabled>
            {{ "COMMON.FEEDER_VOLTAGE" | translate }}
          </option>
          <option
            [id]="'reports_dqr_transformer_feeder-voltage_filter-' + (i + 1)" [selected]="feederVoltage == o.value"
            *ngFor="let o of feederVoltageOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.name }}
          </option>
        </select>

        <select
          id="reports_dqr_transformer_mount-position_filter"
          name="mount"
          (change)="selectMountPosition($event)"
          class="form-select"
        >
          <option value="0" [selected]="mountPosition == 0" disabled>
            {{ "TRANSFORMERS.MOUNT_POS" | translate }}
          </option>
          <option
            [id]="'reports_dqr_transformer_mount-position_filter-' + (i + 1)" [selected]="mountPosition == o.value"
            *ngFor="let o of mountPositionOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
        <select id="reports_dqr_transformer_dt-type_filter" name="type" (change)="selectDtType($event)" class="form-select">
          <option value="0" [selected]="dtType == 0" disabled>{{'TRANSFORMERS.DT_TYPE' | translate}}</option>
          <option
            [id]="'reports_dqr_transformer_dt-type_filter-' + (i + 1)" [selected]="dtType == o.value"
            *ngFor="let o of dtTypeOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
        <select
          id="reports_dqr_transformer_dt-status_filter"
          name="status"
          (change)="selectDtStatus($event)"
          class="form-select"
        >
          <option value="0" [selected]="dtStatus == 0" disabled>
            {{'TRANSFORMERS.DT_STATUS' | translate}}
          </option>
          <option
            [id]="'reports_dqr_transformer_dt-status_filter-' + (i + 1)" [selected]="dtStatus == o.value"
            *ngFor="let o of dtStatusOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
        <select
          id="reports_dqr_transformer_meter-status_filter"
          name="meter-status"
          (change)="selectMeterStatus($event)"
          class="form-select"
        >
          <option value="0" [selected]="meterStatus == 0" disabled>
            {{ "COMMON.MBC_DETAILS.METER_STATUS" | translate }}
          </option>
          <option
            [id]="'reports_dqr_transformer_meter-status_filter-' + (i + 1)" [selected]="meterStatus == o.value"
            *ngFor="let o of meterStatusOptions; let i = index"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
        <select
          id="reports_dqr_transformer_kva_filter"
          name="kva-rating"
          (change)="selectKVARating($event)"
          class="form-select"
        >
          <option value="0" [selected]="!kvaRating" disabled>
            {{ "TRANSFORMERS.KVA_RATING" | translate }}
          </option>
          <option
            [id]="'reports_dqr_transformer_kva_filter-' + (i + 1)"
            [selected]="kvaRating == kva"
            *ngFor="let kva of kvaRatingOptions; let i = index"
            value="{{ kva }}"
          >
            {{ kva }}
          </option>
        </select>
      </div>

      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist
          [filters]="selectedFilters"
          (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)"
          [useApply]="true"
          (applyFilterEmitter)="applyFilter($event)"
        >
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="dts">
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th id="reports_dqr_transformer_table_header_checkbox" class="checkbox">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th id="reports_dqr_transformer_table_header_slrn">{{ "COMMON.SLRN" | translate }}</th>
              <th id="reports_dqr_transformer_table_header_dt-name" class="th-dt-name">{{ "COMMON.DT_NAME" | translate }}</th>
              <th id="reports_dqr_transformer_table_header_dt-rating">{{ "TRANSFORMERS.DT_RATING_KVA" | translate }}</th>
              <th id="reports_dqr_transformer_table_header_dt-address" class="th-address">
                {{ "TRANSFORMERS.DT_ADDRESS" | translate }}
              </th>
              <th id="reports_dqr_transformer_table_header_region">{{ "COMMON.BU.SINGLE" | translate }}</th>
              <th id="reports_dqr_transformer_table_header_district">{{ "COMMON.UT.SINGLE" | translate }}</th>
              <th id="reports_dqr_transformer_table_header_feeder-name">{{ "COMMON.MBC_DETAILS.FEEDER_NAME" | translate }}</th>
              <th id="reports_dqr_transformer_table_header_feeder-voltage">{{ "COMMON.FEEDER_VOLTAGE" | translate }}</th>
              <th id="reports_dqr_transformer_table_header_connected-customers">
                {{ "COMMON.MBC_DETAILS.CONNECTED_CUSTOMERS" | translate }}
              </th>
              <th id="reports_dqr_transformer_table_header_type">{{ "COMMON.DT_METER_TYPE.TYPE" | translate }}</th>
              <th id="reports_dqr_transformer_table_header_validation-status">{{ "COMMON.VALIDATION_STATUS" | translate }}</th>
              <th id="reports_dqr_transformer_table_header_tag">{{ "COMMON.TAG.TITLE" | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              [id]="'reports_dqr_transformer_table_row-' + (i + 1)" *ngFor="let dt of dts; let i = index"
              (click)="selectDataQuality(dt.id)"
            >
              <td [id]="'reports_dqr_transformer_table_checkbox-' + (i + 1)">
                <input
                  type="checkbox"
                  class="form-check-input"
                  value="{{ dt.id }}"
                  [ngModel]="dt.isChecked"
                  (click)="checkDt(dt.id)"
                />
              </td>
              <td [id]="'reports_dqr_transformer_table_slrn-' + (i + 1)" (click)="setFilterStorage()">
                {{ dt?.slrn | hasValue }}
              </td>
              <td [id]="'reports_dqr_transformer_table_dt-name-' + (i + 1)" (click)="setFilterStorage()">
                {{ dt.name | hasValue }}
              </td>
              <td [id]="'reports_dqr_transformer_table_dt-rating-' + (i + 1)" (click)="setFilterStorage()">
                {{ dt.kvaRating | hasValue }}
              </td>

              <td [id]="'reports_dqr_transformer_table_dt-address-' + (i + 1)" (click)="setFilterStorage()">
                {{ dt.locationAddress | hasValue }}
              </td>
              <td [id]="'reports_dqr_transformer_table_region-' + (i + 1)" (click)="setFilterStorage()">
                {{ dt.area.name | hasValue }}
              </td>
              <td [id]="'reports_dqr_transformer_table_district-' + (i + 1)" (click)="setFilterStorage()">
                {{ dt.area.regionName | hasValue }}
              </td>
              <td [id]="'reports_dqr_transformer_table_feeder-name-' + (i + 1)" (click)="setFilterStorage()">
                {{ dt.feeder.name | hasValue }}
              </td>
              <td [id]="'reports_dqr_transformer_table_feeder-voltage-' + (i + 1)" (click)="setFilterStorage()">
                {{
                  dt.feeder.feederType == "KV11" ? "11KV" : ("33KV" | hasValue)
                }}
              </td>
              <td [id]="'reports_dqr_transformer_table_connected-customers-' + (i + 1)" (click)="setFilterStorage()">
                {{ dt.connectedCustomers | number | hasValue }}
              </td>

              <td [id]="'reports_dqr_transformer_table_type-' + (i + 1)" (click)="setFilterStorage()">
                {{ dt.type | hasValue }}
              </td>

              <td [id]="'reports_dqr_transformer_table_validation-status-' + (i + 1)" (click)="setFilterStorage()">
                <div
                  *ngIf="dt.status"
                  class="building-status"
                  [ngClass]="{
                    'complete-building': dt.status == 'Complete',
                    'incomplete-building': dt.status == 'Incomplete',
                    'pending-building': dt.status == 'Pending',
                    'assigned-building':
                      dt.status == 'Assigned' || dt.status == 'Retagged',
                    'dark-red-status v-2': dt.status == 'Rejected'
                  }"
                >
                  {{ dt.status }}
                </div>
                <div *ngIf="!dt.status" class="building-status">--------</div>
              </td>
              <td [id]="'reports_dqr_transformer_table_tag-' + (i + 1)">
                <div (click)="setFilterStorage()" class="dark-green-status v-2">
                  {{ "COMMON.EXISTING" | translate }}
                </div>
              </td>
              <td class="more-icon" (click)="setFilterStorage()">
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging
            *ngIf="count > 0"
            [pageSize]="pageSize"
            [useDirect]="true"
            [count]="count"
            [currentPage]="currentPage"
            (pageChange)="pageChange($event)"
          ></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>
